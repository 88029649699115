import React from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';

const CopyField = ({ content, className }) => (
    <div className={className}>
        <TextField
            value={content}
            readOnly
            variant="outlined"
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton aria-label="copy" onClick={() => copy(content)}>
                            <CopyIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    </div>
);

CopyField.propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
};

export default CopyField;
