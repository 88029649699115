import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { viewPropsMapper } from '@/views/utils';
import SimpleMenu from '../../components/SimpleMenu';
import SimpleDialog from '../../components/SimpleDialog';

const isExpired = dateStr => moment.utc().isAfter(moment.utc(dateStr));

const InviteTab = ({ viewStore: store }) => {
    let inviteRows = [...store.invites.entries()].map(item => {
        const [id, data] = item;
        const menuEntries = [
            { label: 'Renovar', onClick: () => store.renovarInvitacion(id) },
            { label: 'Borrar', onClick: () => store.borrarInvitacion(id) },
            { label: 'Generar enlace', onClick: () => store.showInviteLink(id) },
        ];
        return (
            <TableRow key={id}>
                <TableCell>{data.name}</TableCell>
                <TableCell>{data.email}</TableCell>
                <TableCell>{isExpired(data.expires.toDate()) ? 'expirada' : 'activa'}</TableCell>
                <TableCell>{data.role}</TableCell>
                <TableCell width={48} padding="checkbox">
                    <SimpleMenu entries={menuEntries} />
                </TableCell>
            </TableRow>
        );
    });

    return (
        <Grid container className="home-view-container" spacing={10} justify="center">
            <Grid item xs={12}>
                <SimpleDialog
                    open={store.showInviteLinkDialog}
                    actions={[
                        {
                            label: 'OK',
                            raised: true,
                            onClick: store.closeInviteLinkDialog,
                        },
                    ]}
                >
                    {store.inviteLink}
                </SimpleDialog>
                <Button variant="contained" color="primary" onClick={store.openInviteModal}>
                    Nueva Invitación
                </Button>
                {!!inviteRows.length && (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell>Rol</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{inviteRows}</TableBody>
                    </Table>
                )}
            </Grid>
        </Grid>
    );
};

export default inject(viewPropsMapper)(observer(InviteTab));
