import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

/**
  Simple dialog component based on material-ui v1.x
  @author Rodrigo Derteano
*/
class SimpleDialog extends Component {

  static propTypes = {
    /** Actions to show buttons for in the bottom of the dialog */
    actions : PropTypes.arrayOf(PropTypes.shape({
        className : PropTypes.string,
        raised    : PropTypes.bool,
        label     : PropTypes.string,
        onClick   : PropTypes.func,
        secondary : PropTypes.bool
    })),
    /** Whether to open the dialog or not */
    open      : PropTypes.bool,
    /** The main content */
    children  : PropTypes.node,
    /** Optional classes */
    className : PropTypes.string,
    /** function to close on backdrop click or esc key */
    onClose   : PropTypes.func,
    /** Title of the dialog */
    title     : PropTypes.string,
  };

  static defaultProps = {
    open  : false,
    actions : [],
  };

  render() {
    const {open, actions, onClose, title, children } = this.props;
    return (
      <Dialog open={open} onClose={onClose}>
        {!!title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent style={{paddingBottom: '5px'}}>
          <DialogContentText>
            {children}
          </DialogContentText>
        </DialogContent>
        {!!actions && !!actions.length &&
          <DialogActions style={{padding: '0 5px 5px 5px', }}>
            {actions.map( (action, i) =>
              <Button key={'a'+i}
                onClick={action.onClick}
                color={!!action.secondary ? "secondary" : "primary"}
                className={action.className}
                variant={action.raised ? 'contained' : 'text'}>
                {action.label}
              </Button>
            )}
          </DialogActions>
        }
      </Dialog>
    );
  }
}

export default SimpleDialog;
