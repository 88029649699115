export default function buildInviteUrl(inviteId) {
    const loc = window.location;
    return (
        loc.protocol +
        '//' +
        loc.hostname +
        (loc.port ? ':' + loc.port : '') +
        '/invite/' +
        inviteId
    );
}
