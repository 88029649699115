import React from 'react';
import { observer } from 'mobx-react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

const FilterControl = ({ viewStore: store }) => {
    const { filters } = store;
    return (
        <FormGroup row className="filter-control">
            <div className="search-field">
                <TextField
                    value={filters.search}
                    placeholder={'buscar'}
                    onChange={ev => store.setFilter('search', ev.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className="checkboxes">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filters.online}
                            onChange={ev => store.setFilter('online', ev.target.checked)}
                        />
                    }
                    label="Online"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filters.offline}
                            onChange={ev => store.setFilter('offline', ev.target.checked)}
                        />
                    }
                    label="Offline"
                />
            </div>
        </FormGroup>
    );
};

export default observer(FilterControl);
