import React from 'react';
import { DEFAULT, REFUND_TYPE, ROLE, PLAN, distritosLima } from 'klara-common';

const LocationFormConfig = {
    sections: [
        {
            title: 'Locación',
            fields: [
                {
                    key: 'id',
                    label: 'ID',
                    type: 'textfield',
                    getProps: (field, form, store) =>
                        store.initState.id
                            ? { InputProps: { readOnly: true } }
                            : { helperText: 'Opcional: Se genera sola si no pones nada.' },
                },
                {
                    key: 'name',
                    label: 'Razón social (nombre del local)',
                    type: 'textfield',
                    required: true,
                },
                {
                    key: 'alias',
                    label: 'Alias',
                    type: 'textfield',
                },
                {
                    key: 'fuenteId',
                    label: 'Fuente',
                    type: 'select',
                    props: {
                        helperText:
                            'Al cambiar la fuente se generan registros en historial de despliegues.',
                    },
                    required: true,
                    default: DEFAULT.FUENTE_ID,
                },
                {
                    key: 'published',
                    label: 'Publicada (usuarios comunes la pueden ver)',
                    type: 'checkbox',
                    default: true,
                },
                {
                    key: 'activePeriods',
                    label: 'Periodos activos',
                    type: 'period',
                    required: true,
                    default: [{ start: new Date(), end: null }]
                },
                {
                    key: 'hostId',
                    label: 'Host',
                    type: 'select',
                },
                {
                    key: 'place',
                    label: 'Dirección del local (incluir número!)',
                    type: 'addressfield',
                    required: true,
                },
                {
                    key: 'place.distrito',
                    label: 'Distrito',
                    type: 'select',
                    options: distritosLima,
                    required: true,
                },
                {
                    key: 'reference',
                    label: 'Referencia',
                    type: 'textfield',
                    props: {
                        multiline: true,
                    },
                },
                {
                    key: 'hours',
                    label: 'Horarios de apertura',
                    type: 'textfield',
                    required: true,
                    validateType: 'openingHours',
                    props: {
                        multiline: true,
                    },
                    description: (
                        <span>
                            Aqui ejemplos: Ojo "ph" -> public holiday
                            <br />
                            Mo-Sa 06:00-22:00
                            <br />
                            Mo-Fr 08:00-18:00; Sa 10:00-14:00
                            <br />
                            Mo-Fr 08:00-18:00; Sa,Su 10:00-20:00
                            <br />
                            Mo-Fr 08:00-18:00; We off
                            <br />
                            24/7
                            <br />
                            Mo-Sa 06:00-22:00; ph 10:00-15:00
                            <br />
                        </span>
                    ),
                },
                {
                    key: 'instalacionHours',
                    label: 'Horarios de instalación',
                    type: 'textfield',
                    validateType: 'openingHours',
                    props: {
                        multiline: true,
                    },
                },
                {
                    key: 'img',
                    label: 'Imagen',
                    type: 'imagefield',
                    props: {
                        storageBasePath: 'loc-img',
                    },
                },
                {
                    key: 'RUC',
                    label: 'RUC',
                    type: 'textfield',
                },
                {
                    key: 'type',
                    label: 'Tipo de empresa o institución',
                    type: 'radio',
                    required: true,
                    labelPlacement: 'end',
                    options: [
                        { value: 'pyme', label: 'Pyme' },
                        { value: 'empresa-institucion', label: 'Institución o Empresa' },
                    ],
                },
                {
                    key: 'subType',
                    label: 'Especifica:',
                    type: 'select',
                    required: true,
                    showOnCondition: form => form.type === 'empresa-institucion',
                    options: [
                        { value: 'inst-educativa', label: 'Institución educativa' },
                        { value: 'centro-comercial', label: 'Centro comercial' },
                        { value: 'local-comercial', label: 'Local comercial' },
                        { value: 'Empresa', label: 'Empresa' },
                        { value: 'gimnasio-local-deportivo', label: 'Gimnasio o local deportivo' },
                        { value: 'otros', label: 'Otros' },
                    ],
                },
                {
                    key: 'subType',
                    label: 'Tipo de establecimiento',
                    type: 'select',
                    required: true,
                    showOnCondition: form => form.type === 'pyme',
                    options: [
                        { value: 'bodega', label: 'Bodega' },
                        { value: 'restaurante', label: 'Restaurante' },
                        { value: 'jugueria', label: 'Juguería' },
                        { value: 'cafe', label: 'Café' },
                        { value: 'puestoMercado', label: 'Puesto de mercado o galería comercial' },
                        { value: 'gimnasio-local-deportivo', label: 'Gimnasio o local deportivo' },
                        { value: 'otros', label: 'Otros' },
                    ],
                    showChildrenCondition: 'otros',
                    children: [
                        {
                            key: 'subTypeOther',
                            type: 'textfield',
                            label: 'Especificar',
                            required: true,
                        },
                    ],
                },
                {
                    key: 'plan',
                    label: 'Plan',
                    type: 'select',
                    options: [
                        { value: PLAN.PREPAGO, label: 'Prepago' },
                        { value: PLAN.POSPAGO, label: 'Pospago' },
                        { value: PLAN.PAGO_FIJO, label: 'Pago fijo' },
                        { value: PLAN.LIBRE, label: 'Libre (promocional)' },
                    ],
                    required: true,
                    showChildrenCondition: 'pospago',
                    children: [
                        {
                            key: 'postPagoCostoFijo',
                            type: 'textfield',
                            label: 'Costo fijo mantenimiento',
                            validateType: 'number',
                            default: DEFAULT.POSTPAGO_MANTENIMIENTO,
                            required: true,
                        },
                    ],
                },
                {
                    key: 'percentForHost',
                    label: 'Porcentaje de ganancia del host',
                    description: 'Ingresa porcentaje de 0 a 99',
                    type: 'textfield',
                    validateType: 'number',
                    default: DEFAULT.PERCENT_FOR_HOST,
                    required: true,
                },
                {
                    key: 'recojoMonedas',
                    label: 'Recojo de Monedas',
                    type: 'select',
                    options: [
                        { value: ROLE.IGUISTA, label: 'Igüista' },
                        { value: ROLE.HOST, label: 'Host' },
                        { value: 'cashless', label: 'Cashless' },
                    ],
                    required: true,
                },
                {
                    key: 'refundBy',
                    label: 'Devoluciones a cargo de:',
                    type: 'radio',
                    options: [
                        { value: REFUND_TYPE.HOST, label: 'Host' },
                        { value: REFUND_TYPE.APP, label: 'App' },
                    ],
                    default: REFUND_TYPE.HOST,
                    required: true,
                },
                {
                    key: 'volume',
                    label: 'Volumen',
                    type: 'slider',
                    default: DEFAULT.VOLUME,
                },
                {
                    key: 'tipoAgua',
                    label: 'Tipo de agua',
                    type: 'select',
                    options: [
                        { value: 'planta', label: 'Planta' },
                        { value: 'pozo', label: 'Pozo' },
                        { value: 'mixta', label: 'Mixta' },
                        { value: 'indeterminado', label: 'Indeterminado' },
                    ],
                    required: true,
                },
                {
                    key: 'tipoAguaComment',
                    label: 'Comentario sobre tipo de agua',
                    type: 'textfield',
                },
                {
                    key: 'tanque',
                    label: 'Tiene tanque?',
                    type: 'checkbox',
                },
                {
                    key: 'expuestaAlSol',
                    label: 'Fuente expuesta a sol?',
                    type: 'checkbox',
                },
                {
                    key: 'presion',
                    label: 'Presion de agua',
                    type: 'select',
                    options: [
                        { value: 'baja', label: 'Baja' },
                        { value: 'normal', label: 'Normal' },
                        { value: 'alta', label: 'Alta' },
                    ],
                    default: 'normal',
                    required: true,
                },
                {
                    key: 'tds',
                    label: 'Solidos disueltos (TDS)',
                    type: 'textfield',
                    validateType: 'number',
                },
            ],
        },
    ],
};

export default LocationFormConfig;
