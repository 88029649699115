import { observable, action, computed, runInAction, autorun } from 'mobx';
import { orderBy, get, set } from 'lodash';
import { SimpleOpeningHours } from 'simple-opening-hours';

import {
    db,
    limaMoment,
    getNumLocationActiveDays,
    DEFAULT,
    COLS,
    SUBCOLS,
    PLAN,
} from 'klara-common';
import {
    getLocLitrosInLast3Days,
    aggregateObjects,
    getDayIdsFromToday,
    getMonthIdsFromToday,
} from './utils';

export default class ViewStore {
    @observable days = 30;
    @observable months = 4;
    @observable mode = 'days';
    @observable servsXDia = [];
    @observable servsXMes = [];
    @observable locations = new Map();
    @observable fuentes = new Map();
    @observable openLocationsArray = [];

    getStartDate() {
        if (this.mode === 'days') {
            return limaMoment()
                .subtract(this.days, 'days')
                .endOf('day')
                .toDate();
        } else {
            return limaMoment()
                .subtract(this.months - 1, 'month')
                .startOf('month')
                .toDate();
        }
    }

    @computed get locationsArray() {
        return [...this.locations.values()];
    }

    @computed get paidLocations() {
        return this.locationsArray.filter(loc => loc.plan !== PLAN.LIBRE);
    }

    @computed get lowSaldoLocations() {
        return orderBy(
            this.locationsArray.filter(
                loc => loc.active && loc.plan === PLAN.PREPAGO && loc.saldo <= 50000
            ),
            ['saldo', 'label'],
            ['ASC', 'ASC']
        );
    }

    @computed get disconnectedLocations() {
        return orderBy(
            this.openLocationsArray
                .filter(loc => {
                    const fuente = this.fuentes.get(loc.fuenteId);
                    return loc.isOpen && fuente && fuente.online === false;
                })
                .map(loc => {
                    const mod = this.fuentes.get(loc.fuenteId).onlineModified;
                    return {
                        ...loc,
                        onlineModified: mod ? mod.toDate() : undefined,
                    };
                }),
            ['onlineModified', 'label'],
            ['ASC', 'ASC']
        );
    }

    // warning por filtros sin correr
    @computed get filterWarningLocations() {
        return getLocLitrosInLast3Days(this.locationsArray, this.servsXDia);
    }

    @computed get aggregates() {
        let aggregateFields = ['cents', 'ml', 'mlProm', 'mlPaid']
        if (this.mode === 'months') {
            aggregateFields = [...aggregateFields, 'ml_qr', 'servs_qr', 'servs_user', 'ml_user'];
        }
        return (this.mode === 'days' ? this.servsXDia : this.servsXMes).reduce((acc, dateItem) => {
            if (dateItem.locationId && dateItem.locationId !== DEFAULT.LOCATION_ID) {
                set(
                    acc,
                    `locations.${dateItem.locationId}.totals`,
                    aggregateObjects(
                        get(acc, `locations.${dateItem.locationId}.totals`, {}),
                        dateItem,
                        aggregateFields
                    )
                );
                set(
                    acc,
                    `days.${dateItem.id}`,
                    aggregateObjects(get(acc, `locations.${dateItem.id}`, {}), dateItem, aggregateFields)
                );
                return aggregateObjects(acc, dateItem, aggregateFields);
            } else {
                return acc;
            }
        }, {});
    }

    @computed get locationsInServidas() {
        const aggrLocs = (this.aggregates && this.aggregates.locations) || {};
        return Object.keys(aggrLocs).map(lid => this.locations.get(lid)).filter(l => !!l);
    }

    @computed get numLocsEnRango() {
        return {
            paid: (this.locationsInServidas || []).filter(l => l.plan !== PLAN.LIBRE).length,
            libre: (this.locationsInServidas || []).filter(l => l.plan === PLAN.LIBRE).length,
        };
    }

    @computed get litrosXDia() {
        const aggrLocs = (this.aggregates && this.aggregates.locations) || {};
        const locIds = Object.keys(aggrLocs).filter(
            lid => this.locations.get(lid) && this.locations.get(lid).plan !== PLAN.LIBRE
        );
        let acc = 0;
        locIds.forEach(lid => {
            const periods = this.locations.get(lid).activePeriods;
            if (periods) {
                const activeDays = getNumLocationActiveDays(periods, this.getStartDate());
                if (activeDays) {
                    acc += (this.aggregates.locations[lid].totals.mlPaid || 0) / activeDays;
                }
            }
        });
        return locIds.length ? acc / (locIds.length * 1000) : 0;
    }

    @computed get chartData() {
        const dateIds =
            this.mode === 'days' ? getDayIdsFromToday(this.days) : getMonthIdsFromToday(this.months);
        const dateIdIndexes = dateIds.reduce((acc, did) => {
            acc[did] = dateIds.indexOf(did);
            return acc;
        }, {});
        let result = dateIds.map(dateId => ({ dateId }));

        (this.mode === 'days' ? this.servsXDia : this.servsXMes).forEach(item => {
            if (item.locationId && item.locationId !== DEFAULT.LOCATION_ID) {
                const idx = dateIdIndexes[item.id];
                const aggrItem = get(result, idx, {});
                const l = (item.mlPaid || item.ml) / 1000;
                const lp = item.mlProm / 1000;
                const locKey = `${item.locationId}_litros`;
                aggrItem.litros = (aggrItem.litros || 0) + l;
                aggrItem.litrosProm = (aggrItem.litrosProm || 0) + lp;
                aggrItem[locKey] = (aggrItem[locKey] || 0) + l;
                aggrItem[`${locKey}_prom`] = (aggrItem[`${locKey}_prom`] || 0) + lp;
                result = set(result, idx, aggrItem);
            }
        });
        result.reverse();
        return result;
    }

    constructor(parent, fuenteId) {
        this.rootStore = parent;
        this.startCheckIsOpenInterval();
    }

    initDBListeners() {
        this.startLocationsListener();
        this.startServsXDiaListener();
        this.mode === 'months' && this.startServsXMesListener();
        this.startFuentesListener();
    }

    @action restartQueries() {
        if (this.mode === 'days') {
            this.servsXDia = [];
            this.servsXDiaUnsub && this.servsXDiaUnsub();
            this.startServsXDiaListener();
        } else {
            this.servsXMes = [];
            this.servsXMesUnsub && this.servsXMesUnsub();
            this.startServsXMesListener();
        }
    }

    @action setMode = mode => {
        this.mode = mode;
        this.restartQueries();
    };

    @action setNumItems = (num, mode) => {
        if (mode === 'days') {
            this.days = num;
        } else {
            this.months = num;
        }
        this.restartQueries();
    };

    // LOCATIONS
    startLocationsListener() {
        this.locationsUnsub = db
            .collection(COLS.LOCATION)
            .onSnapshot(this.updateLocationsSnapshot, this.onSnapshotError);
    }

    @action updateLocationsSnapshot = snapshot => {
        snapshot.docChanges().forEach(change => {
            if (change.type === 'added' || change.type === 'modified') {
                const data = change.doc.data();
                this.locations.set(change.doc.id, {
                    id: change.doc.id,
                    label: data.alias || data.name,
                    ...data,
                });
            } else if (change.type === 'removed') {
                this.locations.delete(change.doc.id);
            }
        });
    };

    @action updateOpenLocationsArray = locationsArray => {
        if (locationsArray) {
            this.openLocationsArray = locationsArray.map(loc => ({
                ...loc,
                isOpen: new SimpleOpeningHours(loc.hours).isOpenNow(),
            }));
        }
    };

    startCheckIsOpenInterval = () => {
        this.isOpenInterval = setInterval(
            () =>
                runInAction(() => {
                    if (this.locationsArray) {
                        this.locationsArray.forEach((item, i) => {
                            const openNow = new SimpleOpeningHours(item.hours).isOpenNow();
                            if (this.openLocationsArray[i].isOpen !== openNow) {
                                this.openLocationsArray[i].isOpen = openNow;
                            }
                        });
                    }
                }),
            60 * 1000
        );
        this.disposeIsOpenAutorun = autorun(() => {
            this.updateOpenLocationsArray(this.locationsArray);
        });
    };

    // SERVS X DIA
    startServsXDiaListener() {
        this.servsXDiaUnsub = db
            .collectionGroup(SUBCOLS.SERVIDAS_X_DIA)
            .where('timestamp', '>=', this.getStartDate())
            .orderBy('timestamp', 'asc')
            .onSnapshot(this.updateServsXDiaSnapshot, this.onSnapshotError);
    }

    @action updateServsXDiaSnapshot = snapshot => {
        this.servsXDia = snapshot.docs.map(d => ({ id: d.id, ...d.data() }));
    };

    // SERVS X MES
    startServsXMesListener() {
        this.servsXMesUnsub = db
            .collectionGroup(SUBCOLS.SERVIDAS_X_MES)
            .where('timestamp', '>=', this.getStartDate())
            .orderBy('timestamp', 'asc')
            .onSnapshot(this.updateServsXMesSnapshot, this.onSnapshotError);
    }

    @action updateServsXMesSnapshot = snapshot => {
        this.servsXMes = snapshot.docs.map(d => ({ id: d.id, ...d.data() }));
    };

    // FUENTES LISTENER
    startFuentesListener() {
        this.fuentesUnsub = db
            .collection(COLS.FUENTE)
            .where('locationId', '!=', DEFAULT.LOCATION_ID)
            .onSnapshot(this.updateFuentesSnapshot, this.onSnapshotError);
    }

    @action updateFuentesSnapshot = snapshot => {
        snapshot.docChanges().forEach(change => {
            if (change.type === 'added' || change.type === 'modified') {
                const data = change.doc.data();
                this.fuentes.set(change.doc.id, data);
            } else if (change.type === 'removed') {
                this.fuentes.delete(change.doc.id);
            }
        });
    };

    stopDBListeners() {
        this.locationsUnsub && this.locationsUnsub();
        this.servsXDiaUnsub && this.servsXDiaUnsub();
        this.servsXMesUnsub && this.servsXMesUnsub();
        this.fuentesUnsub && this.fuentesUnsub();
    }

    @action onSnapshotError = error => {
        console.log('Error', error);
    };

    @action onEnter = routerState => {
        this.initDBListeners();
    };

    @action onExit = () => {
        this.stopDBListeners();
        this.isOpenInterval && clearInterval(this.isOpenInterval);
        this.disposeIsOpenAutorun && this.disposeIsOpenAutorun();
    };
}
