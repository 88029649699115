import { observable, action, toJS } from 'mobx';
import { isEmpty, find } from 'lodash';

import { getFormPoints } from './utils';


export default class AutoTestStore {

    data = {};
    @observable showResult = false;
    @observable resultPercent = 0;
    @observable resultLevel = null;
    @observable hasError = false;
    @observable showSubmit = true;

    constructor(testConfig) {
      this.config = testConfig;
    }

    @action onFormChange = ({form, errors}) => {
        this.hasError = !isEmpty(errors);
        this.showResult = true;
        this.showSubmit = false;
        if (!this.hasError) {
          const points = getFormPoints(form, this.config);
          this.resultPercent = Math.min(points / this.config.maxPoints, 1);
          this.resultLevel = find(this.config.pointLevels, l => this.resultPercent > l.min && this.resultPercent <= l.max);
          this.data = {...form, resultPercent: toJS(this.resultPercent), resultLevel: toJS(this.resultLevel)};
        }
    }

    onEvaluate = (form, errors) => console.log('onEvaluate', form, errors);

    onSubmit = (onSubmitFunc) => onSubmitFunc && onSubmitFunc(this.data);

}
