import React from 'react';
import { observer, inject } from 'mobx-react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import { viewPropsMapper } from '@/views/utils';
import { FuenteFormDialog } from '@/components/FuenteFormDialog';
import { DEFAULT } from 'klara-common';

import StatusIndicators from './StatusIndicators';
import FuenteMainTabs from './MainTabs';
import HomeTab from './HomeTab';
import ComandosTab from './ComandosTab';
import './fuente-view.scss';


const FuenteView = ({ viewStore, goTo, route }) => {
    if (!viewStore) return null;
    const { fuente } = viewStore;
    return (
        <Container className="fuente-container" justify="center">
            <div className="fuente-header">
                <div className="fuente-edit-btn">
                    <IconButton onClick={viewStore.openEditForm}>
                        <EditIcon />
                    </IconButton>
                </div>
                <h1 className="h1-title">{fuente && fuente.fuenteId}</h1>
                <div className="subtitle-fields">
                    <div className="subtitle-btn-link">
                        Locación:{' '}
                        <Button
                            onClick={() => goTo('location', { id: fuente.locationId })}
                            disabled={fuente.locationId ===  DEFAULT.LOCATION_ID}
                        >
                            {fuente.locationName || 'Sin asignar'}
                        </Button>
                    </div>
                </div>
                <StatusIndicators fuente={fuente} />
                <FuenteMainTabs />
            </div>

            {viewStore.tab ===  'main' && viewStore.tabStore && (
                <HomeTab tabStore={viewStore.tabStore} {...{ goTo, route }} />
            )}
            {viewStore.tab ===  'comandos' && viewStore.tabStore && (
                <ComandosTab tabStore={viewStore.tabStore} {...{ goTo, route }} />
            )}

            <FuenteFormDialog store={viewStore.fuenteFormStore} />
        </Container>
    );
};

export default inject(viewPropsMapper)(observer(FuenteView));
