import React from 'react';
import Typography from '@material-ui/core/Typography';
import TimeAgo from 'react-timeago';

import LocationWatchList from '../LocationWatchList';

const TEN_MIN = 10 * 60 * 1000;

const agoFormatter = (val, unit) => {
    if (['second', 'minute', 'hour'].includes(unit)) {
        return val + unit.substr(0, 1);
    } else if (unit === 'day') {
        return val + ' dia' + (val > 1 ? 's' : '');
    } else if (unit === 'week') {
        return val + ' semana' + (val > 1 ? 's' : '');
    } else if (unit === 'month') {
        return val + ' mes' + (val > 1 ? 'es' : '');
    } else if (unit === 'year') {
        return val + ' año' + (val > 1 ? 's' : '');
    }
};

const DisconnectedItems = ({ locs }) => {
    return (
        <LocationWatchList
            title="DESCONECTADAS"
            locs={locs}
            getValue={loc => {
                const warning = loc.onlineModified
                    ? Date.now() - loc.onlineModified.getTime() >= TEN_MIN
                    : true;
                return (
                    <Typography color={warning ? 'error' : undefined}>
                        {loc.onlineModified ? (
                            <TimeAgo date={loc.onlineModified} formatter={agoFormatter} />
                        ) : (
                            'n/a'
                        )}
                    </Typography>
                );
            }}
        />
    );
};

export default DisconnectedItems;
