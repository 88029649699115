import React from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import Histogram from '@/components/Histogram';
import HeatMap from '@/components/Heatmap';
import OnlineStatusChart from '@/components/OnlineStatusChart';
import StatRow from '@/components/StatRow';

import Filters from '../Filters';

const LocationHomeTab = ({ tabStore }) => {
    return (
        <div className="location-home-tab">
            <Filters />

            <StatRow
                values={[
                    { label: 'Litros x día', value: tabStore.litrosXDia.toFixed(1) },
                    { label: 'Total Litros', value: tabStore.totals.litros.toFixed(1) },
                    { label: 'Litros Prom', value: tabStore.totals.litrosProm.toFixed(1) },
                ]}
            />
            <StatRow
                values={[
                    { label: 'Total Servidas', value: tabStore.totals.servidas },
                    { label: 'Total Soles', value: tabStore.totals.soles.toFixed(1) },
                ]}
            />

            <div className="chart-section">
                <h3 className="chart-title">Soles</h3>
                <Histogram
                    data={tabStore.daysData}
                    xKey={'dateStr'}
                    bar={{ key: 'soles', name: 'Soles' }}
                />
            </div>

            <div className="chart-section">
                <h3 className="chart-title">Litros</h3>
                <Histogram
                    data={tabStore.daysData}
                    xKey={'dateStr'}
                    bar={{ key: 'litros', name: 'litros' }}
                />
            </div>

            <div className="chart-section">
                <h3 className="chart-title">Litros promoción (gratis)</h3>
                <Histogram
                    data={tabStore.daysData}
                    xKey={'dateStr'}
                    bar={{ key: 'litrosProm', name: 'litrosProm' }}
                />
            </div>

            <div className="chart-section">
                <h3 className="chart-title">Estado de la conección</h3>
                <OnlineStatusChart
                    data={tabStore.main.onlineStates}
                    startDate={tabStore.main.startDate}
                    endDate={tabStore.main.endDate}
                />
            </div>

            <h3>Agregados mensuales</h3>

            <div className="chart-section">
                <h3 className="chart-title">Distribución tamaño de servida (ml)</h3>
                <Histogram
                    data={tabStore.servSizeDistribution}
                    xKey="label"
                    bar={{ key: 'value', name: 'servidas' }}
                />
            </div>

            <div className="chart-section heatmap">
                <h3 className="heatmap-title chart-title">Numero de servidas segun dias y horas</h3>
                <HeatMap data={!!tabStore ? tabStore.weekHeatMapData : null} />
            </div>

            <StatRow
                values={[
                    {
                        label: 'Prom. serv. x dia',
                        value: tabStore.averageServsPerDay.toFixed(1),
                    },
                    {
                        label: 'Prom. ml x servida',
                        value: tabStore.totalsXMes.avgMlXServ.toFixed(1),
                    },
                ]}
            />

            <StatRow
                values={[
                    {
                        label: '% servidas cash',
                        value: tabStore.totalsXMes.percentCash.toFixed(1),
                    },
                    {
                        label: '% servidas app',
                        value: tabStore.totalsXMes.percentApp.toFixed(1),
                    },
                    {
                        label: '% servidas QR',
                        value: tabStore.totalsXMes.percentQR.toFixed(1),
                    },
                ]}
            />
        </div>
    );
};

export default observer(LocationHomeTab);
