import React from 'react';
import cn from 'classnames';

import './color-sample.scss';

const ColorSample = ({ color, className }) => (
  <div className={cn('color-sample', className)} style={{backgroundColor: color}} />
)

export default ColorSample;
