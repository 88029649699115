import React from 'react';
import { AreaChart, XAxis, Area, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { minBy } from 'lodash';

import { primary } from '@/theme';
import './online-status-chart.scss';

const formatData = data => {
    let result = [];
    let previous = null;
    for (let item of data) {
        if (previous !== null && item.connected !== previous) {
            result.push({
                connected: item.connected ? 0 : 1,
                timestamp: moment(item.timestamp.seconds * 1000)
                    .subtract(1, 'second')
                    .valueOf(),
            });
            result.push(item);
        }
        result.push({ ...item, timestamp: item.timestamp.seconds * 1000 });
        previous = item;
    }
    return result;
};

const OnlineStateChart = ({ data, startDate, endDate }) => {
    const formatedData = formatData(data);
    if (!endDate) {
        endDate = new Date();
    }
    if (!startDate) {
        startDate = minBy(formatedData, d => d.timestamp).timestamp;
    }
    const domain = [moment(startDate).valueOf(), moment(endDate).valueOf()];
    return (
        <ResponsiveContainer height={100} width="100%" className="online-status-chart">
            <AreaChart data={formatedData}>
                <XAxis
                    dataKey="timestamp"
                    type="number"
                    domain={domain}
                    tickFormatter={val =>
                        momentTimezone(val)
                            .tz('America/Lima')
                            .format('D MMM YYYY, HH:mm')
                    }
                />
                <Area dataKey="connected" fill={primary[400]} stroke={primary[400]} />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default OnlineStateChart;
