import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChipInput from 'material-ui-chip-input';

import { viewPropsMapper } from '@/views/utils';

const CreateUpdateDialog = ({ viewStore: store, fullScreen }) => {
    return (
        <Dialog
            fullScreen={fullScreen}
            open={!!store.openDialog}
            onClose={() => store.setDialogOpen(false)}
            aria-labelledby="responsive-dialog-title"
            classes={{ container: 'klara-dialog-container' }}
        >
            {store.dialogLoading && (
                <div className="dialog-loading-container">
                    <div className="loading-elem">
                        <CircularProgress color="primary" />
                    </div>
                </div>
            )}
            <DialogTitle id="responsive-dialog-title">
                <span>Despliegue de nuevo update</span>
                <IconButton
                    aria-label="Close"
                    className="dialog-corner-close-btn"
                    onClick={() => store.setDialogOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className="klara-dialog-form">
                {!store.showDialogConfirm && (
                    <Fragment>
                        <div className="form-field">
                            <TextField
                                error={store.showValidation && !!store.updateDocError.tag}
                                helperText={store.showValidation ? store.updateDocError.tag : ''}
                                label="Git Tag (version)"
                                fullWidth
                                value={store.updateDoc.tag || ''}
                                onChange={ev => store.setUpdateDocField('tag', ev.target.value)}
                            />
                        </div>
                        <div className="form-field">
                            <ChipInput
                                label="Restringir a fuente ids"
                                fullWidth
                                helperText="Opcionalmente restringir update a solo estas fuente ids"
                                blurBehavior="add"
                                newChipKeyCodes={[13, 188, 32]}
                                value={store.updateDoc.devices || []}
                                onAdd={dev =>
                                    store.setUpdateDocField('devices', [
                                        ...(store.updateDoc.devices || []),
                                        dev,
                                    ])
                                }
                                onDelete={dev =>
                                    store.setUpdateDocField(
                                        'devices',
                                        store.updateDoc.devices.filter(d => d !==  dev)
                                    )
                                }
                            />
                        </div>
                    </Fragment>
                )}
                {store.showDialogConfirm && (
                    <div className="update-confirm-msg">
                        <strong>OJO: acción no reversible!!!</strong>
                        <p>
                            {`Nueva version: `}
                            <span style={{ color: 'red', fontWeight: 'bold' }}>
                                {store.updateDoc.tag}
                            </span>
                        </p>
                        {!!store.updateDoc.devices && store.updateDoc.devices.length && (
                            <p>Solo a fuenteIds: {store.updateDoc.devices.join(', ')}</p>
                        )}
                        <p>
                            Verifica bien la versión del update y que el tag respectivo en github
                            exista.
                        </p>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                {!store.showDialogConfirm ? (
                    <Fragment>
                        <Button onClick={() => store.setDialogOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={store.createUpdate}
                            color="primary"
                            variant="contained"
                            autoFocus
                        >
                            Deplegar
                        </Button>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Button onClick={store.confirmGoBack} color="primary">
                            Regresar
                        </Button>
                        <Button
                            onClick={store.confirmCreateUpdate}
                            color="primary"
                            variant="contained"
                            autoFocus
                        >
                            Si, confirmo!
                        </Button>
                    </Fragment>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default inject(viewPropsMapper)(withMobileDialog()(observer(CreateUpdateDialog)));
