import React from 'react';
import { observer } from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { format } from 'date-fns';

const DesktopList = ({ servidas }) => (
    <Table>
        <TableHead>
            <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Soles</TableCell>
                <TableCell>Ml</TableCell>
                <TableCell>Ml Prom</TableCell>
                <TableCell>Metodo pago</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {servidas.map((serv, idx) => {
                return (
                    <TableRow key={idx}>
                        <TableCell>{format(serv.timestamp.toDate(), 'd MMM yyyy HH:mm:ss')}</TableCell>
                        <TableCell>S/ {(serv.cents / 100).toFixed(2)}</TableCell>
                        <TableCell>{serv.ml}</TableCell>
                        <TableCell>{serv.mlProm}</TableCell>
                        <TableCell>{serv.metodo}</TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    </Table>
);

export default observer(DesktopList);
