import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import SimpleMenu from '@/components/SimpleMenu';
import SimpleDialog from '@/components/SimpleDialog';
import { formatCentsInSoles } from 'klara-common';

import CashupForm from './CashupForm';

const CashupsTab = ({ tabStore }) => {
    return (
        <div className="cashflow-tab" style={{ paddingTop: 20 }}>
            <Tabs value={tabStore.tab} onChange={(ev, val) => tabStore.setTab(val)}>
                <Tab label="Cierres de caja" />
                <Tab label="Devoluciones" />
            </Tabs>

            {tabStore.tab === 0 && (
                <div className="main-tab-content">
                    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => tabStore.setOpenForm(true)}
                        >
                            Nuevo cierre de caja
                        </Button>
                    </div>

                    <CashupForm store={tabStore} />
                    <SimpleDialog
                        open={tabStore.openDeleteConfirm}
                        title={'Seguro que quieres borrar??'}
                        onClose={tabStore.cancelDelete}
                        actions={[
                            {
                                label: 'Cancelar',
                                onClick: tabStore.cancelDelete,
                            },
                            {
                                label: '¡Si, borrar!',
                                onClick: tabStore.deleteItem,
                                secondary: true,
                            },
                        ]}
                    />

                    {!!tabStore.cashups.length && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Desde</TableCell>
                                    <TableCell>Hasta</TableCell>
                                    <TableCell>Monto en backend</TableCell>
                                    <TableCell>Monto encontrado</TableCell>
                                    <TableCell>Comentario</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tabStore.cashups.slice(0, 30).map((item, idx) => (
                                    <TableRow key={`item-${idx}`}>
                                        <TableCell>
                                            {moment(item.start.toDate()).format(
                                                'D MMM YYYY - HH:mm:ss'
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {moment(item.end.toDate()).format(
                                                'D MMM YYYY - HH:mm:ss'
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {`S/ ` + (item.cents / 100).toFixed(2)}
                                        </TableCell>
                                        <TableCell>
                                            {item.amountFound ? `S/ ${item.amountFound}` : ' - '}
                                        </TableCell>
                                        <TableCell>{item.comment || ' - '}</TableCell>
                                        <TableCell>
                                            <SimpleMenu
                                                entries={[
                                                    {
                                                        label: 'Editar',
                                                        onClick: () => tabStore.setOpenForm(idx),
                                                        icon: <EditIcon />,
                                                    },
                                                    {
                                                        label: 'Borrar',
                                                        onClick: () => tabStore.deleteConfirm(idx),
                                                        icon: <DeleteIcon />,
                                                    },
                                                ]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </div>
            )}
            {tabStore.tab === 1 && (
                <div className="main-tab-content">
                    {!tabStore.refunds.length && <p>No hay devoluciones</p>}
                    {!!tabStore.refunds.length && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Código</TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Monto</TableCell>
                                    <TableCell>Tipo</TableCell>
                                    <TableCell>Efectuada</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tabStore.refunds.slice(0, 30).map((refund, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell>{refund.refundId}</TableCell>
                                            <TableCell>
                                                {moment(refund.timestamp.toDate()).format(
                                                    'D MMM YYYY - HH:mm:ss'
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {formatCentsInSoles(refund.cents)}
                                            </TableCell>
                                            <TableCell>{refund.type}</TableCell>
                                            <TableCell>{refund.pending ? 'NO' : 'SI'}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    )}
                </div>
            )}
        </div>
    );
};

export default observer(CashupsTab);
