import React from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { observer, inject } from 'mobx-react';

import { viewPropsMapper } from '@/views/utils';
import { primary } from '@/theme';
import Histogram from '@/components/Histogram';
import StatRow from '@/components/StatRow';

import LowSaldoItems from './LowSaldoItems';
import DisconnectedItems from './DisconnectedItems';
import DrainWarningItems from './DrainWarningItems';
import './dashboard.scss';

const colors = [primary['400'], primary['300']];

const monthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const dayOptions = [3, 7, 15, 30];

const DashboardView = ({ viewStore, userStore, goTo, route }) => {
    if (!viewStore) return null;
    const {
        chartData,
        aggregates,
        lowSaldoLocations,
        locationsInServidas,
        disconnectedLocations,
        filterWarningLocations,
        days,
        months,
        mode,
        fuentes,
        setMode,
        setNumItems,
    } = viewStore;

    return (
        <Container className="dashboard-container" justify="center">
            <h1>Dashboard</h1>
            <div className="dashboard-content">
                <Paper className="dashboard-section numbers-section">
                    <div className="numbers-top">
                        <h2>Ventas</h2>
                        <div className="grow" />
                        <div className="num-items-field">
                            <Select
                                label={mode === 'days' ? 'Días' : 'Meses'}
                                onChange={ev => setNumItems(ev.target.value, mode)}
                                value={mode === 'days' ? days : months}
                            >
                                {(mode === 'days' ? dayOptions : monthOptions).map(opt => (
                                    <MenuItem key={opt} value={opt}>{opt}</MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="dashboard-mode-selector">
                            <ToggleButtonGroup
                                size="small"
                                exclusive
                                value={mode}
                                onChange={(ev, val) => setMode(val)}
                            >
                                <ToggleButton value="days">Dias</ToggleButton>
                                <ToggleButton value="months">Meses</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </div>
                    <StatRow
                        values={[
                            {
                                label: 'Litros x día x fuente',
                                value: viewStore.litrosXDia.toFixed(1),
                            },
                            {
                                label: 'Total litros',
                                value: ((aggregates.mlPaid || 0) / 1000).toFixed(1),
                            },
                            {
                                label: 'Total litros prom',
                                value: ((aggregates.mlProm || 0) / 1000).toFixed(1),
                            },
                        ]}
                    />
                    <StatRow
                        values={[
                            {
                                label: 'Locaciones pagas',
                                value: (viewStore.numLocsEnRango.paid || 0)
                            },
                            {
                                label: 'Locaciones libres',
                                value: (viewStore.numLocsEnRango.libre || 0)
                            },
                            {
                                label: 'Total soles',
                                value: ((aggregates.cents || 0) / 100).toFixed(1),
                            },
                        ]}
                    />
                    <div className="chart-section">
                        <h3 className="chart-title">Litros vendidos</h3>
                        {!!chartData && (
                            <Histogram
                                data={chartData}
                                xKey={'dateId'}
                                bars={locationsInServidas.map((loc, i) => ({
                                    key: loc.id + '_litros',
                                    name: loc.label,
                                    color: colors[i % 2],
                                }))}
                            />
                        )}
                    </div>
                    <div className="chart-section">
                        <h3 className="chart-title">Litros promocionales</h3>
                        {!!chartData && (
                            <Histogram
                                data={chartData}
                                xKey={'dateId'}
                                bars={locationsInServidas.map((loc, i) => ({
                                    key: loc.id + '_litros_prom',
                                    name: loc.label,
                                    color: colors[i % 2],
                                }))}
                            />
                        )}
                    </div>
                    {mode === 'months' && (
                        <StatRow
                            values={[
                                {
                                    label: 'Servidas App',
                                    value: aggregates.servs_user || 0
                                },
                                {
                                    label: 'Total litros App',
                                    value: ((aggregates.ml_user || 0) / 1000).toFixed(1)
                                },
                                {
                                    label: 'Servidas QR',
                                    value: aggregates.servs_qr || 0
                                },
                                {
                                    label: 'Total litros QR',
                                    value: ((aggregates.ml_qr || 0) / 1000).toFixed(1)
                                },
                            ]}
                        />
                    )}
                </Paper>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Paper>
                            <LowSaldoItems locs={lowSaldoLocations} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Paper>
                            <DisconnectedItems locs={disconnectedLocations} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Paper>
                            <DrainWarningItems locs={filterWarningLocations} fuentes={fuentes} />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
};

export default inject(viewPropsMapper)(observer(DashboardView));
