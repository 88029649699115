import { observable, action, toJS } from 'mobx';
import moment from 'moment';

import FuenteHomeStore from './HomeTab/store';
import ComandosStore from './ComandosTab/store';
import { FuenteFormStore } from '@/components/FuenteFormDialog';
import { db, COLS } from 'klara-common';

export default class FuenteView {
    @observable fuente = {};
    @observable onlineStates = [];
    @observable startDate = moment().startOf('month');
    @observable endDate = null;
    @observable advancedPickers = false;
    @observable tab = 'main';
    @observable.ref tabStore = null;

    constructor(parent, { params }) {
        this.fuenteRef = db.collection(COLS.FUENTE).doc(params.id);
        this.rootStore = parent;
        this.fuenteFormStore = new FuenteFormStore({
            onBeforeSubmitFunc: this.onBeforeFuenteSubmit
        });
    }

    initDBListeners() {
        this.startFuenteListener();
        //this.startOnlineStatusListener();
    }

    startFuenteListener() {
        this.rootStore.setLoading(true);
        this.fuenteListener = this.fuenteRef.onSnapshot(
            this.updateFuenteSnapshot,
            this.onFuenteSnapshotError
        );
    }

    startOnlineStatusListener() {
        let onlineStatusRef = db
            .collection(COLS.ONLINE_STATE)
            .where('fuenteId', '==', this.fuenteId);
        if (this.startDate != null) {
            onlineStatusRef = onlineStatusRef.where('timestamp', '>=', this.startDate.toDate());
        }
        if (this.endDate != null) {
            onlineStatusRef = onlineStatusRef.where('timestamp', '<=', this.endDate.toDate());
        }
        onlineStatusRef = onlineStatusRef.orderBy('timestamp', 'asc');
        if (!this.endDate || this.endDate.isAfter()) {
            this.onlineStatusUnsubscribe = onlineStatusRef.onSnapshot(
                this.updateOnlineStateSnapshot
            );
        } else {
            onlineStatusRef.get().then(this.updateOnlineStateSnapshot);
        }
    }

    restartListeners() {
        this.stopOnlineStateListener();
        this.onlineStates.clear();
        this.startOnlineStatusListener();
    }

    stopDBListeners() {
        this.stopOnlineStateListener();
    }

    stopFuenteListener() {
        this.fuenteListener && this.fuenteListener();
    }

    stopOnlineStateListener() {
        this.onlineStatusUnsubscribe && this.onlineStatusUnsubscribe();
    }

    @action updateOnlineStateSnapshot = snapshot => {
        this.onlineStates = snapshot.docs.map(d => d.data());
    };

    @action updateFuenteSnapshot = snapshot => {
        this.rootStore.setLoading(false);
        if (!snapshot.exists) {
            this.rootStore.routerStore.goTo('fuentes');
            return;
        }
        this.fuente = snapshot.data();
    };

    @action onFuenteSnapshotError = e => console.error('fuente snapshot error', e);

    @action setStartDate = date => {
        this.startDate = date;
        this.restartListeners();
    };

    @action setEndDate = date => {
        this.endDate = date;
        this.restartListeners();
    };

    @action setSimpleDate = date => {
        this.startDate = date;
        this.endDate = moment(date).endOf('month');
        this.restartListeners();
    };

    @action toggleAdvancedPickers = ev => {
        this.advancedPickers = !this.advancedPickers;
    };

    @action goToTab = tab => {
        this.rootStore.routerStore.goTo('fuente', { id: this.fuenteId, tab });
    };

    @action openEditForm = () => {
        this.fuenteFormStore.openEditForm(toJS(this.fuente));
    };

    @action onSubpathEnter = routerState => {
        this.tab = routerState.params.tab || 'main';
        switch (this.tab) {
            case 'comandos':
                this.tabStore = new ComandosStore(this);
                break;
            default:
                this.tabStore = new FuenteHomeStore(this);
        }
    };

    @action onSubpathExit = () => {
        this.tabStore && this.tabStore.onExit && this.tabStore.onExit();
        this.tabStore = null;
    };

    @action onEnter = routerState => {
        this.fuenteId = routerState.params.id;
        this.onSubpathEnter(routerState);
        this.initDBListeners();
    };

    @action onExit = () => {
        this.stopDBListeners();
    };
}
