import React from 'react';
import { observer } from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import ArrowRight from '@material-ui/icons/ArrowForwardIos';

import { DEFAULT } from 'klara-common';
import FuenteStateChip from '@/components/FuenteStateChip';
import ConnectionState from '@/components/ConnectionState';

const DesktopList = ({ fuentesSorted, goTo }) => (
    <Table>
        <TableHead>
            <TableRow>
                <TableCell>Fuente</TableCell>
                <TableCell>Locación</TableCell>
                <TableCell>Online</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Version</TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {fuentesSorted.map(data => (
                <TableRow key={data.fuenteId}>
                    <TableCell>{data.fuenteId}</TableCell>
                    <TableCell>{data.locationName || DEFAULT.LOCATION_NAME}</TableCell>
                    <TableCell>
                        <ConnectionState
                            state={data.online}
                            since={data.onlineModified}
                            showLabel={false}
                        />
                    </TableCell>
                    <TableCell>
                        <FuenteStateChip fuente={data} />
                    </TableCell>
                    <TableCell>{data.version}</TableCell>
                    <TableCell width={48} padding="checkbox">
                        <IconButton onClick={() => goTo('fuente', { id: data.fuenteId })}>
                            <ArrowRight />
                        </IconButton>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
);

export default observer(DesktopList);
