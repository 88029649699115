import React from 'react';
import { observer } from 'mobx-react';
import Alert from '@material-ui/lab/Alert';

import { FormDialog } from '@/components/Form';
import formConf from './formConfig';

const LocationFormDialog = ({ store }) =>
    store ? (
        <FormDialog
            header={
                store.mainError ? (
                    <Alert onClose={store.hideMainError}>{store.mainError}</Alert>
                ) : null
            }
            open={store.open}
            onOpen={store.onFormOpen}
            loading={store.loading}
            onClose={() => store.setDialogOpen(false)}
            onSubmit={store.onSubmit}
            config={formConf}
            asyncOptions={store.formOptions}
            initState={store.editInitState}
        />
    ) : null;

export default observer(LocationFormDialog);
