import React from 'react';
import {inject, observer} from 'mobx-react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import './host-recrute-form.scss';
import RecruteFormMain from './Main';
import {viewPropsMapper} from '@/views/utils';

const HostrRectruteForm = ({route}) => (
  <Container maxWidth="md" className="host-form-main-container">
    <Paper className="host-form-main-paper">
      <RecruteFormMain />
    </Paper>
  </Container>
)

export default inject(viewPropsMapper)(observer(HostrRectruteForm));
