import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import Histogram from '../Histogram';
import BoxPlot from '../Boxplot';

import './histo-boxplot.scss';

const HistoBoxPlot = ({data, xKey, bar }) => {
  const bplotData = data.map(d => Array(d[bar.key]).fill(d[xKey])).flat();
  const numBars = data.length;
  if (!data.length) return null;
  return (
    <div className="histo-box-plot">
      <div className="histogram-container">
        <Histogram
          data={data}
          xKey={xKey}
          bar={bar}
          className="with-boxplot" />
      </div>
      <div className="boxplot-container">
        <BoxPlot data={bplotData} numBars={numBars} />
      </div>
    </div>
  );
}

HistoBoxPlot.propTypes = {
  data : PropTypes.array,
  xKey : PropTypes.string,
  bar : PropTypes.object,
};

HistoBoxPlot.defaultProps = {
  xKey: 'key',
  bar: { key: 'value', name: 'veces'}
};

export default observer(HistoBoxPlot);
