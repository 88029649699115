import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import './checkbox-field.scss';

const CheckboxField = ({ value, onChange, label, className, error, description }) => (
    <FormControl error={!!error} className={className}>
        <FormControlLabel
            control={
                <Checkbox checked={value || false} onChange={ev => onChange(ev.target.checked)} />
            }
            label={label}
        />
        {(!!error || !!description) && (
            <FormHelperText className="checkbox-error-msg">{description || error}</FormHelperText>
        )}
    </FormControl>
);

export default CheckboxField;
