import React, { useState, useRef, useEffect } from 'react';
import { MdLocationOn as LocationIcon } from 'react-icons/md';

import './location-point-map.scss';

const LocationPointMap = ({ pos, className }) => {
    const [mapa, setMapa] = useState({});
    const ref = useRef();
    const { lat, lng } = pos || {};

    useEffect(() => {
        let gmap, marker; //, tmout;
        if (ref.current && lat && lng) {
            gmap = new window.google.maps.Map(ref.current, {
                zoom: 16,
                center: { lat, lng },
                disableDefaultUI: true,
                gestureHandling: 'cooperative',
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        featureType: 'poi',
                        stylers: [{ visibility: 'off' }],
                    },
                ],
            });
            marker = new window.google.maps.Marker({ position: { lat, lng }, map: gmap });
            setMapa(Object.assign(mapa, { gmap, marker }));
        }
        return () => {
            marker && marker.setMap(null);
        };
    }, [lat, lng]);

    return (
        <div className={`location-point-map ${className}`}>
            <div className="map-div" ref={ref} />
            {!mapa.gmap && (
                <div className="map-fallback-container">
                    <LocationIcon className="location-icon" />
                    {!mapa.gmap && (
                        <div className="map-fallback-msg">
                            {!mapa.gmap
                                ? 'Error al crear mapa :('
                                : 'No se pudo cargar google maps.'}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default LocationPointMap;
