import React from 'react';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import TimerIcon from '@material-ui/icons/Timer';
import Paper from '@material-ui/core/Paper';

const Reboot = ({ tabStore }) => {
    return (
        <Paper className="reboot comando">
            <h3>Reboot</h3>
            <div className="comando-content">
                <p>OJO: no chequea si hay servida en proceso.</p>
                <div className="conectar">
                    <Button
                        endIcon={tabStore.loading ? <TimerIcon /> : null}
                        onClick={tabStore.confirmReboot}
                        variant="contained"
                        color="primary"
                        disabled={tabStore.loading}
                    >
                        Reboot
                    </Button>
                </div>
            </div>
        </Paper>
    );
};

export default observer(Reboot);
