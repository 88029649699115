import React from 'react';
import View from './View';
import store from './store';
import {
  runAllHooks,
  checkUserIsSignedIn,
  checkUserIsAdmin,
  onRouterViewEnter,
  onRouterViewExit
} from '@/state/routerUtils';

const viewConf = {
  name : 'notificationTest',
  pattern: '/notification-test',
  onEnter: runAllHooks(
      checkUserIsSignedIn,
      checkUserIsAdmin,
      onRouterViewEnter
  ),
  onExit: onRouterViewExit,
  component: <View />,
  store
};

export default viewConf;
