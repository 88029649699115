import React from 'react';
import View from './View';
import store from './store';
import {
  runAllHooks,
  checkUserIsSignedIn,
  onRouterViewEnter,
  onRouterViewExit
} from '@/state/routerUtils';

import { ROLE } from 'klara-common';

const fuenteViewConfig = {
  name : 'fuente',
  pattern : '/fuente/:id/:tab?',
  onEnter : runAllHooks(
              checkUserIsSignedIn,
              // check if has access to fuente
              (fromState, toState, routerStore) => {
                try {
                  const { rootStore : {userStore}} = routerStore;
                  const {user} = userStore;
                  if (user.role ===  ROLE.ADMIN ||
                     (user.fuentes && Array.isArray(user.fuentes) && user.fuentes.includes(toState.params.id))) {
                       return Promise.resolve();
                  } else {
                    return Promise.reject()
                  }
                } catch (e) {
                  console.error(e);
                }
              },
              onRouterViewEnter
            ),
  onExit : onRouterViewExit,
  component : <View />,
  persistStore: true,
  backTo: 'fuentes',
  store
};

export default fuenteViewConfig;
