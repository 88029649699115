import { observable, action, runInAction } from 'mobx';
import { RouterState } from 'mobx-state-router';
import { first } from 'lodash';

import { http, ROLE } from 'klara-common';

export default class InviteView {
    @observable loginVisible = true;
    @observable errorMsg = null;

    constructor(parent) {
        this.rootStore = parent;
        this.init();
    }

    init = () => {
        const { user } = this.rootStore.userStore;
        if (user && user.role) {
            this.rootStore.routerStore.goTo(new RouterState('dashboard'));
        }
        this.rootStore.alternativeLoginHandler = this.onLoginAttempt;
    };

    onExit = () => {
        this.rootStore.alternativeLoginHandler = null;
    };

    @action
    onLoginAttempt = async authResult => {
        if (authResult) {
            this.loginVisible = false;
            this.rootStore.setLoading(true);
            const { inviteId } = this.rootStore.routerStore.routerState.params;
            http.post('/api/activateInvitedUser', { inviteId })
                .then(res => {
                    if (res.body.role === ROLE.ADMIN) {
                        let user = { ...first(authResult.providerData), ...res.body };
                        user.uid = authResult.uid;
                        user.emailVerified = authResult.emailVerified;
                        this.rootStore.userStore.setUser(user);
                        this.rootStore.setLoading(false);
                        this.rootStore.routerStore.goTo('dashboard');
                    } else {
                        throw new Error('invitacion incompleta');
                    }
                })
                .catch(e =>
                    runInAction(() => {
                        console.log('catch de activateInvitedUser', e);
                        this.rootStore.userStore.noPermsAction(`
                            Lo sentimos. No se encontró la invitación o ha caducado.
                            Si necesitas ayuda escribe a ayuda@klara.pe.
                          `);
                    })
                );
        } else {
            this.rootStore.setLoading(false);
        }
    };
}
