import React from 'react';
import { observer } from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import ArrowRight from '@material-ui/icons/ArrowForwardIos';

import { PLAN } from 'klara-common';
import FuenteStateChip from '@/components/FuenteStateChip';
import ConnectionState from '@/components/ConnectionState';

const DesktopList = ({ locations, goTo }) => (
    <Table>
        <TableHead>
            <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>estado</TableCell>
                <TableCell>plan</TableCell>
                <TableCell>saldo</TableCell>
                <TableCell>online</TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {locations.map((loc, idx) => {
                const fuente = loc.fuente || null;
                return (
                    <TableRow key={`l-${idx}`}>
                        <TableCell>{loc.name}</TableCell>
                        <TableCell>
                            {!!fuente && (
                                <FuenteStateChip fuente={fuente} />
                            )}
                        </TableCell>
                        <TableCell>{loc.plan}</TableCell>
                        <TableCell>
                            {loc.plan === PLAN.PREPAGO && ((loc.saldo || 0) / 1000).toFixed(2) + 'l'}
                        </TableCell>
                        <TableCell>
                            <ConnectionState
                                state={fuente ? fuente.online : null}
                                since={fuente ? fuente.onlineModified: null}
                                showLabel={false}
                            />
                        </TableCell>
                        <TableCell width={48} padding="checkbox">
                            <IconButton onClick={() => goTo('location', { id: loc.id })}>
                                <ArrowRight />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    </Table>
);

export default observer(DesktopList);
