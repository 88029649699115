

const viewPropsMapper = (allStores, props, context) => {
  const {rootStore} = allStores;
  const {userStore, viewStore, routerStore } = rootStore;
  return {
    rootStore,
    viewStore,
    userStore,
    goTo: routerStore.goTo.bind(routerStore),
    route : routerStore.routerState
  }
}

export {viewPropsMapper};
