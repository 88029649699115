import { observable, action, when, computed, runInAction } from 'mobx';
import { RouterState } from 'mobx-state-router';
import { first } from 'lodash';
import { db, firebaseAuth, COLS, ROLE } from 'klara-common';

export default class User {
    @observable user = null;
    @observable userIsLoading = true;
    @observable messages = [];

    @computed get isSignedIn() {
        return !!this.user && !!this.user.uid;
    }

    @computed get role() {
        return this.user ? this.user.role : 'anonymous';
    }

    @computed get readOnly() {
        return this.user ? !!this.user.readOnly : false;
    }

    signInRedirectState = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.registerAuthListener();
    }

    @action
    onLoginAttempt = authResult => {
        if (!this.rootStore.alternativeLoginHandler) {
            if (authResult) {
                db.collection(COLS.PERFIL)
                    .doc(authResult.uid)
                    .get()
                    .then(res =>
                        runInAction(() => {
                            if (res.exists && res.data().role === ROLE.ADMIN) {
                                const profile = res.data();
                                let user = { ...first(authResult.providerData), ...profile };
                                user.uid = authResult.uid;
                                user.emailVerified = authResult.emailVerified;
                                this.user = user;
                                this.rootStore.setLoading(false);
                                this.userIsLoading = false;
                                if (this.signInRedirectState) {
                                    const redirectName =
                                        typeof this.signInRedirectState === 'string'
                                            ? this.signInRedirectState
                                            : this.signInRedirectState.routeName;
                                    const currentName = this.rootStore.routerStore.routerState
                                        .routeName;
                                    if (
                                        currentName !== '__initial__' &&
                                        redirectName !== currentName
                                    ) {
                                        this.rootStore.routerStore.goTo(this.signInRedirectState);
                                    }
                                } else {
                                    const currentName = this.rootStore.routerStore.routerState
                                        .routeName;
                                    if (this.rootStore.loginViews.includes(currentName)) {
                                        this.rootStore.routerStore.goTo('dashboard');
                                    }
                                }
                            } else {
                                this.noPermsAction(
                                    'Uy! Tu cuenta no ha sido activada. Contacta a un administrador.'
                                );
                            }
                        })
                    )
                    .catch(e => {
                        this.noPermsAction(
                            'Uy! Hubo un problema al acceder a tu perfil. Intenta denuevo!'
                        );
                    });
            } else {
                if (
                    !this.rootStore.noLoginViews.includes(
                        this.rootStore.routerStore.routerState.routeName
                    )
                ) {
                    this.resetUser();
                } else {
                    this.rootStore.setLoading(false);
                }
            }
        } else {
            this.rootStore.alternativeLoginHandler(authResult);
        }
    };

    @action
    deleteUserMessages = () => {
        this.messages = [];
    };

    registerAuthListener() {
        this.rootStore.setLoading(true);
        this.unregisterAuthObserver = firebaseAuth.onAuthStateChanged(this.onLoginAttempt);
    }

    logout = () => {
        console.log('logout!');
        if (this.rootStore.viewStore) {
            this.rootStore.onExit && this.rootStore.onExit();
        }
        return firebaseAuth.signOut().then(() => {
            this.resetUser();
            return Promise.resolve(true);
        });
    };

    @action noPermsAction(msg) {
        console.log('noPermsAction', msg);
        this.rootStore.setLoading(false);
        this.logout().then(() =>
            runInAction(() => {
                this.resetUser();
                console.log('logout aca');
                this.messages.push({
                    action: () => window.location.reload(),
                    severity: 'error',
                    content:
                        msg ||
                        'Lo sentimos, tu cuenta no ha sido activada. Si necesitas ayuda escribe a ayuda@klara.pe',
                });
            })
        );
    }

    @action setUser = user => {
        this.user = user;
        this.userIsLoading = false;
        this.messages = [];
    };

    resetUser = () => {
        this.user = null;
        this.rootStore.routerStore.goTo(new RouterState('login'));
        this.rootStore.setLoading(false);
        this.userIsLoading = false;
    };

    setSignInRedirect = state => {
        this.signInRedirectState = state;
    };

    waitForUser = () =>
        new Promise((resolve, reject) => {
            if (this.userIsLoading) {
                when(
                    () => this.userIsLoading === false,
                    () => resolve(this.isSignedIn)
                );
            } else {
                resolve(this.isSignedIn);
            }
        });
}
