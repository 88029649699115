import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { orderBy } from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import SnackContent from '@/components/SnackContent';
import { viewPropsMapper } from '@/views/utils';
import CreateUpdateDialog from './CreateUpdateDialog';
import './fuente-updates.scss';

const FuenteUpdatesView = ({ viewStore: store, fullscreen }) => {
    const fuenteUpdates = orderBy([...store.fuenteUpdates.values()], ['created'], ['desc']).map(data => (
        <TableRow key={data.created}>
            <TableCell>{data.tag}</TableCell>
            <TableCell>{moment(data.created).format('D MMM YYYY, H:mm')}</TableCell>
            <TableCell>
                {data.devices ? data.devices.join(', ') : 'todas'}
            </TableCell>
            <TableCell>
                <div className="update-progress">
                    <LinearProgress
                        className="linear-progress"
                        variant="determinate"
                        value={!data.numUpdated ? 0 : (data.numUpdated * 100) / data.total}
                    />
                    <div className="progress-num">
                        {data.numUpdated || 0} de {data.total}
                    </div>
                </div>
            </TableCell>
        </TableRow>
    ));

    return (
        <Fragment>
            <Container className="updates-view-container klara-main-container">
                {!!store.messages && !!store.messages.length && (
                    <div className="admin-user-messages">
                        {store.messages.map((m, i) => (
                            <SnackContent
                                key={`msg-${i}`}
                                variant={m.variant}
                                onClose={() => store.deleteMessage(i)}
                            >
                                {m.content}
                            </SnackContent>
                        ))}
                    </div>
                )}
                <div className="add-new-holder">
                    <Button
                        onClick={() => store.setDialogOpen(true)}
                        variant="contained"
                        size="large"
                        color="primary"
                    >
                        Nuevo Update
                    </Button>
                </div>
                <div className="updates-list-conatainer">
                    {!!fuenteUpdates.length ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{`Version (git tag)`}</TableCell>
                                    <TableCell>{`creado`}</TableCell>
                                    <TableCell>{`devices`}</TableCell>
                                    <TableCell>{`progreso`}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{fuenteUpdates}</TableBody>
                        </Table>
                    ) : (
                        <p>No hay updates todavia.</p>
                    )}
                </div>
            </Container>
            <CreateUpdateDialog />
        </Fragment>
    );
};

export default inject(viewPropsMapper)(observer(FuenteUpdatesView));
