import React from 'react';
import Typography from '@material-ui/core/Typography';

import LocationWatchList from '../LocationWatchList';

const DrainWarningItems = ({ locs, fuentes }) => (
    <LocationWatchList
        title="DRENAJE: LITROS 3 ULTIMOS DIAS"
        locs={locs}
        getValue={loc => (
            <Typography color={loc.mlLast3days <= 6000 ? 'error' : undefined}>
                {(loc.mlLast3days / 1000).toFixed(1)}l
            </Typography>
        )}
        getSecondaryText={loc =>
            (fuentes.get(loc.fuenteId) || {}).online === false ? 'offline' : undefined
        }
    />
);

export default DrainWarningItems;
