import React from 'react';

import './form-field-display.scss';

const FormFieldDisplay = ({ label, content }) => (
    <div className="field-item-display">
        <div className="field-item-display-label">{label}</div>
        <div className="field-item-display-content">{content || '--sin definir--'}</div>
    </div>
);

export default FormFieldDisplay;
