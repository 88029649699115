import { observable, action, runInAction } from 'mobx';
import { nanoid } from 'nanoid';

import { COLS, dbSetBatch } from 'klara-common';

export default class GenerateQRsStore {
    @observable amount = 30;
    @observable litrosProm = 2;
    @observable litros = 0;
    @observable ids = [];
    @observable msg = null;
    @observable error = null;

    @action setAmount = e => {
        this.amount = e.target.value ? parseInt(e.target.value) : '';
    };
    @action setLitros = e => {
        this.litros = e.target.value ? parseInt(e.target.value) : '';
    };
    @action setLitrosProm = e => {
        this.litrosProm = e.target.value ? parseInt(e.target.value) : '';
    };

    constructor(parent) {
        this.rootStore = parent;
    }

    @action generateIds = () => {
        this.msg = null;
        this.error = null;
        this.ids = new Array(this.amount).fill(null).map(() => nanoid());
    };

    @action clear = () => {
        this.ids = [];
        this.msg = null;
    };

    @action register = () => {
        if (this.ids.length) {
            this.msg = null;
            this.error = null;

            let items = this.ids.reduce((acc, curr) => {
                acc[curr] = {
                    saldoProm: parseInt(this.litrosProm) * 1000,
                    saldo: parseInt(this.litros) * 1000,
                    primeraVez: true,
                    active: true,
                    fechaActivacion: new Date(),
                };
                return acc;
            }, {});

            this.rootStore.setLoading(true);
            dbSetBatch(COLS.QR, items)
                .then(res =>
                    runInAction(() => {
                        this.msg = 'QRs registrados con éxito!';
                    })
                )
                .catch(e =>
                    runInAction(() => {
                        this.error = 'Sucedió un error al registrar. Chequear logs!';
                        console.error(e);
                    })
                )
                .finally(() => {
                    this.rootStore.setLoading(false);
                });
        }
    };
}
