import { observable, action } from 'mobx';
import { startOfToday, subDays, endOfToday, endOfDay } from 'date-fns';

import { db, COLS } from 'klara-common';

export default class ServidasTabStore {
    @observable servidas = [];
    @observable loading = false;
    @observable range = {
        endDate: endOfToday(),
        startDate: subDays(startOfToday(), 2),
    };

    constructor(main) {
        this.main = main;
        this.getServidas();
    }

    async getServidas() {
        const result = await db
            .collection(COLS.SERVIDA)
            .where('locationId', '==', this.main.locationId)
            .where('timestamp', '>=', this.range.startDate)
            .where('timestamp', '<=', endOfDay(this.range.endDate))
            .orderBy('timestamp', 'desc')
            .get();
        this.servidas = result.docs.map( doc => doc.data());
    }

    @action setRange = (range) => {
        this.range = range;
        this.getServidas();
    };
}
