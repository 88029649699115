import React from 'react';
import { observer } from 'mobx-react';

import ConnectionState from '@/components/ConnectionState';
import FuenteStateChip from '@/components/FuenteStateChip';

import './status-indicators.scss';

const StatusIndicators = ({ fuente, location }) => (
    <div className="status-indicators">
        <div className="indicator">
            <FuenteStateChip fuente={fuente} />
        </div>
        <div className="indicator">
            <ConnectionState state={fuente.online} since={fuente.onlineModified} />
        </div>
        <div className="indicator">{location.plan}</div>
        {location.plan === 'prepago' && (
            <div className="indicator saldo">
                <div className="saldo-label">saldo:</div>
                <div className="saldo-value">{((location.saldo || 0) / 1000).toFixed(2)} l</div>
            </div>
        )}
    </div>
);

export default observer(StatusIndicators);
