import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';

import './snack-content.scss';

const SnackContent = ({children, variant, onClose, icon, className, ...other}) =>
  <SnackbarContent
    className={cn('snack-content', variant, className)}
    message={
      <span className="snack-content-msg-inner">
        <div className="snack-content-inner-icon">{icon}</div>
        <div className="snack-content-inner-msg">{children}</div>
      </span>
    }
    classes={{
      message : 'snack-content-message',
      action: 'snack-content-action'
    }}
    action={[
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        className="snack-content-close-btn"
        onClick={e => !!onClose && onClose()}
      >
        <CloseIcon className="snack-content-close-icon" />
      </IconButton>,
    ]}
    {...other}
  />

SnackContent.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
  onClose: PropTypes.func,
  icon: PropTypes.node,
  className : PropTypes.string
}

SnackContent.defaultProps = {
  type: 'error',
  icon: <InfoIcon className={cn('snack-content-icon', 'icon-info')} />
}

export default SnackContent;
