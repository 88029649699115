import { computed } from 'mobx';
import moment from 'moment';
import { first, last } from 'lodash';

import {
    limaMoment,
    getNumLocationActiveDays,
    HEATMAP_PERIODS,
    SERVE_SIZE_BUCKETS,
} from 'klara-common';

export default class LocationHomeTabStore {
    @computed get numActiveDays() {
        const startDate = this.main.startDate.toDate();
        const endDate = (
            this.main.endDate ||
            limaMoment()
                .startOf('month')
                .add(1, 'month')
        ).toDate();
        const endOfToday = limaMoment()
            .startOf('day')
            .add(1, 'day')
            .toDate();
        let realEndDate = limaMoment(endDate).isAfter(limaMoment(endOfToday))
            ? endOfToday
            : endDate;
        return getNumLocationActiveDays(
            this.main.location.activePeriods || [],
            startDate,
            realEndDate
        );
    }

    @computed get totals() {
        let ml = 0;
        let mlProm = 0;
        let cents = 0;
        let servidas = 0;

        this.main.servsXDia.forEach(s => {
            cents += s.cents;
            ml += s.mlPaid || s.ml;
            mlProm += s.mlProm || 0;
            servidas += s.servs;
        });
        return {
            litros: ml / 1000,
            litrosProm: mlProm / 1000,
            soles: cents / 100,
            servidas,
        };
    }

    @computed get litrosXDia() {
        return this.totals.litros / (this.numActiveDays || 1);
    }

    @computed get daysData() {
        return this.main.servsXDia.map(d => ({
            dateStr: moment(d.timestamp.toDate()).format('D MMM'),
            soles: d.cents / 100,
            litros: (d.mlPaid || d.ml) / 1000,
            litrosProm: d.mlProm / 1000,
        }));
    }

    @computed get servSizeDistribution() {
        return SERVE_SIZE_BUCKETS.map(bucket => ({
            label: bucket,
            value: this.main.servsXMes.reduce(
                (acc, curr) => acc + ((curr.servSize && curr.servSize[bucket]) || 0),
                0
            ),
        }));
    }

    @computed get totalsXMes() {
        let servidas = this.main.servsXMes.reduce((acc, curr) => acc + curr.servs, 0);
        let ml = this.main.servsXMes.reduce((acc, curr) => acc + curr.mlPaid, 0);
        let cents = this.main.servsXMes.reduce((acc, curr) => acc + curr.cents, 0);
        let servsCash = this.main.servsXMes.reduce((acc, curr) => acc + (curr.servs_cash || 0), 0);
        let servsApp = this.main.servsXMes.reduce((acc, curr) => acc + (curr.servs_user || 0), 0);
        let centsApp = this.main.servsXMes.reduce((acc, curr) => acc + (curr.cents_user || 0), 0);
        let mlApp = this.main.servsXMes.reduce((acc, curr) => acc + (curr.ml_user || 0), 0);
        let servsQR = this.main.servsXMes.reduce((acc, curr) => acc + (curr.servs_qr || 0), 0);
        let centsQR = this.main.servsXMes.reduce((acc, curr) => acc + (curr.cents_qr || 0), 0);
        let mlQR = this.main.servsXMes.reduce((acc, curr) => acc + (curr.ml_qr || 0), 0);
        return {
            avgMlXServ: servidas ? ml / servidas : 0,
            avgCentsXServ: servidas ? cents / servidas : 0,
            servsApp,
            solesApp: centsApp / 100,
            litrosApp: mlApp / 1000,
            servsQR,
            solesQR: centsQR / 100,
            litrosQR: mlQR / 1000,
            percentCash: servidas ? (servsCash * 100) / servidas : 0,
            percentApp: servidas ? (servsApp * 100) / servidas : 0,
            percentQR: servidas ? (servsQR * 100) / servidas : 0,
        };
    }

    @computed get averageServsPerDay() {
        let servidas = this.main.servsXDia.reduce((acc, curr) => acc + curr.servs, 0);
        if (servidas === 0) return 0;
        const firstDay = moment(first(this.main.servsXDia).timestamp.toDate()).startOf('day');
        const lastDay = moment(last(this.main.servsXDia).timestamp.toDate())
            .endOf('day')
            .add(1, 'day');
        return servidas / lastDay.diff(firstDay, 'day');
    }

    constructor(main) {
        this.main = main;
    }

    /******************* WEEKLY HEATMAP *************************/

    getPeriodKey = hour => {
        for (let p of HEATMAP_PERIODS) {
            if (hour >= p.start && hour < p.end) {
                return this.makePeriodKey(p);
            }
        }
    };

    makePeriodKey = p => {
        if (p.end - p.start === 1) {
            return p.start + 'h';
        } else {
            return p.start + '-' + p.end + 'h';
        }
    };

    @computed get weekHeatMapData() {
        const result = [];
        const data = this.main.servsXMes.reduce((acc, curr) => {
            Object.keys(curr.servXHora || {}).forEach(day => {
                if (!acc[day]) {
                    acc[day] = {};
                }
                Object.keys(curr.servXHora[day]).forEach(h => {
                    if (!acc[day][h]) {
                        acc[day][h] = curr.servXHora[day][h];
                    } else {
                        acc[h] += curr.servXHora[day][h];
                    }
                });
            });
            return acc;
        }, {});

        ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].forEach(day => {
            let dayData = [];
            HEATMAP_PERIODS.forEach(period => {
                const pName = this.makePeriodKey(period);
                let pAmount = 0;
                if (data[day]) {
                    for (let h in data[day]) {
                        h = parseInt(h);
                        if (h >= period.start && h < period.end) {
                            pAmount += data[day][h];
                        }
                    }
                }
                dayData.push({ period: pName, value: pAmount, index: 1 });
            });
            result.push({ day, data: dayData });
        });
        return result;
    }

    onSnapshotError = e => console.error('SNAP ERROR', e);
}
