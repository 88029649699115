import React from 'react';
import { observer } from 'mobx-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import './thumb.scss';

const Thumb = ({ img, onDelete }) => {
    const url = img.url || img.tmpUrl;
    return (
        <div className="image-upload-thumb">
            <div className="img-holder" style={{ backgroundImage: `url(${url})` }} />
            {!!img.uploading && (
                <LinearProgress
                    variant="determinate"
                    value={img.progress}
                    className="upload-progress"
                />
            )}
            <div className="interaction-surface">
                <IconButton size="small" className="delete-button" onClick={onDelete}>
                    <CloseIcon />
                </IconButton>
            </div>
        </div>
    );
};

export default observer(Thumb);
