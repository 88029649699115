import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import AddIcon from '@material-ui/icons/Add';

import { FuenteFormDialog } from '@/components/FuenteFormDialog';
import { viewPropsMapper } from '@/views/utils';
import DesktopList from './DesktopList';
import MobileList from './MobileList';
import FilterControl from './FilterControl';

import './fuentes.scss';

const HomeView = ({ viewStore, goTo }) => {
    if (!viewStore) return null;
    const { fuentesSorted } = viewStore;

    return (
        <Fragment>
            <Container className="home-view-container">
                <div className="title-holder">
                    <h1>Fuentes</h1>
                    <div className="add-new-holder">
                        <IconButton onClick={viewStore.openAddFuenteForm}>
                            <AddIcon />
                        </IconButton>
                    </div>
                </div>
                <div className="filters">
                    <FilterControl viewStore={viewStore} />
                </div>
                {!!fuentesSorted && !!fuentesSorted.length ? (
                    <Fragment>
                        <Hidden mdUp>
                            <MobileList {...{ fuentesSorted, goTo }} />
                        </Hidden>
                        <Hidden smDown>
                            <DesktopList {...{ fuentesSorted, goTo }} />
                        </Hidden>
                    </Fragment>
                ) : (
                    <p>No hay fuentes todavia.</p>
                )}
            </Container>
            <FuenteFormDialog store={viewStore.fuenteFormStore} />
        </Fragment>
    );
};

export default inject(viewPropsMapper)(observer(HomeView));
