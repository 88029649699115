const Finalizar = {
   submitLabel : 'Enviar Solicitud',
   sections: [
     {
       title : 'Enviar',
       fields : [
         {
           key: 'comentario',
           label: 'Déjanos un comentario (opcional)',
           type: 'textfield',
           props: {
             multiline: true
           }
         }
       ]
     }
   ]
}

export default Finalizar;
