import React from 'react';

import './success.scss';

const Success = () =>
  <div className="success">
    <h1>¡Gracias!</h1>
    <div className="msg-wrap">
      <p className="emoji">🎉 ❤️</p>
      <p>Tu solicitud se ha enviado con éxito. Nos pondremos en contacto contigo. Puedes cerrar esta página.</p>
      <p>¡Saludos!</p>
      <p>El equipo Klara</p>
    </div>
  </div>

export default Success;
