import React from 'react';

import store from './store';
import LoginFormPage from '@/components/LoginForm';
import {
  checkUserIsSignedOut,
  onRouterViewEnter,
  onRouterViewExit,
  runAllHooks
} from '@/state/routerUtils';
import {
} from '@/state/routerUtils';

const loginViewConf = {
  name : 'login',
  pattern : '/login',
  onEnter : runAllHooks(onRouterViewEnter, checkUserIsSignedOut),
  onExit: onRouterViewExit,
  component: <LoginFormPage />,
  isLoginView: true,
  store
};

export default loginViewConf;
