import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { viewPropsMapper } from '@/views/utils';
import InviteDialog from './invite-dialog';
import EditUserDialog from './edit-user-dialog';
import UserTab from './user-tab';
import InviteTab from './invite-tab';
import SnackContent from '@/components/SnackContent';
import './user-admin.scss';

const UserAdminHomeView = ({ viewStore: store, fullscreen }) => (
    <Fragment>
        <Container style={{paddingTop: 16}}>
            <div className="tab-container">
                <Tabs value={store.tab} onChange={store.changeTab}>
                    <Tab label="USUARIOS" />
                    <Tab label="INVITAR" />
                </Tabs>
            </div>
            {!!store.messages && !!store.messages.length && (
                <div className="admin-user-messages">
                    {store.messages.map((m, i) => (
                        <SnackContent
                            key={`msg-${i}`}
                            variant={m.variant}
                            onClose={() => store.deleteMessage(i)}
                        >
                            {m.content}
                        </SnackContent>
                    ))}
                </div>
            )}
            <div className="tab-content-container">
                {store.tab === 0 && (
                    <div className="tab-content">
                        <UserTab />
                    </div>
                )}
                {store.tab === 1 && (
                    <div className="tab-content">
                        <InviteTab />
                    </div>
                )}
            </div>
        </Container>
        <InviteDialog />
        <EditUserDialog />
    </Fragment>
);

export default inject(viewPropsMapper)(observer(UserAdminHomeView));
