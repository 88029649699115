import React from 'react';
import View from './View';
import store from './store';
import {
  runAllHooks,
  checkUserIsSignedIn,
  checkUserIsAdmin,
  onRouterViewEnter,
  onRouterViewExit
} from '@/state/routerUtils';

const fuentesUpgradeConf = {
  name : 'fuenteUpdates',
  pattern: '/fuente-updates',
  onEnter: runAllHooks(
      checkUserIsSignedIn,
      checkUserIsAdmin,
      onRouterViewEnter
  ),
  onExit: onRouterViewExit,
  component: <View />,
  store
};

export default fuentesUpgradeConf;
