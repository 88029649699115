
const AutoTest = {
  maxPoints: 12,
  submitLabel: 'Evaluar',
  sections: [{
    title: 'Breve evaluación',
    desc: `Para hospedar una fuente Klara con éxito, esta debe vender un promedio anual de
          50 litros diarios (en invierno baja, en verano sube). Hemos recopilado las siguientes
          preguntas para determinar las posibilidades de éxito de tu espacio:`,
    fields: [
        {
          key: 'horasAlDia',
          label: 'Horarios de apertura',
          labelPlacement: 'end',
          type: 'radio',
          options: [
            {value: '0-7', label: 'Menos de 8', points: 0},
            {value: '8-10', label: '8 a 10', points: 2},
            {value: '11-16', label: '10 a 16', points: 3},
            {value: '17-24', label: '17 o más', points: 3.5}
          ]
        },
        {
          key: 'diasPorSemana',
          label: 'Dias por semana',
          labelPlacement: 'end',
          type: 'radio',
          options: [
            {value: '0-4', label: 'Menos de 5', points: 0},
            {value: '5', label: '5 días', points: 1},
            {value: '6', label: '6 días', points: 2},
            {value: '7', label: '7 días', points: 3}
          ]
        },
        {
          key: 'personasTrabajan',
          type: 'radio',
          label: 'Cuántas personas trabajan/estudian en (o cerca de) tu establecimiento diariamente?',
          labelPlacement: 'end',
          options: [
            {value: '0-200', label: 'Menos de 200', points: 0},
            {value: '200-500', label: '200 a 500', points: 1},
            {value: '500-1000', label: '500 a 1000', points: 2},
            {value: '1000+', label: '1000 o más', points: 4}
          ]
        },
        {
          key: 'personasPasan',
          type: 'radio',
          label: 'Cuántas personas trabajan/estudian en (o cerca de) tu establecimiento diariamente?',
          labelPlacement: 'end',
          options: [
            {value: '0-200', label: 'Menos de 200', points: 0},
            {value: '200-500', label: '200 a 500', points: 0.5},
            {value: '500-1000', label: '500 a 1000', points: 1},
            {value: '1000-2000', label: '1000 a 2000', points: 2},
            {value: '2000+', label: '2000 o más', points: 3}
          ]
        },
        {
            key: 'entornos',
            type: 'multicheckbox',
            label: 'Escoge una opción (puede ser mas de una). Tu local está cerca o dentro de:',
            options: [
              {
                value: 'centroDeEstudio',
                label: 'Centro de estudio',
                points: 3
              },
              {
                value: 'mercado',
                label: 'Mercado',
                points: 3
              },
              {
                value: 'galeria',
                label: 'Galería / Centro comercial',
                points: 3
              },
              {
                value: 'terminalTransporte',
                label: 'Terminal de transporte',
                points: 2
              },
              {
                value: 'granUnidadResidencial',
                label: 'Gran unidad residencial',
                points: 2
              },
              {
                value: 'lugarTuristico',
                label: 'Lugar turístico muy visitado',
                points: 2
              },
              {
                value: 'entornoOtro',
                label: 'Otros', // TODO: especificar!
                points: 2
              }
          ],
          showChildrenCondition: {
            value: 'entornoOtro',
          },
          children : [
            {
              key : 'entornoOtroEspecificar',
              label: 'Especificar opción "otro"',
              type: 'textfield'
            }
          ]
        }
      ]
    }
  ],
  pointLevels : [
    {
      min: -0.1,
      max: 0.333333,
      emoji: '😕',
      msg: `Hmm... lo mas probable es que la fuente
            no resulte rentable. Asegurate de llenar bien todos los campos!`
    },
    {
      min: 0.333333,
      max: 0.666666,
      emoji: '😐',
      msg: `Al parecer las características de tu espacio no cumplen con los
            niveles de venta ideales para la instacion de una fuente IGUA. Aun así,
            te pedimos que continúes con el proceso para que alguien del equipo Klara
            pueda evaluar con más detalle tu solicitud.`
    },
    {
      min: 0.666666,
      max: 0.9,
      emoji: '😀',
      msg: `¡Bien ahí! Las probabilidades de que la fuente sea rentable son altas.
            Continua para enviar tus datos y ayudar al equipo de Klara a procesar tu solicitud.`
    },
    {
      min: 0.9,
      max: Infinity,
      emoji: '😍',
      msg: `¡Wow! Las probabilidades de que la fuente sea rentable son muy altas!
            Continua para enviar tus datos y ayudar al equipo de Klara a procesar tu solicitud.`
    }
  ]
}

export default AutoTest;
