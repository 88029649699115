import React from 'react';
import { observer } from 'mobx-react';

import HistoBoxPlot from '@/components/HistogramWithBoxplot';
import StatRow from '@/components/StatRow';
import AnalysisToolBar from './AnalysisToolBar';
import Filters from '../Filters';

import './analysis-tab.scss';

const FuenteAnalysisTab = ({ tabStore }) => (
    <div className="fuente-analysis-tab">
        <Filters />
        <AnalysisToolBar />
        <h2 className="h3-title">Análisis servidas por intervalos cortos</h2>
        <p className="p-desc">Ojo que la data excluye los intervalos sin servidas.</p>

        <div className="chart-section">
            <h3 className="chart-title">
                Distribucion número de servidas en intervalos de {tabStore.timeBucketSize} minutos
            </h3>
            <HistoBoxPlot data={tabStore.timeStats.servsPerPeriod} />
        </div>

        <div className="chart-section">
            <h3 className="histogram-title chart-title">
                Distribucion mililitros servidos en intervalos de {tabStore.timeBucketSize} minutos
            </h3>
            <HistoBoxPlot data={tabStore.timeStats.mlPerPeriod} />
        </div>

        <StatRow
            align="center"
            values={[
                {
                    label: `Promedio ml/${tabStore.timeBucketSize}m`,
                    value: tabStore.timeStats.ml.mean.toFixed(1),
                },
                {
                    label: `Desviación standard ml/${tabStore.timeBucketSize}m`,
                    value: tabStore.timeStats.ml.sd.toFixed(1),
                },
            ]}
        />

        <StatRow
            align="center"
            values={[
                { label: `84% del tiempo`, value: `${tabStore.timeStats.ml.sd1x.toFixed(1)} ml` },
                {
                    label: `Desviación standard ml/${tabStore.timeBucketSize}m`,
                    value: `${tabStore.timeStats.ml.sd2x.toFixed(1)} ml`,
                },
            ]}
        />

        <div className="chart-section">
            <h3 className="chart-title">Distribución intervalos entre servidas (minutos)</h3>
            <HistoBoxPlot data={tabStore.timeStats.timeDelta.distrib} />
        </div>

        <StatRow
            align="center"
            values={[
                {
                    label: `Promedio intervalo entre servidas`,
                    value: `${tabStore.timeStats.timeDelta.mean.toFixed(1)} min`,
                },
                {
                    label: `Desviación standard intervalo entre servidas`,
                    value: `${tabStore.timeStats.timeDelta.sd.toFixed(1)} min`,
                },
            ]}
        />

        <StatRow
            align="center"
            values={[
                {
                    label: '84% del tiempo',
                    value: `${tabStore.timeStats.timeDelta.sd1x.toFixed(1)} min`,
                },
                {
                    label: '97% del tiempo',
                    value: `${tabStore.timeStats.timeDelta.sd2x.toFixed(1)} min`,
                },
            ]}
        />
    </div>
);

export default observer(FuenteAnalysisTab);
