import React from 'react';
import { observer } from 'mobx-react';

import { FormDialog } from '@/components/Form';

const formConfig = {
    sections: [
        {
            fields: [
                {
                    key: 'litros',
                    label: 'Litros',
                    type: 'textfield',
                    required: true,
                    validateType: 'number',
                },
            ],
        },
    ],
};

const ForceSaldoFormDialog = ({ store }) =>
    store ? (
        <FormDialog
            title={'Forzar saldo (cuidado!)'}
            open={store.openForceSaldoForm}
            loading={store.loading}
            onClose={() => store.setOpenForceSaldoForm(false)}
            onSubmit={store.onForceSaldoSubmit}
            config={formConfig}
        />
    ) : null;

export default observer(ForceSaldoFormDialog);
