import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { DatePicker } from '@material-ui/pickers';

import { viewPropsMapper } from '@/views/utils';
import './date-picker.scss';

const FuenteDatePicker = ({ viewStore }) =>
    viewStore ? (
        <div className="date-input-fields">
            {viewStore.advancedPickers ? (
                <Fragment>
                    <DatePicker
                        className="dpicker advanced-start"
                        value={viewStore.startDate.toDate()}
                        onChange={viewStore.setStartDate}
                        label="Desde"
                        clearable={true}
                        disableFuture={true}
                    />
                    <DatePicker
                        className="dpicker advanced-end"
                        value={viewStore.endDate ? viewStore.endDate.toDate() : null}
                        onChange={viewStore.setEndDate}
                        label="Hasta"
                        clearable={true}
                        disableFuture={true}
                    />
                </Fragment>
            ) : (
                <DatePicker
                    openTo="month"
                    className="dpicker"
                    value={viewStore.startDate}
                    onChange={viewStore.setSimpleDate}
                    label="Elije mes"
                    clearable={false}
                    views={['year', 'month']}
                    disableFuture={true}
                />
            )}
        </div>
    ) : null;

export default inject(viewPropsMapper)(observer(FuenteDatePicker));
