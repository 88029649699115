import { observable, action, toJS, runInAction } from 'mobx';

import { db, COLS, parseTimestamps } from 'klara-common';

export default class CashupTabStore {
    @observable cashups = [];
    @observable refunds = [];
    @observable loading = false;
    @observable openForm = false;
    @observable openDeleteConfirm = false;
    @observable editIndex = null;
    @observable initForm = {};
    @observable tab = 0;
    deleteIndex = null;

    constructor(main) {
        this.main = main;
        this.startCashupsListener();
        this.startRefundsListener();
    }

    startCashupsListener = () => {
        this.cashupsUnsubscribe = db
            .collection(COLS.LOCATION)
            .doc(this.main.locationId)
            .collection(COLS.CASHUP)
            .orderBy('start', 'desc')
            .onSnapshot(this.updateCashflowSnapshot);
    };

    updateCashflowSnapshot = query => {
        if (!query.empty) {
            this.cashups.replace(query.docs.map(cDoc => ({ id: cDoc.id, ...cDoc.data() })));
        } else {
            this.cashups.clear();
        }
    };

    startRefundsListener = () => {
        this.refundsUnsubscribe = db
            .collection(COLS.REFUND)
            .where('locationId', '==', this.main.locationId)
            .orderBy('timestamp', 'desc')
            .onSnapshot(this.updateRefundsSnapshot);
    };

    updateRefundsSnapshot = query => {
        if (!query.empty) {
            this.refunds.replace(query.docs.map(rDoc => rDoc.data()));
        } else {
            this.refunds.clear();
        }
    };

    onExit = () => {
        this.cashupsUnsubscribe && this.cashupsUnsubscribe();
        this.refundsUnsubscribe && this.refundsUnsubscribe();
    };

    @action setOpenForm = val => {
        if (val === true) {
            this.editIndex = null;
            const initForm = {
                end: new Date(),
                start: null,
            };
            if (this.cashups.length) {
                initForm.start =
                    this.cashups[0].end && this.cashups[0].end.toDate
                        ? this.cashups[0].end.toDate()
                        : null;
            }
            this.initForm = initForm;
        } else if (Number.isInteger(val)) {
            this.editIndex = val;
            this.initForm = parseTimestamps(toJS(this.cashups[val]));
        }
        this.openForm = val !== false;
    };

    @action onSubmit = form => {
        this.loading = true;

        db.collection(COLS.SERVIDA)
            .where('locationId', '==', this.main.locationId)
            .where('timestamp', '>=', form.start)
            .where('timestamp', '<=', form.end)
            .get()
            .then(result => {
                form.cents = 0;
                if (!result.empty) {
                    result.docs.forEach(d => {
                        form.cents += d.data().cents;
                    });
                }
                const { id, ...data } = form;
                data.fuenteId = this.main.location.fuenteId;
                data.locationId = this.main.locationId;

                const col = db
                    .collection(COLS.LOCATION)
                    .doc(this.main.locationId)
                    .collection(COLS.CASHUP);

                (id ? col.doc(id).set(data) : col.add(data)).then(() =>
                    runInAction(() => {
                        this.loading = false;
                        this.openForm = false;
                    })
                );
            });
    };

    @action setTab = val => {
        this.tab = val;
    };

    @action deleteConfirm = idx => {
        this.openDeleteConfirm = true;
        this.deleteIndex = idx;
    };

    @action deleteItem = () => {
        this.openDeleteConfirm = false;
        if (this.deleteIndex === null) return;
        const docId = this.cachups[this.deleteIndex].id;
        db.collection(COLS.LOCATION)
            .doc(this.main.locationId)
            .collection(COLS.CASHUP)
            .doc(docId)
            .delete();
    };

    @action cancelDelete = () => {
        this.openDeleteConfirm = false;
        this.deleteIndex = null;
    };
}
