import React, { useEffect, useState, useRef, memo } from 'react';
import PlaceIcon from '@material-ui/icons/Place';

import './location-point-map.scss';

const LocationEditMap = ({ pos, onChange }) => {
    const [mapCreated, setMapCreated] = useState(false);
    const mapRef = useRef();
    const markerRef = useRef();
    const ref = useRef();
    const { lat, lng } = pos;

    useEffect(() => {
        if (ref.current && lat && lng) {
            if (!mapRef.current) {
                mapRef.current = new window.google.maps.Map(ref.current, {
                    zoom: 16,
                    center: { lat, lng },
                    //disableDefaultUI: true,
                    gestureHandling: 'cooperative',
                    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                    styles: [
                        {
                            featureType: 'poi',
                            stylers: [{ visibility: 'off' }],
                        },
                    ],
                });
                setMapCreated(true);
                markerRef.current = new window.google.maps.Marker({
                    position: { lat, lng },
                    map: mapRef.current,
                    draggable: true,
                });
                const onChangePos = pos => {
                    const newPos = {
                        lat: pos.latLng.lat(),
                        lng: pos.latLng.lng(),
                    };
                    onChange && onChange(newPos);
                    mapRef.current.panTo(pos.latLng);
                };

                markerRef.current.addListener('dragend', onChangePos);
                mapRef.current.addListener('click', ev => {
                    markerRef.current.setPosition(ev.latLng);
                    onChangePos(ev);
                });
            } else {
                mapRef.current.panTo({ lat, lng });
                markerRef.current.setPosition({ lat, lng });
            }
        }
    }, [lat, lng, ref, onChange]);

    // clean up
    useEffect(
        () => () => {
            markerRef.current && markerRef.current.setMap(null);
        },
        []
    );

    return (
        <div className="location-point-map-edit">
            <div className="map-div" ref={ref} />
            {!mapCreated && (
                <div className="map-fallback-container">
                    <PlaceIcon className="location-icon" />
                    {mapCreated === false && (
                        <div className="map-fallback-msg">'Error al crear mapa :('</div>
                    )}
                </div>
            )}
        </div>
    );
};

LocationEditMap.defaultProps = {
    pos: {
        lat: -12.046374,
        lng: -77.042793,
    },
};

export default memo(LocationEditMap);
