import { observable, runInAction } from 'mobx';

import { http } from 'klara-common';

export default class NotificationTestView {
    @observable showSuccess = false;
    @observable error = null;

    constructor(parent) {
        this.rootStore = parent;
    }

    onSubmit = form => {
        this.rootStore.setLoading(true);
        setTimeout(
            () =>
                http
                    .post('/api/sendTestNotification', form)
                    .then(res =>
                        runInAction(() => {
                            this.showSuccess = true;
                            this.error = null;
                        })
                    )
                    .catch(err =>
                        runInAction(() => {
                            console.log(err);
                            this.showSuccess = false;
                            this.error = (err.error && err.error.message) || 'Hubo un error';
                        })
                    )
                    .finally(() => {
                        this.rootStore.setLoading(false);
                    }),
            form.delay || 0
        );
    };
}
