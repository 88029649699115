import React from 'react';
import { observer, inject } from 'mobx-react';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import SimpleMenu from '@/components/SimpleMenu';
import DatePicker from '../DatePicker';
import { viewPropsMapper } from '@/views/utils';

import './fuente-filters.scss';

const FuenteFilters = ({ viewStore, userStore }) => viewStore ? (
      <div className="fuente-filters">
          <div className="filters-container">
              <DatePicker />
          </div>
          <div className="menu-container">
              <SimpleMenu entries={[
                  {
                      label: viewStore.advancedPickers ? 'Modo simple' : 'Modo avanzado',
                      onClick: viewStore.toggleAdvancedPickers,
                  },
                  {
                      label: 'Download CSV',
                      icon: <CloudDownloadIcon />,
                      onClick: viewStore.downloadCsv,
                  },
              ]} />
          </div>
      </div>
    ) : null;

export default inject(viewPropsMapper)(observer(FuenteFilters));
