import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

import LocationEditMap from '@/components/LocationEditMap';
import { getDistrito, formatAddress } from './utils';
import './address-field.scss';

export default class AddressField extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.object,
        className: PropTypes.string,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        error: PropTypes.bool,
        helperText: PropTypes.node,
    };

    constructor(props) {
        super(props);
        this.state = props.value || { address: '', placeId: null, geo: null };
        this.geocoder = new window.google.maps.Geocoder();
    }

    handleChange = (address, placeId = '') => {
        this.setState({ address, placeId });
    };

    handleSelect = (address, placeId) => {
        address = address.replace(', Perú', '').replace('Distrito de ', '')
        this.handleChange(address, placeId);
        const { onChange } = this.props;
        geocodeByAddress(address)
            .then(async results => {
                const latLng = await getLatLng(results[0]);
                return { geo: latLng, distrito: getDistrito(results[0]) };
            })
            .then(res => {
                this.setState(res);
                onChange(this.state);
            })
            .catch(error => {
                onChange(this.state);
            });
    };

    handleBlur = suggestions => {
        if (suggestions && suggestions.length) {
            this.handleSelect(suggestions[0].description, suggestions[0].placeId);
        }
    };

    handleMapPointChanged = pos => {
        this.setState({geo: pos}, this.props.onChange(this.state));
        this.geocoder.geocode({ location: pos }, (results, status) => {
            if (results && results.length) {
                this.setState({
                    address: formatAddress(results[0]),
                    placeId: results[0].place_id,
                    distrito: getDistrito(results[0])
                }, () => this.props.onChange(this.state));
            }
        })
    }

    render() {
        const { placeholder, label, className, error, helperText } = this.props;
        const { geo } = this.state;
        return (
            <div className="address-field">
                <PlacesAutocomplete
                    value={this.state.address || ''}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    debounce={100}
                    searchOptions={{
                        componentRestrictions: { country: ['pe'] },
                    }}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                        return (
                            <div className={cn('address-field', className)}>
                                <TextField
                                    placeholder={placeholder}
                                    label={label}
                                    fullWidth={true}
                                    error={error}
                                    helperText={helperText}
                                    InputProps={{
                                        ...getInputProps({
                                            className: 'location-search-input',
                                            onBlur: () => this.handleBlur(suggestions),
                                        }),
                                    }}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                    }}
                                />
                            {!!suggestions && !!suggestions.length && (
                                    <Paper className="address-field-suggestions">
                                        {suggestions.map((suggest, i) => {
                                            const desc = suggest.description.replace(', Perú', '').replace('Distrito de ', '');
                                            return (
                                                <MenuItem
                                                    key={i}
                                                    {...getSuggestionItemProps(suggest)}
                                                    selected={suggest.active}
                                                >
                                                    {desc}
                                                </MenuItem>
                                            )
                                        })}
                                    </Paper>
                                )}
                            </div>
                        );
                    }}
                </PlacesAutocomplete>
                {!!geo && <LocationEditMap pos={geo} onChange={this.handleMapPointChanged} />}
            </div>
        );
    }
}
