import { find } from 'lodash';

export function getFieldPoints(form, field) {
    let points = 0;
    const fval = form[field.key];

    if (field.showOnCondition && !field.showOnCondition(form)) return 0;

    if (fval || field.type === 'subgroup') {
        switch (field.type) {
            case 'checkbox':
                points += field.points || 0;
                break;
            case 'select':
            case 'radio':
                points += find(field.options, o => o.value === String(fval)).points || 0;
                break;
            case 'multicheckbox':
                points += field.options
                    .filter(o => fval.includes(o.value))
                    .reduce((acc, item) => acc + item.points, 0);
                break;
            case 'textfield':
                points += field.points || 0;
                break;
            case 'multiselect':
                form[field.key].forEach(opt => {
                    points += opt.points;
                });
                break;
            default:
                break;
        }

        if (field.children && (field.type !== 'checkbox' || (field.type === 'checkbox' && fval))) {
            let childPoints = 0;
            field.children.forEach(f => {
                childPoints += getFieldPoints(form, f);
            });
            if (field.maxPoints && childPoints > field.maxPoints) {
                childPoints = field.maxPoints;
            }
            points += childPoints;
        }
    }
    return points;
}

export function getFormPoints(form, config) {
    let points = 0;
    config.sections.forEach(sec => {
        if (sec.showOnCondition && !sec.showOnCondition(form)) return;
        sec.fields.forEach(f => {
            points += getFieldPoints(form, f);
        });
    });
    return points;
}
