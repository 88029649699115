import React from 'react';
import View from './View';
import store from './store';
import {
  onRouterViewEnter,
  onRouterViewExit
} from '@/state/routerUtils';


const viewConf = {
  name: 'hostRecruteForm',
  pattern: '/host-form/:path',
  onEnter: onRouterViewEnter,
  onExit : onRouterViewExit,
  component: <View />,
  noLogin: true,
  persistStore: true,
  store
}

export default viewConf;
