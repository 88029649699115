
const FormConfig = {
    sections: [
        {
            fields: [
                {
                    key: 'fuenteId',
                    label: 'Fuente id (formato IGUA_XXX)',
                    type: 'textfield',
                    required: true,
                    getProps: (field, form, store) =>
                        store.initState.fuenteId
                            ? { InputProps: { readOnly: true } }
                            : { helperText: 'Debe ser igual a la de la máquina.' },
                },
                {
                    key: 'modelo',
                    label: 'Modelo',
                    type: 'select',
                    required: true,
                    options: [{ value: 'cajaAzul', label: 'Caja Azul' }],
                    default: 'cajaAzul',
                },
                {
                    key: 'board',
                    label: 'Tarjeta',
                    type: 'select',
                    required: true,
                    options: [
                        { value: 'iguino0', label: 'iguino0' },
                        { value: 'iguino1', label: 'iguino1' },
                        { value: 'iguino2', label: 'iguino2' },
                    ],
                },
                {
                    key: 'status',
                    label: 'Estado',
                    type: 'select',
                    required: true,
                    options: [
                        { value: 'active', label: 'Activa' },
                        { value: 'maintenance', label: 'En mantenimiento' },
                        { value: 'inactive', label: 'Fuera de servicio' },
                        { value: 'sin-suministro', label: 'Sin suministro' },
                        { value: 'testing', label: 'Testeando' },
                        {
                            value: 'testing-no-records',
                            label: 'Testeando (sin escribir al backend)',
                        },
                    ],
                    default: 'testing',
                },
                {
                    key: 'simId',
                    label: 'SIM id (Modem)',
                    type: 'textfield',
                }
            ],
        },
    ],
};

export default FormConfig;
