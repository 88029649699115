import React from 'react';
import Typography from '@material-ui/core/Typography';

import LocationWatchList from '../LocationWatchList';

const LowSaldoItems = ({ locs }) => (
    <LocationWatchList
        title="BAJO SALDO"
        locs={locs}
        getValue={loc => (
            <Typography color={loc.saldo <= 30000 ? 'error' : undefined}>
                {(loc.saldo / 1000).toFixed(1)}l
            </Typography>
        )}
    />
);

export default LowSaldoItems;
