


export default function putInBucket(val, buckets) {
  let lastBucket = 0;
  for (let b of buckets) {
    if (val >= lastBucket && val < b) {
      return b;
    }
    lastBucket = b;
  }
  return lastBucket;
}
