import {RouterState} from 'mobx-state-router';
import {find} from 'lodash';

import { ROLE } from 'klara-common';

const checkUserIsSignedIn = (fromState, toState, routerStore) => new Promise((resolve, reject) => {
  const { rootStore: { userStore }} = routerStore;
  userStore.setSignInRedirect(toState);
  userStore.waitForUser().then(isSignedIn => isSignedIn
      ? resolve()
      : reject(new RouterState('login'))
  )
});

const checkUserIsAdmin = (fromState, toState, routerStore) => {
  const { rootStore: { userStore }} = routerStore;
  return userStore.user && userStore.user.role === ROLE.ADMIN
       ? Promise.resolve()
       : Promise.reject(new RouterState('dashboard'))
}

const checkUserIsSignedOut = (fromState, toState, routerStore) => {
  const { rootStore: { userStore }} = routerStore;
  return userStore.isSignedIn
      ? Promise.reject(new RouterState('dashboard'))
      : Promise.resolve();
}

const onRouterViewEnter = (fromState, toState, routerStore) => {
    try {
        const name = toState.routeName;
        const routeConfig = find(routerStore.routes, r => r.name === name);
        const { rootStore } = routerStore;
        if (routeConfig.persistStore && fromState.routeName === toState.routeName) {
          rootStore.viewStore && rootStore.viewStore.onSubpathEnter && rootStore.viewStore.onSubpathEnter(toState);
          return Promise.resolve();
        }
        routeConfig && routeConfig.store && rootStore.createCurrentViewStore(routeConfig, toState);
        return Promise.resolve();
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
}

const onRouterViewExit = (fromState, toState, routerStore) => {
    try {
        const { rootStore } = routerStore;
        const name = fromState.routeName;
        const routeConfig = find(routerStore.routes, r => r.name === name);
        if (routeConfig.persistStore && (!toState || toState.routeName === name)) {
          rootStore.viewStore && rootStore.viewStore.onSubpathExit && rootStore.viewStore.onSubpathExit(fromState);
          return Promise.resolve();
        }
        routeConfig && routeConfig.store && rootStore.removeCurrentViewStore(routeConfig, fromState);
        return Promise.resolve();
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
}

const runAllHooks = (...allChecks) => {
  return async function (fromState, toState, routerStore) {
    for (let func of allChecks) {
      try {
        await func(fromState, toState, routerStore);
      } catch (e) {
        return Promise.reject(e);
      }
    }
    return Promise.resolve();
  }
}

const createRedirect = (currentPattern, toRouteName, toRouteParams = {}, other) => ({
    name : toRouteName+'Redirect',
    pattern: currentPattern,
    onEnter : () => Promise.reject(new RouterState(toRouteName, toRouteParams)),
    ...other
});

export {
  onRouterViewEnter,
  onRouterViewExit,
  checkUserIsSignedIn,
  checkUserIsSignedOut,
  checkUserIsAdmin,
  createRedirect,
  runAllHooks,
};
