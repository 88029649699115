import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import FuenteStateChip from '@/components/FuenteStateChip';
import ConnectionState from '@/components/ConnectionState';

const MobileList = ({locations, goTo }) => (
    <List>
        <Divider />
        {locations.map(loc => {
            const fuente = loc.fuente || null;
            return (
                <Fragment key={loc.id}>
                    <ListItem button onClick={() => goTo('location', { id: loc.id })}>
                        <ListItemText
                            classes={{ primary: 'location-list-name' }}
                            primary={loc.name}
                            secondaryTypographyProps={{ component: 'div' }}
                            secondary={
                                <div className="loc-sec-item">
                                    {!!fuente && (
                                        <div className="sec-item">
                                            {!!fuente && <FuenteStateChip fuente={fuente} />}
                                        </div>
                                    )}
                                    <div className="sec-item">{loc.fuenteId}</div>
                                    {loc.plan === 'prepago' && (
                                        <div className="sec-item">
                                            {((loc.saldo || 0) / 1000).toFixed(2)} l
                                        </div>
                                    )}
                                    {loc.plan !== 'prepago' && (
                                        <div className="sec-item">{loc.plan}</div>
                                    )}
                                </div>
                            }
                        />
                        <ListItemSecondaryAction>
                            <ConnectionState
                                state={fuente ? fuente.online : null}
                                since={fuente ? fuente.onlineModified: null}
                                showLabel={false}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </Fragment>
            );
        })}
    </List>
);

export default observer(MobileList);
