import React from 'react';
import { observer } from 'mobx-react';

import { FormDialog } from '@/components/Form';
import formConf from './formConfig';

const RecargaFormDialog = ({ store }) =>
    store ? (
        <FormDialog
            title={'Nueva Recarga'}
            open={store.openForm}
            loading={store.loading}
            onClose={() => store.setOpenForm(false)}
            onSubmit={store.onSubmit}
            initState={store.formInitState}
            config={formConf}
        />
    ) : null;

export default observer(RecargaFormDialog);
