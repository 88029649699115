import React from 'react';
import { observer } from 'mobx-react';
import { formatCentsInSoles, formatLitros, formatPercent } from 'klara-common';

import LocationMap from '@/components/LocationMap';
import StatRow from '@/components/StatRow';
import FormFieldDisplay from '@/components/FormFieldDisplay';
import './about-tab.scss';

const LocationAboutTab = ({ tabStore }) => {
    const loc = tabStore.main.location;
    return (
        <div className="location-about-tab">
            <h3>Totales historicos</h3>
            <StatRow
                values={[
                    { label: 'Ventas cash', value: formatCentsInSoles(tabStore.stats.cents) },
                    {
                        label: 'litros expendidos',
                        value: formatLitros(tabStore.stats.mlRealesServidos),
                    },
                    { label: 'Percent promocional', value: formatPercent(tabStore.percentMlProm) },
                ]}
            />
            <div className="location-content">
                <div className="picture-and-map">
                    <div
                        className="loc-img pic-and-map-item"
                        style={{ backgroundImage: `url("${tabStore.locationImgUrl}")` }}
                    />
                    <LocationMap className="pic-and-map-item" pos={tabStore.positionLatLng} />
                </div>

                <div className="location-fields">
                    <h3>Configuración:</h3>
                    <FormFieldDisplay label="Publicado" content={loc.published ? 'Si' : 'No'} />
                    <FormFieldDisplay label="Dirección" content={loc.place && loc.place.address} />
                    <FormFieldDisplay label="Distrito" content={loc.place && loc.place.distrito} />
                    <FormFieldDisplay label="Horario" content={loc.hours} />
                    <FormFieldDisplay label="Percent host" content={loc.percentForHost ? loc.percentForHost+'%' : false} />
                    <FormFieldDisplay label="Recojo monedas" content={loc.recojoMonedas} />
                    <FormFieldDisplay label="Refunds por" content={loc.refundBy} />
                    <FormFieldDisplay label="Volumen sonido" content={loc.volumen} />
                </div>
            </div>
        </div>
    );
};

export default observer(LocationAboutTab);
