import React from 'react';
import cn from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import './multi-checkbox-field.scss';

const MultiCheckboxField = ({
  value,
  onChange,
  options,
  label,
  className,
  error,
  helperText,
  labelPlacement = 'end'
}) => {
  const onCheckboxChange = (val, op) => {
    onChange(op ? [...(value || []), val] : (value || []).filter(v => v !== val));
  }
  return (
  <FormControl component="fieldset" error={!!error} className={cn('multi-checkbox-field', className)}>
    <FormLabel component="legend" className="multi-checkbox-field-label">{label}</FormLabel>
    <FormGroup row
      className={cn('multi-checkbox-field-group', `multi-checkbox-field-group-label-${labelPlacement}`)}>
      {options.map( (opt, i) =>
       <FormControlLabel
         key={`o-${i}`}
         control={
           <Checkbox
             color="primary"
             onChange={ev => onCheckboxChange(opt.value, ev.target.checked)}
             checked={(value || []).includes(opt.value)}
             />
         }
         label={opt.label}
         labelPlacement={labelPlacement}
         className={`multi-checkbox-item-label-${labelPlacement}`}
       />
     )}
   </FormGroup>
    {!!error && <FormHelperText className="radio-error-msg">{helperText}</FormHelperText>}
  </FormControl>
)
}

export default MultiCheckboxField;
