import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';

import './autotest.scss';
import Form from '@/components/Form';
import TestResults from './TestResults';
import AutoTestStore from './store';

@observer
class AutoTest extends Component {

    constructor(props, context) {
      super(props, context);
      this.store = new AutoTestStore(props.config);
    }

    render() {
      const { config, onSubmit } = this.props;
      return (
        <div className="autotest-form">
          <Form
            config={config}
            onSubmit={this.store.onEvaluate}
            onChange={this.store.onFormChange}
            showSubmit={this.store.showSubmit}
          />
          <TestResults store={this.store} />
          {this.store.showResult &&
            <div className="seguir-action">
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => this.store.onSubmit(onSubmit)}>
                  {'Seguir'}
              </Button>
            </div>
          }
        </div>
      );
    }
}

export default AutoTest;
