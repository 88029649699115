

export default function resizeImage(file, size) {

  return new Promise((resolve, reject) => {
    // Create file reader
    const reader = new FileReader();
    reader.onload = readerEvent => {
      // Create image object
      const image = new Image();
      image.onload = imageEvent => {
        // Create canvas or use provided canvas
        const canvas = document.createElement('canvas');
        let newWidth = image.width, newHeight = image.height;
        if (image.width >= image.height && image.width > size) {
          newWidth = size;
          newHeight = size * (image.height / image.width);
        } else if (image.height > image.width && image.height > size) {
          newHeight = size;
          newWidth = size * (image.width / image.height);
        }
        canvas.width = newWidth;
        canvas.height = newHeight;
        // Obtain the context for a 2d drawing
        const context = canvas.getContext('2d');
        if (!context) {
          return reject('Could not get the context of the canvas element');
        }
        // Draw the resized and cropped image
        context.drawImage(
          image,
          0,
          0,
          newWidth,
          newHeight
        );
        canvas.toBlob(blob => {
          resolve(blob);
        }, file.type);
      };
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
}
