import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';

import './form.scss';
import FormField from './FormField';
import FormStore from './store';

@observer
class Form extends Component {
    static propTypes = {
        config: PropTypes.object,
        initState: PropTypes.object,
        onSubmit: PropTypes.func,
        showSubmit: PropTypes.bool,
        asyncOptions: PropTypes.object,
        registerFormStore: PropTypes.func,
    };

    static defaultProps = {
        showSubmit: true,
        asyncOptions: {},
    };

    constructor(props, context) {
        super(props, context);
        this.formStore = new FormStore({
            config: props.config,
            initState: props.initState,
            onChange: props.onChange,
            setDialogUploading: props.setDialogUploading,
        });
        if (props.registerFormStore) {
            props.registerFormStore(this.formStore);
        }
    }

    componentWillUnmount() {
        this.formStore.dispose();
    }

    render() {
        const { showSubmit, onSubmit, asyncOptions } = this.props;
        const { config, state, form } = this.formStore;

        return (
            <div className="form">
                {state === 'edit' && (
                    <div className="form-edit-content">
                        {config.sections.map((sec, i) =>
                            !sec.showOnCondition || sec.showOnCondition(form) ? (
                                <div className="form-group" key={`sec-${i}`}>
                                    <h3 className="form-group-title">{sec.title}</h3>
                                    {!!sec.desc && (
                                        <div className="form-group-desc">{sec.desc}</div>
                                    )}
                                    <div className="form-group-content">
                                        {sec.fields.map((field, i) => (
                                            <div className="form-field-wrapper" key={`f-${i}`}>
                                                <FormField
                                                    store={this.formStore}
                                                    field={field}
                                                    asyncOptions={asyncOptions[field.key]}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                        )}
                        {showSubmit &&
                            (!config.showSubmitOnCondition ||
                                config.showSubmitOnCondition(form)) && (
                                <div className="send-form-container">
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        onClick={() => this.formStore.onSubmit(onSubmit)}
                                    >
                                        {config.submitLabel || 'Enviar'}
                                    </Button>
                                </div>
                            )}
                    </div>
                )}
                {state === 'saved' && (
                    <div className="form-success">
                        <CheckIcon className="check-icon" />
                        <div className="msg">Datos enviados!</div>
                    </div>
                )}
            </div>
        );
    }
}

export default Form;
