import { RouterState, RouterStore } from 'mobx-state-router';
import {observable, action} from 'mobx';
import routes from './routes';
import UserStore from './user';

const notFound = new RouterState('notFound');

export default class RootStore {

  // ROOT VARS
  @observable loading = false;
  @observable backTo = null;

  // ACTIONS
  @action setLoading = val => {
    this.loading = val;
  }

  /***************   CURRENT VIEW STORE   ***************/

  @action createCurrentViewStore = (config, toState) => {
    try {
        this.backTo = config.backTo;
        this.viewStore = new config.store(this, toState);
        this.viewStore.onEnter && this.viewStore.onEnter(toState);
    } catch (e) {
        console.log('ERROR 1', e);
    }
  };

  @action removeCurrentViewStore = (config, fromState) => {
     try {
         this.backTo = null;
         this.viewStore.onExit && this.viewStore.onExit(fromState);
    } catch (e) {
        console.log('ERROR 2', e);
    }
  }

  /****************   PERMANENT STORES    **************/
  routerStore = new RouterStore(this, routes, notFound);
  userStore = new UserStore(this);

  /****************   SHORTCUTS   *******************/
  getUserData = () => this.userStore.user;
  views = routes.reduce((acc, item) => ({...acc, [item.name] : item.component}), {});
  loginViews = routes.filter(r => r.isLoginView).map(r => r.name);
  noLoginViews = routes.filter(r => r.noLogin).map(r => r.name);
  getRouteParams = () => this.routerStore.routerState ? this.routerStore.routerState.params : {};
}
