import React from 'react';
import { observer, inject } from 'mobx-react';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';

import Form from '@/components/Form';
import { viewPropsMapper } from '@/views/utils';

import formConfig from './formConfig';

const NotifcationtestView = ({ viewStore, goTo }) => {
    if (!viewStore) return null;

    return (
        <Container className="home-view-container">
            <h1>Test de notificaciones</h1>
            {!!viewStore.error && <Alert severity="error">{viewStore.error}</Alert>}
            {!!viewStore.showSuccess && <Alert severity="success">Notificación enviada!</Alert>}
            <Form config={formConfig} onSubmit={viewStore.onSubmit} />
        </Container>
    );
};

export default inject(viewPropsMapper)(observer(NotifcationtestView));
