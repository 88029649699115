import React from 'react';
import { observer, inject } from 'mobx-react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ToolBar from '@material-ui/core/ToolBar';
import Alert from '@material-ui/lab/Alert';
import { QRCode } from 'react-qr-svg';

import { viewPropsMapper } from '@/views/utils';
import './generate-qrs.scss';

const GenerateQrsView = ({ viewStore: store }) => {
    return (
        <div className="qr-generator">
            {(store.error || store.msg) && (
                <Alert
                    className="qr-generator-alert"
                    severity={store.error ? 'error' : 'success'}
                    action={
                        store.msg ? (
                            <Button color="inherit" onClick={store.clear}>
                                Clear
                            </Button>
                        ) : null
                    }
                >
                    {store.error || store.msg}
                </Alert>
            )}
            <Container className="qr-generator-controls">
                <ToolBar className="qr-generator-toolbar">
                    <TextField
                        className="amount txt-field"
                        type="number"
                        value={store.amount}
                        onChange={store.setAmount}
                        label="Cantidad"
                    />
                    <TextField
                        className="litros-prom txt-field"
                        type="number"
                        value={store.litrosProm}
                        onChange={store.setLitrosProm}
                        label="Litros Prom"
                    />
                    <TextField
                        className="litros-pagos txt-field"
                        type="number"
                        value={store.litros}
                        onChange={store.setLitros}
                        label="Litros Pago (vender)"
                    />
                    <Button className="generate btn" onClick={store.generateIds}>
                        Generar
                    </Button>
                    <Button className="register btn" onClick={store.register}>
                        Registrar en backend
                    </Button>
                </ToolBar>
            </Container>
            <div className="qr-results">
                {store.ids.map((id, i) => (
                    <div className="qr-container" key={i}>
                        <QRCode value={`Q:${id}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default inject(viewPropsMapper)(observer(GenerateQrsView));
