import { first, last } from 'lodash';

export default class Accumulator {
    values = {};

    add(fieldName, val) {
        if (!this.values[fieldName]) {
            this.values[fieldName] = 0;
        }
        this.values[fieldName] += val;
    }

    get = () => {
        return this.values;
    };

    toArray = sort => {
        let keys = Object.keys(this.values);
        if (sort === 'string') {
            keys.sort();
        } else if (sort === 'number') {
            keys = keys.map(parseFloat).sort((a, b) => a - b);
        }
        let result = keys.map(k => ({ key: k, value: this.values[k] }));
        return result;
    };

    toArrayWithInterval = (interval = 1) => {
        let keys = Object.keys(this.values)
            .map(parseFloat)
            .sort((a, b) => a - b);
        let result = [];
        if (keys.length <= 1) return result;

        const minKey = first(keys);
        const maxKey = last(keys) !== Infinity ? last(keys) : keys[keys.length - 2];

        for (let i = minKey; i <= maxKey; i += interval) {
            result.push({ key: i, value: this.values[i] || 0 });
        }
        return result;
    };
}
