import React from 'react';
import { observer, inject } from 'mobx-react';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { viewPropsMapper } from '@/views/utils';

import './main-tabs.scss';

const MainTabs = ({ viewStore, userStore }) => {
    return (
        <div className="fuente-main-tabs-container">
          <AppBar position="static" color="default" className="fuente-main-tabs">
              <Tabs
                  variant="scrollable"
                  scrollButtons="auto"
                  value={viewStore.tab}
                  onChange={(e, tab) => viewStore.goToTab(tab)}
              >
                  <Tab label="Info" value="main" />
                  {!userStore.readOnly && <Tab label="Comandos" value="comandos" />}
              </Tabs>
          </AppBar>
        </div>
    );
};

export default inject(viewPropsMapper)(observer(MainTabs));
