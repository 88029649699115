import { observable, computed, action, toJS } from 'mobx';
import { orderBy, debounce } from 'lodash';
import { db, COLS, ROLE } from 'klara-common';
import { FuenteFormStore } from '@/components/FuenteFormDialog';

export default class HomeView {
    @observable fuentes = new Map();
    @observable fuentesLoaded = false;
    @observable formOptions = {
        locationId: [],
    };
    @observable filters = {
        online: true,
        offline: true,
        search: '',
    };

    @computed get fuentesSorted() {
        const search = this.filters.search.trim().toLowerCase();
        let fuentes = Object.values(toJS(this.fuentes))
            .filter(fuente => {
                if (this.filters.online && fuente.online) {
                    return true;
                }
                if (this.filters.offline && !fuente.online) {
                    return true;
                }
                return false;
            })
            .map(fuente => {
                fuente.onlineSort = fuente.online ? 1 : typeof fuente.online ===  'undefined' ? 3 : 2;
                return fuente;
            });
        if (search) {
            fuentes = fuentes.filter(
                fuente =>
                    fuente.fuenteId.toLowerCase().includes(search) ||
                    (fuente.locationName || '').toLowerCase().includes(search) ||
                    (fuente.simId || '') ===  search
            );
        }
        return orderBy(fuentes, ['onlineSort', 'fuenteId'], ['asc', 'asc']);
    }

    constructor(parent) {
        this.rootStore = parent;
        this.fuenteFormStore = new FuenteFormStore();
    }

    initDBListeners() {
        const user = this.rootStore.getUserData();
        if (user.role ===  ROLE.ADMIN) {
            this.fuentesUnsubscribe = db
                .collection(COLS.FUENTE)
                .onSnapshot(this.updateFuentesSnapshot, this.onSnapshotError);
        } else {
            this.fuentesUnsubscribe = db
                .collection(COLS.FUENTE)
                .where('acceso', 'array-contains', user.uid)
                .onSnapshot(this.updateFuentesSnapshot, this.onSnapshotError);
        }
    }

    @action updateFuentesSnapshot = snapshot => {
        this.fuentesLoaded = true;
        snapshot.docChanges().forEach(change => {
            if (change.type ===  'added' || change.type ===  'modified') {
                this.fuentes.set(change.doc.id, change.doc.data());
            } else if (change.type ===  'removed') {
                this.fuentes.delete(change.doc.id);
            }
        });
    };

    @action onSnapshotError = error => {
        console.log('Error', error);
    };

    @action openAddFuenteForm = () => {
        this.fuenteFormStore.openAddForm();
    };

    @action setFilter = (field, val) => {
        this.filters[field] = val;
    };

    debouncedSetFilter = debounce(this.setFilter, 300);

    stopDBListeners() {
        this.fuentesUnsubscribe();
    }

    onEnter() {
        this.initDBListeners();
    }

    onExit() {
        this.stopDBListeners();
    }
}
