import { createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';

import { ENV } from 'klara-common';

export const primary = ENV === 'PROD' ? indigo : teal;
export const secondary = ENV === 'PROD' ? pink : amber;

const theme = createMuiTheme({
    palette: {
        primary,
        secondary,
    },
    overrides: {
        MuiFormControlLabel: {
            label: {
                fontSize: 15,
            },
        },
    },
});

export default theme;
