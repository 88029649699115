import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SimpleMenu from '@/components/SimpleMenu';
import SimpleDialog from '@/components/SimpleDialog';

import RecargaForm from './RecargaForm';
import ForceSaldoForm from './ForceSaldoForm';

const getSaldoLitros = (recarga) => {
    return (((recarga.ml || 0) + (recarga.mlProm || 0)) / 1000).toFixed(2)
}

const RecargaTab = ({ tabStore }) => (
    <div className="recargas-tab" style={{ paddingTop: 20 }}>
        <h2>Recarga de saldo</h2>

        <div className="saldo-actual">
            Saldo actual:{' '}
            <strong>{((tabStore.main.location.saldo || 0) / 1000).toFixed(2)} l</strong>
        </div>
        <div className="main-tab-content">
            <div style={{ paddingTop: 20, paddingBottom: 20, display: 'flex' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => tabStore.setOpenForm(true)}
                >
                    Nueva recarga
                </Button>
                <div style={{flexGrow: 100}} />
                <SimpleMenu
                    entries={[
                        {
                            label: 'Forzar saldo',
                            onClick: () => tabStore.setOpenForceSaldoForm(true),
                        },
                    ]}
                />
            </div>

            <RecargaForm store={tabStore} />
            <ForceSaldoForm store={tabStore} />

            <SimpleDialog
                open={tabStore.openConfirm}
                title={'Por favor confirma'}
                onClose={tabStore.cancelSubmit}
                actions={[
                    {
                        label: 'Cancelar',
                        onClick: tabStore.cancelSubmit,
                    },
                    {
                        label: '¡Si, confirmo!',
                        onClick: tabStore.submitItem,
                        secondary: true,
                    },
                ]}
            />

            {!!tabStore.recargas.length && (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Fecha</TableCell>
                            <TableCell>Monto</TableCell>
                            <TableCell>Litros</TableCell>
                            <TableCell>Estado</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tabStore.recargas.map((item, idx) => (
                            <TableRow key={`item-${idx}`}>
                                <TableCell>
                                    {moment(item.timestamp.toDate()).format(
                                        'D MMM YYYY - HH:mm:ss'
                                    )}
                                </TableCell>
                                <TableCell>{'S/ ' + (item.cents / 100).toFixed(1)}</TableCell>
                                <TableCell>{`${getSaldoLitros(item)} l${item.mlProm ? ' (prom)': ''}`}</TableCell>
                                <TableCell>{item.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </div>
    </div>
);

export default observer(RecargaTab);
