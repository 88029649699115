import React from 'react';
import withFirebaseAuth from 'react-with-firebase-auth';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { FcGoogle } from 'react-icons/fc';
import { observer } from 'mobx-react';

import { firebaseAuth, firebaseAuthProviders } from 'klara-common';
import './login-form.scss';

const authConfig = {
    firebaseAppAuth: firebaseAuth,
    providers: firebaseAuthProviders,
};

const LoginForm = ({ signInWithGoogle, loading, error, messages }) => {
    return (
        <div className="login-container">
            {!loading && error && <Alert severity="warning">{error}</Alert>}
            {messages.map((msg, i) => (
                <Alert key={i} severity={msg.severity || 'warning'}>{msg.content}</Alert>
            ))}
            <div className="login-button-container">
                <Button
                    className="login-button"
                    size="large"
                    variant="outlined"
                    onClick={signInWithGoogle}
                    startIcon={<FcGoogle className="login-button-icon" />}
                    fullWidth
                >
                    Ingresar con Google
                </Button>
            </div>
        </div>
    );
};

export default withFirebaseAuth(authConfig)(observer(LoginForm));
