export const getDefaultState = form => {
    let state = {};
    ((form && form.sections) || []).forEach(section => {
        ((section && section.fields) || []).forEach(field => {
            if (field.default) {
                state[field.key] = field.default;
            }
        });
    });
    return state;
};
