import React from 'react';
import View from './View';
import store from './store';
import {
  runAllHooks,
  checkUserIsSignedIn,
  onRouterViewEnter,
  onRouterViewExit
} from '@/state/routerUtils';

const fuenteViewConfig = {
  name : 'location',
  pattern : '/location/:id/:tab?',
  onEnter : runAllHooks(
              checkUserIsSignedIn,
              onRouterViewEnter
            ),
  onExit : onRouterViewExit,
  component : <View />,
  persistStore: true,
  backTo: 'locations',
  store
};

export default fuenteViewConfig;
