import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, Dot, Rectangle, Curve } from 'recharts';
import { getBoxPlotData } from './utils';
import { observer } from 'mobx-react';
import { max, min } from 'lodash';

import './boxplot.scss';

@observer 
class BoxPlot extends Component {
    static propTypes = {
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        data: PropTypes.array,
        numBars: PropTypes.number,
    };

    static defaultProps = {
        height: 100,
    };

    xRange = num => {
        let result =
            ((num - min(this.props.data)) / max(this.props.data)) * parseInt(this.props.width);
        if (this.props.numBars) {
            return result + (this.props.width / this.props.numBars) * 0.5;
        } else {
            return 0;
        }
    };

    render() {
        const { data, height, width } = this.props;

        if (!data.length) return null;

        //console.log('data.length', this.props.width, JSON.stringify(data));

        if (!data || !data.length) return null;
        //console.log('width', width);
        const boxData = getBoxPlotData(data);
        //console.log('boxplot-data', boxData);

        const topPad = 5;
        const bottomPad = 5;
        const whiskerLowX = this.xRange(boxData.whiskerLow);
        const whiskerHighX = this.xRange(boxData.whiskerHigh);
        const whiskerH = 10;
        const q1X = this.xRange(boxData.quartile1);
        const q2X = this.xRange(boxData.quartile2);
        const q3X = this.xRange(boxData.quartile3);
        const boxH = parseInt(height) - (topPad + bottomPad);
        const boxW = q3X - q1X;
        const vertMiddle = height / 2;

        return (
            <div>
                <svg width={width} height={height + 40} className="bplot">
                    <Curve
                        className="bplot-line"
                        type="linear"
                        points={[
                            { x: whiskerLowX, y: vertMiddle },
                            { x: q1X, y: vertMiddle },
                        ]}
                    />
                    <Rectangle className="bplot-box" x={q1X} y={5} width={boxW} height={boxH} />
                    <Curve
                        className="bplot-line median"
                        type="linear"
                        points={[
                            { x: q2X, y: topPad },
                            { x: q2X, y: height - bottomPad },
                        ]}
                    />
                    <Curve
                        className="bplot-line"
                        type="linear"
                        points={[
                            { x: q3X, y: vertMiddle },
                            { x: whiskerHighX, y: vertMiddle },
                        ]}
                    />
                    <Curve
                        className="bplot-line"
                        type="linear"
                        points={[
                            { x: whiskerLowX, y: vertMiddle + whiskerH },
                            { x: whiskerLowX, y: vertMiddle - whiskerH },
                        ]}
                    />
                    <Curve
                        className="bplot-line"
                        type="linear"
                        points={[
                            { x: whiskerHighX, y: vertMiddle + whiskerH },
                            { x: whiskerHighX, y: vertMiddle - whiskerH },
                        ]}
                    />
                    {boxData.outliers.map((o, i) => (
                        <Dot
                            key={'dot-' + i}
                            className="bplot-outlier"
                            cx={this.xRange(o.key)}
                            cy={vertMiddle}
                            r={2 * (o.value < 5 ? o.value : 5)}
                        />
                    ))}
                </svg>
            </div>
        );
    }
}

const BoxPlotWrapped = props => (
    <ResponsiveContainer height={40} width="100%">
        <BoxPlot {...props} />
    </ResponsiveContainer>
);

export default BoxPlotWrapped;
