import React from 'react';
import View from './View';
import store from './store';
import {
  runAllHooks,
  checkUserIsSignedIn,
  onRouterViewEnter,
  onRouterViewExit
} from '@/state/routerUtils';

const viewConfig = {
  name : 'dashboard',
  pattern : '/',
  onEnter : runAllHooks(
              checkUserIsSignedIn,
              onRouterViewEnter
            ),
  onExit : onRouterViewExit,
  component : <View />,
  persistStore: true,
  store
};

export default viewConfig;
