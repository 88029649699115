import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { IoQrCodeOutline } from 'react-icons/io5';
import { RiCoinsLine } from 'react-icons/ri';
import { FaRegUserCircle } from 'react-icons/fa';
import { format } from 'date-fns';

const typeToIcon = {
    qr : <IoQrCodeOutline className="serv-icon" />,
    cash: <RiCoinsLine className="serv-icon" />,
    user: <FaRegUserCircle className="serv-icon" />,
};

const MobileServidasList = ({ servidas }) => {
    return (
        <List>
            {servidas.map((serv, i) => (
                <ListItem key={i}>
                    <ListItemIcon>{typeToIcon[serv.metodo]}</ListItemIcon>
                    <ListItemText
                        primary={format(serv.timestamp.toDate(), 'd MMM yyyy HH:mm:ss')}
                        secondary={
                            'S/ ' +
                            (serv.cents / 100).toFixed(2) +
                            `, ${serv.ml} ml, ${serv.mlProm} mlProm, ${serv.metodo}`
                        }
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default MobileServidasList;
