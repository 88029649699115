import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import TimeAgo from 'react-timeago';

import ColorSample from '@/components/ColorSample';
import './connection-state.scss';

const agoFormatter = (val, unit) => {
    let result;
    if (['second', 'minute', 'hour'].includes(unit)) {
        result = val + unit.substr(0, 1);
    } else if (unit === 'day') {
        result = val + ' dia' + (val > 1 ? 's' : '');
    } else if (unit === 'week') {
        result = val + ' semana' + (val > 1 ? 's' : '');
    } else if (unit === 'month') {
        result = val + ' mes' + (val > 1 ? 'es' : '');
    } else if (unit === 'year') {
        result = val + ' año' + (val > 1 ? 's' : '');
    }
    return `hace ${result}`;
};

const ConnectionState = ({ state, since, showLabel = true }) => {
    let msg = 'Sin info de conexión';
    let color = '#EEEEEE';
    if (state === true) {
        msg = 'Online';
        color = '#4caf50';
    } else if (state === false) {
        msg = 'Offline';
        color = '#ff3d00';
    }
    const date = since ? (since.toDate ? since.toDate() : since) : null;
    return (
        <Tooltip title={date ? <TimeAgo date={date} formatter={agoFormatter} /> : 'n/a'}>
            <div className="constate">
                <ColorSample color={color} />
                {showLabel && <div className="constate-msg">{msg}</div>}
            </div>
        </Tooltip>
    );
};

export default ConnectionState;
