

export const getDistrito = (geocodeResult) => {
    let distrito = null;
    if (geocodeResult.plus_code && geocodeResult.plus_code.compound_code) {
        distrito = geocodeResult.plus_code.compound_code.split(',')[0].replace(/^[a-zA-Z0-9+]* /, '');
    } else {
        for (let result of geocodeResult.address_components) {
            if (result.types.includes('locality')) {
                distrito = result.long_name;
                break;
            }
        };
    }
    console.log('distrito', distrito, geocodeResult);
    return distrito;
}

export const getCalle = (geocodeResult) => {
    let numero = '', calle = '';
    for (let result of geocodeResult.address_components) {
        if (result.types.includes('street_number')) {
            numero = result.long_name;
        } else if (result.types.includes('route')) {
            calle = result.long_name;
            if (calle === 'Unnamed Road') {
                calle = 'Calle sin nombre';
            }
        }
    };
    return [calle, numero].join(' ').trim();
}

export const getCiudad = (geocodeResult) => {
    for (let result of geocodeResult.address_components) {
        if (result.types.includes('administrative_area_level_1') || result.types.includes('administrative_area_level_2')) {
            const name = result.long_name;
            return name.replace('Provincia de ', '').replace('Municipalidad Metropolitana de ', '');
        }
    };
    return '';
}

export const formatAddress = (geoRes) => {
    return getCalle(geoRes) + ', ' + getDistrito(geoRes) + ', ' + getCiudad(geoRes);
}
