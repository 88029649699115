import { observable, action } from 'mobx';
import moment from 'moment';

import { db, COLS } from 'klara-common';

export default class DialogStore {
    @observable open = false;
    @observable loading = false;
    @observable showDeleteConfirm = false;

    editId = null;
    editInitState = null;

    constructor(hooks = {}) {
        const { onBeforeSubmitFunc, onSubmitFunc, onDeleteFunc } = hooks;
        this.onBeforeSubmitFunc = onBeforeSubmitFunc;
        this.onSubmitFunc = onSubmitFunc;
        this.onDeleteFunc = onDeleteFunc;
    }

    @action onSubmit = formData => {
        this.onBeforeSubmitFunc && this.onBeforeSubmitFunc(formData);
        if (!this.editId) {
            formData.created = moment().toISOString();
        }
        this.loading = true;
        db.collection(COLS.FUENTE)
            .doc(formData.fuenteId)
            .set(formData)
            .then(resp => {
                this.onSubmitFunc && this.onSubmitFunc(formData);
                this.setDialogOpen(false);
            });
    };

    @action onDelete = () => {
        this.showDeleteConfirm = true;
    };

    @action onDeleteCancel = () => {
        this.showDeleteConfirm = false;
    };

    @action onDeleteConfirm = () => {
        this.loading = true;
        db.collection(COLS.FUENTE)
            .doc(this.editId)
            .delete()
            .then(() => {
                setTimeout(() => {
                    this.onDeleteFunc && this.onDeleteFunc();
                }, 10);
            })
            .finally(() => {
                this.open = false;
                this.loading = false;
            });
    };

    @action openEditForm = data => {
        this.editId = data.fuenteId;
        this.editInitState = data;
        this.setDialogOpen(true);
    };

    @action openAddForm = () => {
        this.editInitState = null;
        this.editId = null;
        this.setDialogOpen(true);
    };

    @action setDialogOpen = val => {
        this.loading = false;
        this.open = val;
    };
}
