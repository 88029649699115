import React from 'react';
import { observer } from 'mobx-react';

import ConnectionState from '@/components/ConnectionState';
import FuenteStateChip from '@/components/FuenteStateChip';

import './status-indicators.scss';

const StatusIndicators = ({ fuente }) => (
    <div className="fuente-status-indicators">
        <div className="indicator"><FuenteStateChip fuente={fuente} /></div>
        <div className="indicator">
            <ConnectionState state={fuente.online} since={fuente.onlineModified} />
        </div>
        <div className="indicator">{fuente.version}</div>
    </div>
);

export default observer(StatusIndicators);
