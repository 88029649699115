import { observable, action, runInAction } from 'mobx';

import { db, COLS, DEFAULT, ROLE } from 'klara-common';

export default class LocationFormDialogStore {
    @observable open = false;
    @observable loading = false;
    @observable formOptions = {
        fuenteId: [],
        hostId: [],
    };
    @observable mainError = null;

    editId = null;
    editInitState = null;

    constructor(hooks = {}) {
        const { onBeforeSubmitFunc, onSubmitFunc } = hooks;
        this.onBeforeSubmitFunc = onBeforeSubmitFunc;
        this.onSubmitFunc = onSubmitFunc;
    }

    loadResources() {
        let qvalues = DEFAULT.LOCATION_ID,
            qop = '==';
        if (this.editId) {
            qvalues = [DEFAULT.LOCATION_ID, this.editId];
            qop = 'in';
        }
        db.collection(COLS.FUENTE)
            .where('locationId', qop, qvalues)
            .get()
            .then(result =>
                runInAction(() => {
                    this.formOptions.fuenteId = [
                        {
                            value: DEFAULT.FUENTE_ID,
                            label: 'Sin Fuente',
                        },
                        ...result.docs.map(doc => ({
                            value: doc.id,
                            label: doc.id,
                        })),
                    ];
                })
            );
        db.collection(COLS.PERFIL)
            .where('role', '==', ROLE.HOST)
            .get()
            .then(result =>
                runInAction(() => {
                    this.formOptions.hostId = result.docs.map(doc => ({
                        value: doc.id,
                        label: doc.data().name + ' (' + doc.data().email + ')',
                    }));
                })
            );
    }

    @action onSubmit = async formData => {
        this.onBeforeSubmitFunc && this.onBeforeSubmitFunc(formData);
        let { id, ...data } = formData;
        if (!this.editId) {
            data.created = new Date();
        }
        data.active = data.fuenteId !== DEFAULT.FUENTE_ID;
        this.loading = true;
        id = this.editId || id;
        const col = db.collection(COLS.LOCATION);
        (id ? col.doc(id).set(data) : col.add(data)).then(resp => {
            /*(id ? col.doc(id).set : col.add)(data).then(resp => {*/
            this.onSubmitFunc && this.onSubmitFunc(formData);
            this.setDialogOpen(false);
            this.loading = false;
        });
    };

    @action openEditForm = (id, data) => {
        this.editId = id;
        this.editInitState = { id, ...data };
        this.loadResources();
        this.setDialogOpen(true);
    };

    @action openAddForm = () => {
        this.editInitState = null;
        this.editId = null;
        this.loadResources();
        this.setDialogOpen(true);
    };

    @action showMainError = err => {
        this.mainError = err;
    };

    @action hideMainError = () => {
        this.mainError = null;
    };

    @action setDialogOpen = val => {
        this.loading = false;
        this.open = val;
    };
}
