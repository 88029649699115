import { observable, action, toJS, computed } from 'mobx';

import { db, COLS } from 'klara-common';

export default class ServidasTabStore {
    @observable stats = {};

    @computed get percentMlProm() {
        return this.stats.mlProm / ((this.stats.mlProm || 0) + (this.stats.ml || 0) || 1);
    }

    @computed get locationImgUrl() {
        if (this.main.location.img && this.main.location.img.length) {
            return this.main.location.img[0].url;
        } else {
            return 'https://via.placeholder.com/320x240?text=SIN+IMAGEN';
        }
    }

    @computed get positionLatLng() {
        if (this.main.location.place && this.main.location.place.geo) {
            return this.main.location.place.geo;
        } else {
            return {}
        }
    }

    constructor(main) {
        this.main = main;
        console.log('main', main);
        this.getStats();
    }

    async getStats() {
        const doc = await db
            .collection(COLS.LOCATION_STATS)
            .doc(this.main.locationId)
            .get();
        this.stats = doc.exists ? doc.data() : {};
    }
}
