import { observable, action } from 'mobx';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { http } from 'klara-common';

export default class Store {

  data = {};
  @observable activeStep = 0;
  @observable status = 'edit';

  constructor(parent) {
    this.rootStore = parent;
  }

  @action onSeguir1 = (form, errors) => {
    if (!isEmpty(form) && isEmpty(errors)) {
      this.activeStep = 1;
      Object.assign(this.data, form);
      setTimeout(() => window.scrollTo(0, 0), 10);
    }
  }

  @action onSeguir2 = (evalData) => {
    this.activeStep = 2;
    Object.assign(this.data, { evaluation: evalData });
    setTimeout(() => window.scrollTo(0, 0), 10);
  }

  @action onClickStep = step => {
    if (this.activeStep === 1 && step === 0) {
      this.activeStep = 0;
    }
  }

  @action sendForm = (form = {}) => {
      const formData = {
        ...this.data,
        ...form,
        timestamp: moment().toISOString()
      }
      this.chachedFormData = formData;
      this.rootStore.setLoading(true);
      http.post('https://klara-cloud.firebaseapp.com/api/saveHostForm', formData)
      .then( res => {
        if (res.body.status === 200) {
          this.status = 'success';
        } else {
          this.status = 'error';
        }
      })
      .catch(err => {
        this.status = 'error';
      })
      .finally( () => {
        this.rootStore.setLoading(false);
      });
  }

  @action retrySend = () => {
    this.sendForm(this.chachedFormData);
  }
}
