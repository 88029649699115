import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import { orderBy } from 'lodash';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Hidden from '@material-ui/core/Hidden';

import SnackContent from '@/components/SnackContent';
import { viewPropsMapper } from '@/views/utils';
import { LocationFormDialog } from '@/components/LocationFormDialog';
import SimpleDialog from '@/components/SimpleDialog';
import MobileList from './MobileList';
import DesktopList from './DesktopList';
import FilterControl from './FilterControl';
import './locations.scss';

const getOrderedLocations = (locations, fuentes, filters) => {
    let result = toJS([...locations.values()]);
    const search = (filters.search || '').trim().toLowerCase();

    result = result
        .map(loc => {
            const fnte = fuentes.get(loc.fuenteId);
            loc.active = !!fnte;
            loc.online = !!fnte && fnte.online;
            loc.fuente = fnte;
            return loc;
        })
        .filter(loc => {
            if (filters.online && loc.online) {
                return true;
            }
            if (filters.inactive && !loc.active) {
                return true;
            }
            if (loc.active && filters.offline && !loc.online) {
                return true;
            }
            return false;
        });
    if (search) {
        result = result.filter(
            loc =>
                loc.name.toLowerCase().includes(search) ||
                (loc.alias || '').toLowerCase().includes(search)
        );
    }
    return orderBy(result, ['online', 'active', 'name'], ['desc', 'desc', 'asc']);
};

const LocationsView = ({ viewStore: store, userStore, fullscreen, goTo }) => {
    if (!store) return null;
    const { fuentes, locations, filters } = store;

    const orderedLocations = getOrderedLocations(locations, fuentes, filters);

    return (
        <Fragment>
            <Container className="locations-view-container klara-main-container">
                {!!store.messages && !!store.messages.length && (
                    <div className="admin-user-messages">
                        {store.messages.map((m, i) => (
                            <SnackContent
                                key={`msg-${i}`}
                                variant={m.variant}
                                onClose={() => store.deleteMessage(i)}
                            >
                                {m.content}
                            </SnackContent>
                        ))}
                    </div>
                )}

                <div className="title-holder">
                    <h1>Locaciones</h1>
                    {!userStore.readOnly && (
                        <div className="add-new-holder">
                            <IconButton onClick={() => store.openAddLocation()}>
                                <AddIcon />
                            </IconButton>
                        </div>
                    )}
                </div>
                <div className="filters">
                    <FilterControl viewStore={store} />
                </div>
                <div className="locations-list-conatainer">
                    {!!orderedLocations && !!orderedLocations.length ? (
                        <Fragment>
                            <Hidden mdUp>
                                <MobileList locations={orderedLocations} {...{ goTo }} />
                            </Hidden>
                            <Hidden smDown>
                                <DesktopList locations={orderedLocations} {...{ goTo }} />
                            </Hidden>
                        </Fragment>
                    ) : (
                        <p>No results :(</p>
                    )}
                </div>
            </Container>
            <LocationFormDialog store={store.locationFormDialogStore} />
            <SimpleDialog
                open={store.openDeleteConfirm}
                actions={[
                    { label: 'Cancelar', onClick: store.cancelDelete, secondary: true },
                    { label: 'Borrar', onClick: store.deleteLocationConfirm },
                ]}
            >
                Seguro que quieres borrar?
            </SimpleDialog>
        </Fragment>
    );
};

export default inject(viewPropsMapper)(observer(LocationsView));
