import { observable, action, computed } from 'mobx';
import moment from 'moment';
import { stdev, mean } from 'stats-lite';

import { Accumulator, putInBucket } from '@/utils';
import { db, COLS, cents2ml } from 'klara-common';

export default class FuenteHomeTabStore {
    constructor(main) {
        this.main = main;
        this.startServidasListener();
    }
    @observable servidas = [];
    @observable timeBucketSize = 10;
    @observable timeMaxServ = null;
    @observable timeMinServ = 0;

    @action setTimeMinServ = ev => {
        this.timeMinServ = parseInt(ev.target.value) || null;
    };

    @action setTimeMaxServ = ev => {
        this.timeMaxServ = parseInt(ev.target.value) || null;
    };

    @action setTimeBucketSize = ev => {
        this.timeBucketSize = parseInt(ev.target.value) || null;
    };

    @computed get timeStats() {
        if (this.main.servidas.length) {
            let servPeriods = new Accumulator();
            let mlPeriods = new Accumulator();
            let servTimeDeltas = new Accumulator();
            let mlArray = [];
            let servTimeDeltaArray = [];
            let lastDate = null;

            this.main.servidas.forEach(s => {
                const ml = cents2ml(s.centavos); // sacamos los ml de acuerdo a los centavos para no meter ruido
                if (
                    (!this.timeMinServ || ml >= this.timeMinServ) &&
                    (!this.timeMaxServ || ml <= this.timeMaxServ)
                ) {
                    let mom = moment
                        .unix(s.timestamp.seconds)
                        .seconds(0)
                        .milliseconds(0);
                    const mins =
                        parseInt(Math.floor(mom.minutes() / this.timeBucketSize)) *
                        this.timeBucketSize;
                    mom = mom.minutes(mins);
                    const periodKey = mom.toISOString();
                    servPeriods.add(periodKey, 1);
                    mlPeriods.add(periodKey, ml);
                    let thisDate = s.timestamp.seconds;
                    if (lastDate) {
                        const delta = (thisDate - lastDate) / 60;
                        if (moment.unix(thisDate).day() === moment.unix(lastDate).day()) {
                            servTimeDeltaArray.push(delta);
                            //const buckets = [1,2,3,4,5,7,10,15,20,30,40,60,90,120, Infinity];
                            let buckets = [];
                            for (var i = 0; i < 120; i++) {
                                buckets.push(i * 0.5);
                            }
                            buckets.push(Infinity);
                            //const buckets = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, Infinity];
                            const bucket = putInBucket(delta, buckets);
                            servTimeDeltas.add(bucket, 1);
                        }
                    }
                    lastDate = thisDate;
                }
            });

            let servBuckets = new Accumulator();
            let mlBuckets = new Accumulator();

            servPeriods.toArray().forEach(item => {
                servBuckets.add(item.value, 1);
            });
            mlPeriods.toArray().forEach(item => {
                mlBuckets.add(item.value, 1);
            });

            // stdev
            mlBuckets.toArray().forEach(item => {
                for (var i = 0; i < item.value; i++) {
                    mlArray.push(parseInt(item.key));
                }
            });

            const dataStdev = stdev(mlArray);
            const dataMean = mean(mlArray);
            const timeDeltaSd = stdev(servTimeDeltaArray);
            const timeDeltaMean = mean(servTimeDeltaArray);

            return {
                servsPerPeriod: servBuckets.toArrayWithInterval(1),
                mlPerPeriod: mlBuckets.toArrayWithInterval(200),
                ml: {
                    sd: dataStdev,
                    mean: dataMean,
                    sd1x: dataMean + dataStdev,
                    sd2x: dataMean + 2 * dataStdev,
                    dataArray: mlArray,
                },
                timeDelta: {
                    distrib: servTimeDeltas.toArrayWithInterval(0.5),
                    sd: timeDeltaSd,
                    mean: timeDeltaMean,
                    sd1x: timeDeltaMean + timeDeltaSd,
                    sd2x: timeDeltaMean + 2 * timeDeltaSd,
                    dataArray: servTimeDeltaArray,
                },
            };
        } else {
            return {
                servsPerPeriod: [],
                mlPerPeriod: [],
                servTimeDeltas: [],
                ml: {
                    sd: 0,
                    mean: 0,
                    sd1x: 0,
                    sd2x: 0,
                    dataArray: [],
                },
                timeDelta: {
                    distrib: [],
                    sd: 0,
                    mean: 0,
                    sd1x: 0,
                    sd2x: 0,
                    dataArray: [],
                },
            };
        }
    }

    startServidasListener = () => {
        let servidasRef = db
            .collection(COLS.SERVIDA)
            .where('locationId', '==', this.main.locationId);
        if (this.main.startDate != null) {
            servidasRef = servidasRef.where('timestamp', '>=', this.main.startDate.toDate());
        }
        if (this.main.endDate != null) {
            servidasRef = servidasRef.where(
                'timestamp',
                '<=',
                this.main.endDate.endOf('day').toDate()
            );
        }
        servidasRef = servidasRef.orderBy('timestamp', 'asc');
        if (!this.main.endDate || this.main.endDate.isAfter()) {
            this.servidasQueryUnsub = servidasRef.onSnapshot(this.updateServidasSnapshot);
        } else {
            servidasRef.get().then(this.updateServidasSnapshot);
        }
    };

    @action updateServidasSnapshot = snapshot => {
        this.servidas = snapshot.docs.map(d => d.data());
    };

    restartListeners() {
        this.stopServidasListener();
        this.startServidasListener();
    }

    stopServidasListener() {
        this.servidas.clear();
        !!this.servidasQueryUnsub && this.servidasQueryUnsub();
    }

    onExit() {
        this.stopServidasListener();
    }
}
