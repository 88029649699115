import { observable, action, toJS } from 'mobx';
import moment from 'moment';
import isSemver from 'is-semver';
import { db, COLS } from 'klara-common';

export default class FuenteUpdatesView {
    @observable fuenteUpdates = new Map();
    @observable loading = false;
    @observable openDialog = false;
    @observable showDialogConfirm = false;
    @observable numFuentes = 0;
    @observable updateDoc = {};
    @observable updateDocError = {};
    @observable showValidation = false;

    constructor(parent) {
        this.rootStore = parent;
    }

    initDBListeners() {
        this.fuentesUpdatesUnsubscribe = db
            .collection(COLS.FUENTE_UPDATE)
            .orderBy('created', 'desc')
            .limit(10)
            .onSnapshot(this.updateFuenteUpdatesSnapshot, this.onSnapshotError);
    }

    @action updateFuenteUpdatesSnapshot = snapshot => {
        this.loading = false;
        snapshot.docChanges().forEach(change => {
            if (change.type === 'added' || change.type === 'modified') {
                this.fuenteUpdates.set(change.doc.id, change.doc.data());
            } else if (change.type === 'removed') {
                this.fuenteUpdates.delete(change.doc.id);
            }
        });
    };

    @action onSnapshotError = error => {
        console.log('Error', error);
    };

    @action createUpdate = () => {
        this.validateUpdate();
        if (Object.keys(toJS(this.updateDocError)).length) {
            this.showValidation = true;
        } else {
            this.showDialogConfirm = true;
        }
    };

    @action confirmGoBack = () => {
        this.showDialogConfirm = false;
    };

    @action confirmCreateUpdate = () => {
        this.dialogLoading = true;
        let data = toJS(this.updateDoc);
        data.created = moment().toISOString();
        data.numUpdated = 0;
        data.all = !data.devices || !data.devices.length;
        const docId = `${data.tag}_${data.created}`;
        if (data.devices && !data.devices.length) {
            delete data.devices;
        }
        db.collection(COLS.FUENTE_UPDATE)
            .doc(docId)
            .set(data)
            .then(() => this.setDialogOpen(false));
    };

    @action setDialogOpen = val => {
        this.dialogLoading = false;
        this.showValidation = false;
        this.showDialogConfirm = false;
        this.updateDoc = {};
        this.openDialog = val;
    };

    @action setUpdateDocField = (field, val) => {
        const current = toJS(this.updateDoc);
        if (field === 'devices') {
            let newVal = [];
            val.forEach(v => {
                const parts = v.split(/[ ,]+/g).filter(p => !!p);
                newVal = [...newVal, ...parts];
            });
            val = newVal;
        }
        console.log('val', val);
        this.updateDoc = { ...current, [field]: val };
        this.validateUpdate();
    };

    @action validateUpdate = () => {
        const tagOk =
            this.updateDoc.tag && isSemver(this.updateDoc.tag) && this.updateDoc.tag[0] === 'v';
        if (!tagOk) {
            this.updateDocError.tag =
                'verifica tu campo de git tag (formato semver con "v" al inicio)';
        } else if (this.updateDocError.tag) {
            delete this.updateDocError.tag;
        }
    };

    stopDBListeners() {
        this.fuentesUpdatesUnsubscribe();
    }

    onEnter() {
        this.initDBListeners();
    }

    onExit() {
        this.stopDBListeners();
    }
}
