const FormConfig = {
    sections: [
        {
            fields: [
                {
                    key: 'start',
                    label: 'Desde',
                    type: 'datetime',
                    required: true,
                    props: {
                        disableFuture: true
                    }
                },
                {
                    key: 'end',
                    label: 'Hasta',
                    type: 'datetime',
                    required: true,
                    props: {
                        disableFuture: true
                    }
                },
                {
                    key: 'comment',
                    label: 'comentario',
                    type: 'textfield',
                    props: {
                        multiline: true
                    }
                },
                {
                    key: 'amountFound',
                    label: 'Monto encontrado',
                    type: 'textfield',
                    validateType : 'number',
                    props: {
                        type : 'number',
                        helperText: 'opcional: llenar esto al contar las monedas.'
                    }
                },
            ],
        },
    ],
};

export default FormConfig;
