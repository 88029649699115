import { observable, action, computed, toJS } from 'mobx';
import moment from 'moment';
import { cloneDeep, sortBy, pick } from 'lodash';
import isEmail from 'validator/es/lib/isEmail';

import { buildInviteUrl } from '@/utils';
import { db, http, COLS, ROLE, FirestoreFieldValue } from 'klara-common';

const defaultInviteForm = {
    role: ROLE.HOST,
};

export default class AdminHomeView {
    @observable profiles = new Map();
    @observable editUserData = {};
    @observable editUserId = null;
    @observable invites = new Map();
    @observable newInvite = cloneDeep(defaultInviteForm);
    @observable userFormError = {};
    @observable inviteModalOpen = false;
    @observable locationOptions = [];
    @observable tab = 0;
    @observable inviteLink = '';
    @observable showInviteLinkDialog = false;
    @observable messages = [];

    @computed get profilesSorted() {
        return sortBy([...this.profiles.entries()], prof => prof[1].name.toLowerCase());
    }

    constructor(parent) {
        this.rootStore = parent;
        this.initDBListeners();
    }

    @action deleteMessage = index => {
        this.messages.splice(index, 1);
    };

    @action openInviteModal = (isNew = true) => {
        this.getLocations();
        if (isNew) {
            this.newInvite = cloneDeep(defaultInviteForm);
        }
        this.inviteModalOpen = true;
    };

    @action closeInviteModal = () => {
        this.inviteModalOpen = false;
    };

    @action setInviteField = (fieldName, value) => {
        this.newInvite[fieldName] = value;
    };

    @action generateInvite = inv => {
        let data = toJS(this.newInvite);

        if (this.validateUserForm(data)) {
            if (data.locations && data.locations.length) {
                data.locations = data.locations.map(l => l.value);
            }
            this.rootStore.setLoading(true);

            http.post('/api/generateInvite', data)
                .then(res => {
                    this.closeInviteModal();
                    if (data.role === ROLE.ADMIN) {
                        const inviteLink = buildInviteUrl(res.body.id);
                        this.messages.push({
                            variant: 'success',
                            content: `Invitación creada para ${res.body.data.name}.
                                  Ahora mándale este link de invitación para que pueda sacar su cuenta:
                                  ${inviteLink}
                                `,
                        });
                    } else {
                        this.messages.push({
                            variant: 'success',
                            content: `Invitación creada con éxito. Ahora el usuario podra utilizar 
                                      su cuenta de google o facebook asociada a este correo para ingresar
                                      a https://admin.klara.pe`,
                        });
                    }
                })
                .catch(err => {
                    console.log('error', err);
                })
                .finally(() => this.rootStore.setLoading(false));
        }
    };

    @action changeTab = (ev, val) => {
        this.tab = val;
    };

    @action getLocations = async () => {
        const locQuery = await db.collection(COLS.LOCATION).get();
        this.locationOptions = (locQuery.docs || []).map(doc => ({
            label: doc.data().name,
            value: doc.id,
        }));
        return this.locationOptions;
    };

    @action validateUserForm = data => {
        this.userFormError = {};
        if (!data.name || !data.name.trim()) {
            this.userFormError.name = 'campo obligatorio';
        }
        if (data.email && !isEmail(data.email)) {
            this.userFormError.email = 'dirección de mail inválida.';
        }

        if (data.role !== ROLE.ADMIN && (!data.locations || !data.locations.length)) {
            this.userFormError.fuentes = 'campo obligatorio para hosts/iguistas etc';
        }
        return Object.keys(this.userFormError).length === 0;
    };

    initDBListeners() {
        // Invites
        this.invitesUnsubscribe = db
            .collection(COLS.USER_INVITE)
            .onSnapshot(this.updateInvitesSnapshot, this.onInvitesSnapshotError);
        // User Profiles
        this.profilesUnsubscribe = db
            .collection(COLS.PERFIL)
            .onSnapshot(this.updateProfilesSnapShot, this.onProfileSnapshotError);
    }

    stopDBListeners() {
        this.invites.clear();
        this.invitesUnsubscribe && this.invitesUnsubscribe();
        this.profilesUnsubscribe && this.profilesUnsubscribe();
    }

    @action updateInvitesSnapshot = snapshot =>
        snapshot.docChanges().forEach(change => {
            if (change.type === 'added' || change.type === 'modified') {
                this.invites.set(change.doc.id, change.doc.data());
            } else if (change.type === 'removed') {
                this.invites.delete(change.doc.id);
            }
        });
    onInvitesSnapshotError = err => console.log(err);

    @action updateProfilesSnapShot = snapshot =>
        snapshot.docChanges().forEach(change => {
            if (change.type === 'added' || change.type === 'modified') {
                this.profiles.set(change.doc.id, change.doc.data());
            } else if (change.type === 'removed') {
                this.profiles.delete(change.doc.id);
            }
        });
    onProfilesSnapshotError = err => console.log(err);

    @action renovarInvitacion = inviteId => {
        this.rootStore.setLoading(true);
        db.collection(COLS.USER_INVITE)
            .doc(inviteId)
            .update({
                expires: moment()
                    .add(2, 'days')
                    .endOf('day')
                    .toDate(),
            })
            .finally(() => this.rootStore.setLoading(false));
    };

    @action borrarInvitacion = inviteId => {
        this.rootStore.setLoading(true);
        db.collection(COLS.USER_INVITE)
            .doc(inviteId)
            .delete()
            .finally(() => this.rootStore.setLoading(false));
    };

    @action showInviteLink = inviteId => {
        this.inviteLink = buildInviteUrl(inviteId);
        this.showInviteLinkDialog = true;
    };

    @action closeInviteLinkDialog = () => {
        this.showInviteLinkDialog = false;
    };

    @action editUserProfile = async id => {
        let editData = toJS(this.profiles.get(id));
        const allLocOpts = await this.getLocations();
        if (editData.locations && editData.locations.length) {
            editData.locations = toJS(allLocOpts).filter(loc =>
                editData.locations.includes(loc.value)
            );
        }
        this.editUserData = editData;
        this.editUserId = id;
    };

    @action closeEditUserDialog = () => {
        this.editUserData = {};
        this.editUserId = null;
    };

    @action setEditUserField = (fieldName, value) => {
        this.editUserData[fieldName] = value;
    };

    @action saveUserProfile = () => {
        const data = pick(toJS(this.editUserData), [
            'name',
            'comment',
            'locations',
            'role',
            'readOnly',
        ]);
        const id = toJS(this.editUserId);

        if (this.validateUserForm(data)) {
            if (data.locations && data.locations.length) {
                data.locations = data.locations.map(f => f.value);
            }
            if (data.role !== ROLE.ADMIN && typeof data.readOnly !== 'undefined') {
                delete data.readOnly;
            }
            if (
                [ROLE.ADMIN, ROLE.USER].includes(data.role) &&
                data.locations &&
                data.locations.length
            ) {
                data.locations = FirestoreFieldValue.delete();
            }
            this.rootStore.setLoading(true);

            db.collection(COLS.PERFIL)
                .doc(id)
                .update(data)
                .then(res => this.closeEditUserDialog())
                .catch(err => console.log('error', err))
                .finally(() => this.rootStore.setLoading(false));
        }
    };

    @action deleteUser = uid => {
        if (window.confirm('Estas seguro? Esta acción es irreversible!')) {
            this.rootStore.setLoading(true);
            http.post('/api/deleteUser', { uid })
                .then(res => console.log('delete ok', res))
                .catch(e => console.log('delete user error', e))
                .finally(() => this.rootStore.setLoading(false));
        }
    };

    onEnter = () => {
        this.initDBListeners();
    };

    onExit = () => {
        this.stopDBListeners();
    };
}
