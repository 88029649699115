import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import Contacto from '../Contacto';
import HostTest from '../HostTest';
import Final from '../Final';
import Success from '../Success';
import Error from '../Error';

import { viewPropsMapper } from '@/views/utils';
import './main.scss';

const Main = ({ viewStore: store }) => {
    return (
        <div className="host-form-main">
            {store.status === 'edit' && (
                <Fragment>
                    <h1>Solicitud de Fuentes Klara</h1>

                    <div className="stepper-container">
                        <Stepper activeStep={store.activeStep} className="host-form-stepper">
                            <Step>
                                <StepLabel>Datos de contacto</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Evaluación</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Enviar</StepLabel>
                            </Step>
                        </Stepper>
                    </div>

                    {store.activeStep === 0 && (
                        <Fragment>
                            <p>
                                {`¡Hola! Gracias por tu interés en alojar una fuente Klara.
                  Aquí nos puedes dejar tus datos y llenar una encuesta rápida
                  que ayuda a evaluar el potencial de ventas de tu espacio.`}
                            </p>
                            <p>
                                {`Te informamos que en este momento inicial del proyecto no
                estamos atendiendo pedidos de provincias, sin embargo esperamos
                hacerlo en un futuro cercano. Si este es tu caso, te pedimos que
                continúes llenando el formulario para contactarnos contigo apenas
                iniciemos con el despliegue fuera de Lima.`}
                            </p>
                            <Contacto onSubmit={store.onSeguir1} />
                        </Fragment>
                    )}
                    {store.activeStep === 1 && <HostTest onSubmit={store.onSeguir2} />}
                    {store.activeStep === 2 && <Final onSubmit={store.sendForm} />}
                </Fragment>
            )}
            {store.status === 'success' && <Success />}
            {store.status === 'error' && <Error />}
        </div>
    );
};

export default inject(viewPropsMapper)(observer(Main));
