import React, { Component } from 'react';
import { Provider } from 'mobx-react';
import { HistoryAdapter } from 'mobx-state-router';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import "moment/locale/es";

import RootStore from '@/state/root';
import history from '@/state/history';
import theme from '@/theme';
import Shell from './Shell';
import './App.scss'

moment.locale('es');

// Create the rootStore
const rootStore = new RootStore();

// Observe history changes
const historyAdapter = new HistoryAdapter(rootStore.routerStore, history);
historyAdapter.observeRouterStateChanges();

export default class App extends Component {
  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale="es" moment={moment}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Provider rootStore={rootStore}>
            <Shell />
          </Provider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}
