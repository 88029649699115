import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { max } from 'lodash';
import {
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    ZAxis,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';

import { primary } from '@/theme';

@observer
class HeatMap extends Component {
    renderTooltip = props => {
        const { active, payload } = props;

        if (active && payload && payload.length) {
            const data = payload[0] && payload[0].payload;
            const periodName = data.period.includes('-')
                ? data.period
                : data.period.replace('h', '') +
                  ' - ' +
                  (parseInt(data.period.replace('h', '')) + 1) +
                  'h';
            return (
                <div
                    style={{
                        backgroundColor: '#fff',
                        border: '1px solid #999',
                        margin: 0,
                        padding: 3,
                    }}
                >
                    <p>{periodName}</p>
                    <p>
                        <span>servidas: </span>
                        {data.value}
                    </p>
                </div>
            );
        }

        return null;
    };

    getDomain = () => {
        const maximo = max(this.props.data.map(dayData => max(dayData.data.map(d => d.value))));
        return [0, maximo];
    };

    render() {
        const range = [0, 255];
        const domain = this.getDomain();
        return (
            <div className="weekly-heatmap-chart">
                {this.props.data.map((dayData, i) => (
                    <ResponsiveContainer height={60} width="100%" key={dayData.day}>
                        <ScatterChart margin={{ top: 10, right: 0, bottom: 0, left: 0 }}>
                            <XAxis
                                type="category"
                                dataKey="period"
                                name="hora"
                                interval={0}
                                tick={{ fontSize: i === this.props.data.length - 1 ? 12 : 0 }}
                                tickLine={{ transform: 'translate(0, -6)' }}
                            />
                            <YAxis
                                type="number"
                                dataKey="index"
                                height={10}
                                width={30}
                                tick={false}
                                tickLine={false}
                                axisLine={false}
                                label={{ value: dayData.day, position: 'insideRight' }}
                            />
                            <ZAxis type="number" dataKey="value" domain={domain} range={range} />
                            <Tooltip
                                cursor={{ strokeDasharray: '3 3' }}
                                wrapperStyle={{ zIndex: 100 }}
                                content={this.renderTooltip}
                            />
                            <Scatter data={dayData.data} fill={primary['400']} />
                        </ScatterChart>
                    </ResponsiveContainer>
                ))}
            </div>
        );
    }
}

export default HeatMap;
