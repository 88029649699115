import React from 'react';
import { observer, inject } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { ROLE } from 'klara-common';

import CheckboxField from '@/components/CheckboxField';
import AutoChipField from '@/components/AutoChipField';
import SimpleSelect from '@/components/SimpleSelect';
import { viewPropsMapper } from '@/views/utils';

const InviteUserDialog = ({ viewStore: store, fullScreen }) => {
    const roleOptions = [
        { label: 'Host', value: ROLE.HOST },
        { label: 'Igüista', value: ROLE.IGUISTA },
        { label: 'Administrador', value: ROLE.ADMIN },
    ];
    const currentRole = store.newInvite.role || ROLE.HOST;

    return (
        <Dialog
            fullScreen={fullScreen}
            open={store.inviteModalOpen}
            onClose={store.closeInviteModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Nueva invitación</DialogTitle>
            <DialogContent className="klara-dialog-form">
                <div className="form-field">
                    <TextField
                        error={!!store.userFormError.name}
                        helperText={store.userFormError.name}
                        label="Nombre"
                        fullWidth={true}
                        value={store.newInvite.name || ''}
                        onChange={ev => store.setInviteField('name', ev.target.value)}
                    />
                </div>
                <div className="form-field">
                    <TextField
                        error={!!store.userFormError.email}
                        helperText={store.userFormError.email || 'Si es por app, llenar email.'}
                        label="Email"
                        fullWidth={true}
                        value={store.newInvite.email || ''}
                        onChange={ev =>
                            store.setInviteField('email', (ev.target.value || '').toLowerCase())
                        }
                    />
                </div>
                <div className="form-field">
                    <SimpleSelect
                        options={roleOptions}
                        value={currentRole}
                        onChange={val => store.setInviteField('role', val)}
                    />
                </div>
                {currentRole !== ROLE.ADMIN && (
                    <div className="form-field">
                        <AutoChipField
                            error={!!store.userFormError.locations}
                            helperText={store.userFormError.locations}
                            label="Locaciones asignadas"
                            options={store.locationOptions}
                            value={store.newInvite.locations || []}
                            onChange={val => store.setInviteField('locations', val)}
                        />
                    </div>
                )}
                {currentRole === ROLE.ADMIN && (
                    <div className="form-field">
                        <CheckboxField
                            value={store.newInvite.readOnly || false}
                            label="Read only admin"
                            onChange={val => store.setInviteField('readOnly', val)}
                        />
                    </div>
                )}
                <div className="form-field">
                    <TextField
                        label="Comentario/Referencia"
                        fullWidth={true}
                        value={store.newInvite.comment || ''}
                        onChange={ev => store.setInviteField('comment', ev.target.value)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={store.closeInviteModal} color="primary">
                    Cancelar
                </Button>
                <Button onClick={store.generateInvite} color="primary" autoFocus>
                    Generar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default inject(viewPropsMapper)(withMobileDialog()(observer(InviteUserDialog)));
