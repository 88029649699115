import { observable, action } from 'mobx';

import { LocationFormDialogStore } from '@/components/LocationFormDialog';
import { db, COLS } from 'klara-common';

export default class LocationsView {
    @observable locations = new Map();
    @observable orderedLocations = [];
    @observable fuentes = new Map();
    @observable openDeleteConfirm = false;
    @observable filters = {
        online: true,
        offline: true,
        inactive: false,
        search: ''
    };

    constructor(parent) {
        this.rootStore = parent;
        this.locationFormDialogStore = new LocationFormDialogStore();
    }

    initDBListeners() {
        this.locationsUnsubscribe = db
            .collection(COLS.LOCATION)
            .onSnapshot(this.updateLocationsSnapshot, this.onSnapshotError);
        this.fuentesUnsubscribe = db
            .collection(COLS.FUENTE)
            .onSnapshot(this.updateFuentesSnapshot, this.onSnapshotError);
    }

    stopDBListeners() {
        !!this.locationsUnsubscribe && this.locationsUnsubscribe();
        !!this.fuentesUnsubscribe && this.fuentesUnsubscribe();
    }

    @action updateLocationsSnapshot = snapshot => {
        snapshot.docChanges().forEach(change => {
            if (change.type === 'added' || change.type === 'modified') {
                this.locations.set(change.doc.id, { id: change.doc.id, ...change.doc.data() });
            } else if (change.type === 'removed') {
                this.locations.delete(change.doc.id);
            }
        });
    };

    @action updateFuentesSnapshot = snapshot => {
        snapshot.docChanges().forEach(change => {
            if (change.type === 'added' || change.type === 'modified') {
                this.fuentes.set(change.doc.id, change.doc.data());
            } else if (change.type === 'removed') {
                this.fuentes.delete(change.doc.id);
            }
        });
    };

    @action onSnapshotError = error => {
        console.log('Error', error);
    };

    @action openEditLocation = async loc => {
        const { id, ...data } = loc;
        this.locationFormDialogStore.openEditForm(loc.id, data);
    };

    @action openAddLocation = () => {
        this.locationFormDialogStore.openAddForm();
    };

    @action setFilter = (field, val) => {
        this.filters[field] = val;
    };

    @action deleteLocation = loc => {
        const { id } = loc;
        this.deleteId = id;
        this.openDeleteConfirm = true;
    };

    @action cancelDelete = () => {
        this.deleteId = null;
        this.openDeleteConfirm = false;
    };

    @action deleteLocationConfirm = () => {
        this.rootStore.setLoading(true);
        this.openDeleteConfirm = false;
        db.collection(COLS.LOCATION)
            .doc(this.deleteId)
            .delete();
    };

    onEnter() {
        this.initDBListeners();
    }

    onExit() {
        this.stopDBListeners();
    }
}
