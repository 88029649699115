import React from 'react';
import { observer, inject } from 'mobx-react';
import LoginForm from './LoginForm';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import { viewPropsMapper } from '@/views/utils';
import './login-form.scss';

const LoginFormPage = ({ viewStore }) => {
    return (
        <Container maxWidth="sm">
            <Paper className="login-form-paper">
                <div className="welcome">
                    <h2>Klara Backend</h2>
                </div>
                <LoginForm messages={viewStore.rootStore.userStore.messages} />
            </Paper>
        </Container>
    );
};

export default inject(viewPropsMapper)(observer(LoginFormPage));
