import React from 'react';
import LoginFormPage from '@/components/LoginForm';
import store from './store';
import {
  onRouterViewEnter,
  onRouterViewExit
} from '@/state/routerUtils';


const inviteViewConf = {
  name : 'invite',
  pattern : '/invite/:inviteId',
  onEnter : onRouterViewEnter,
  onExit : onRouterViewExit,
  component: <LoginFormPage />,
  isLoginView: true,
  store
};

export default inviteViewConf;
