import React from 'react';
import { observer, inject } from 'mobx-react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import { viewPropsMapper } from '@/views/utils';
import { LocationFormDialog } from '@/components/LocationFormDialog';

import MainTabs from './MainTabs';
import HomeTab from './HomeTab';
import AnalysisTab from './AnalysisTab';
import CashupsTab from './CashupsTab';
import RecargasTab from './RecargasTab';
import ServidasTab from './ServidasTab';
import AboutTab from './AboutTab';
import StatusIndicators from './StatusIndicators';
import { DEFAULT } from 'klara-common';

import './location-detail.scss';

const LocationView = ({ viewStore, userStore, goTo, route }) => {
    if (!viewStore) return null;
    const { location, fuente } = viewStore;
    const fuenteId =
        location.fuenteId === DEFAULT.FUENTE_ID ? 'SIN FUENTE' : location.fuenteId || '-';
    return (
        <Container className="location-container" justify="center">
            <div className="location-header">
                {!userStore.readOnly && (
                    <div className="location-edit-btn">
                        <IconButton onClick={viewStore.openEditForm}>
                            <EditIcon />
                        </IconButton>
                    </div>
                )}
                <h1 className="h1-title">{location && location.name}</h1>
                <div className="subtitle-fields">
                    <div className="subtitle-btn-link">
                        Fuente:{' '}
                        <Button
                            onClick={() => goTo('fuente', { id: fuenteId })}
                            disabled={fuenteId === DEFAULT.FUENTE_ID}
                        >
                            {fuenteId}
                        </Button>
                    </div>
                </div>
                {!!fuente.fuenteId && <StatusIndicators fuente={fuente} location={location} />}
                <MainTabs />
            </div>

            {viewStore.tab === 'main' && viewStore.tabStore && (
                <HomeTab tabStore={viewStore.tabStore} />
            )}
            {viewStore.tab === 'analysis' && viewStore.tabStore && (
                <AnalysisTab tabStore={viewStore.tabStore} />
            )}
            {viewStore.tab === 'servidas' && viewStore.tabStore && (
                <ServidasTab tabStore={viewStore.tabStore} />
            )}
            {viewStore.tab === 'cashups' && viewStore.tabStore && (
                <CashupsTab tabStore={viewStore.tabStore} />
            )}
            {viewStore.tab === 'recargas' && viewStore.tabStore && (
                <RecargasTab tabStore={viewStore.tabStore} />
            )}
            {viewStore.tab === 'about' && viewStore.tabStore && (
                <AboutTab tabStore={viewStore.tabStore} />
            )}

            <LocationFormDialog store={viewStore.locationFormDialogStore} />
        </Container>
    );
};

export default inject(viewPropsMapper)(observer(LocationView));
