import React from 'react';
import { observer } from 'mobx-react';

import { FormDialog } from '@/components/Form';
import formConf from './formConfig';

const CashupFormDialog = ({ store }) =>
    store ? (
        <FormDialog
            title={!store.editIndex ? 'Nuevo Cierre de Caja' : 'Editar Cierre de Caja'}
            open={store.openForm}
            loading={store.loading}
            onClose={() => store.setOpenForm(false)}
            onSubmit={store.onSubmit}
            config={formConf}
            initState={store.initForm}
        />
    ) : null;

export default observer(CashupFormDialog);
