import React from 'react';
import cn from 'classnames';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import './radio-field.scss';

const RadioField = ({value, onChange, options, label, className, error, helperText, labelPlacement = 'bottom'}) => {
  return (
  <FormControl component="fieldset" error={!!error} className={cn('radio-field form-item', className)}>
    <FormLabel component="legend" className="radio-field-label">{label}</FormLabel>
    <RadioGroup
      aria-label="position"
      name="position"
      value={value}
      onChange={e => onChange(e.target.value)}
      row
      className={cn('radio-field-group', `radio-field-group-label-${labelPlacement}`)}>
      {options.map( (opt, i) =>
       <FormControlLabel
         key={`o-${i}`}
         value={opt.value}
         control={<Radio color="primary" />}
         label={opt.label}
         labelPlacement={labelPlacement}
         className={`radio-item-label-${labelPlacement}`}
       />
     )}
     </RadioGroup>
    {!!error && <FormHelperText className="radio-error-msg">{helperText}</FormHelperText>}
  </FormControl>
)
}

export default RadioField;
