import React from 'react';
import View from './View';

const notFoundViewConf = {
  name: 'notFound',
  pattern: '/not-found',
  component: <View />
}

export default notFoundViewConf;
