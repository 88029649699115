import React from 'react';
import {observer, inject} from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import {viewPropsMapper} from '@/views/utils';

import './tool-bar.scss';

const FuenteToolBar = ({viewStore}) =>
    <div className="toolbar-container">
      <h4>Fijar intervalos, tamaño de servida...</h4>
      <div className="text-input-fields">
        <TextField className="tpicker" value={viewStore.timeMinServ || ''} onChange={viewStore.setTimeMinServ} label="Servida mínima (ml)" />
        <TextField className="tpicker" value={viewStore.timeMaxServ || ''} onChange={viewStore.setTimeMaxServ} label="Servida máxima (ml)" />
        <TextField className="tpicker" value={viewStore.timeBucketSize || ''} onChange={viewStore.setTimeBucketSize} label="Intervalo (min)" />
      </div>
    </div>

export default inject(viewPropsMapper)(observer(FuenteToolBar));
