import { observable, action } from 'mobx';

import { db, http, COLS, PRECIO_X_LITRO, DEFAULT } from 'klara-common';

export default class RecargasTabStore {
    @observable recargas = [];
    @observable loading = false;
    @observable openForm = false;
    @observable openForceSaldoForm = false;
    @observable openConfirm = false;

    constructor(main) {
        this.main = main;
        this.startRecargasListener();
    }

    startRecargasListener = () => {
        this.recargasUnsubscribe = db
            .collection(COLS.TRANSACCION)
            .where('toType', '==', 'location')
            .where('to', '==', this.main.locationId)
            .orderBy('timestamp', 'desc')
            .limit(30)
            .onSnapshot(this.updateSnapshot);
    };

    updateSnapshot = query => {
        if (!query.empty) {
            this.recargas.replace(query.docs.map(rDoc => ({ id: rDoc.id, ...rDoc.data() })));
        } else {
            this.recargas.clear();
        }
    };

    onExit = () => {
        this.recargasUnsubscribe && this.recargasUnsubscribe();
    };

    @action setOpenForm = val => {
        this.formInitState = {
            percentForHost: parseInt(this.main.location.percentForHost) || DEFAULT.PERCENT_FOR_HOST,
        };
        this.openForm = val !== false;
    };

    @action setOpenForceSaldoForm = val => {
        this.openForceSaldoForm = val;
    };

    @action onForceSaldoSubmit = form => {
        this.formToSubmit = form;
        this.formToSubmitType = 'forceSaldo';
        this.openConfirm = true;
    };

    @action onSubmit = form => {
        this.formToSubmit = form;
        this.formToSubmitType = 'recarga';
        this.openConfirm = true;
    };

    @action submitItem = () => {
        this.loading = true;
        this.openConfirm = false;
        this.openForm = false;
        this.openForceSaldoForm = false;

        if (this.formToSubmitType === 'recarga') {
            const isProm = !!this.formToSubmit.isProm;
            const cents = !isProm ? parseInt(parseFloat(this.formToSubmit.soles) * 100) : 0;
            const percentHost = !isProm ? parseFloat(this.formToSubmit.percentForHost) : 0;
            const conversion = !isProm ? percentHost / (100 - percentHost) + 1 : 1;
            const ml = isProm
                ? parseInt(this.formToSubmit.litrosProm) * 1000
                : parseInt(cents * conversion * 10 * (1 / PRECIO_X_LITRO));

            const transaccion = {
                from: this.main.location.hostId || 'UNKNOWN',
                fromType: 'host',
                via: 'backend',
                to: this.main.locationId,
                toType: 'location',
                formaDePago: !isProm ? this.formToSubmit.formaDePago : 'prom',
                mlProm: isProm ? ml : 0,
                conversion: conversion,
                timestamp: new Date(),
                status: 'esperandoDestino',
                cents,
                ml: !isProm ? ml : 0,
            };
            db.collection(COLS.TRANSACCION)
                .add(transaccion)
                .then(() => {
                    this.loading = false;
                });
        } else if (this.formToSubmitType === 'forceSaldo') {
            http.post('/api/forceSetSaldo', {
                locationId: this.main.locationId,
                ml: parseInt(this.formToSubmit.litros * 1000),
            }).then(() => {
                this.loading = false;
            });
        }
    };

    @action cancelSubmit = () => {
        this.openConfirm = false;
        this.formToSubmit = null;
        this.formToSubmitType = null;
    };
}
