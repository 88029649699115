import { observable, action, computed, autorun, toJS, when } from 'mobx';
import { isEmpty, set } from 'lodash';
import { validateGenericForm } from './validate';
import { getDefaultState } from './utils';

export default class FormStore {
    initState = null;
    @observable form = {};
    @observable state = 'edit';
    @observable validate = false;
    @observable filesUploading = {};

    @computed get errors() {
        return this.validate ? validateGenericForm(this.form, this.config) : {};
    }

    @computed get isUploadingFiles() {
        return Object.values(this.filesUploading).some(f => !!f);
    }

    @computed get isValid() {
        return !!Object.keys(this.errors).length;
    }

    constructor({ config, initState = null, onChange, setDialogUploading }) {
        if (!initState) {
            initState = getDefaultState(config);
        }
        this.config = config;
        this.form = initState;
        this.initState = initState;
        this.setDialogUploading = setDialogUploading || (() => false);
        this.disposeValidateAutorun = autorun(() => {
            this.validate &&
                onChange &&
                onChange({
                    form: toJS(this.form),
                    errors: toJS(this.errors),
                });
        });
    }

    @action setForm = (data, reset = false) => {
        if (!reset) {
            this.form = { ...this.form, ...data };
        } else {
            this.form = data;
        }
    };

    @action setField = (path, value) => {
        set(this.form, path, value);
    }

    @action setFilesUploading = (key, val) => {
        this.filesUploading[key] = val;
    }

    @action setValidate = val => {
        this.validate = val;
    };

    @action setConfig = (config, reset = true) => {
        this.config = config;
        if (reset) {
            this.errors = {};
            this.form = {};
        }
    };

    @action setUploading = (val) => {
        this.uploading = val;
    }

    onSubmit = submitFunc => {
        this.setValidate(true);
        setTimeout( async () => {
            if (isEmpty(this.errors)) {
                if (this.isUploadingFiles) {
                    this.setDialogUploading(true);
                    await when(() => this.isUploadingFiles === false);
                    this.setDialogUploading(false);
                }
                submitFunc && submitFunc(this.getForm(), toJS(this.errors));
            }
        });
    };

    getForm = () => toJS(this.form);

    dispose = () => {
        this.disposeValidateAutorun();
    };
}
