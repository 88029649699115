import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import FuenteStateChip from '@/components/FuenteStateChip';
import ConnectionState from '@/components/ConnectionState';

const MobileList = ({ fuentesSorted, goTo }) => (
    <List>
        <Divider />
        {fuentesSorted.map(fuente => {
            return (
                <Fragment key={fuente.fuenteId}>
                    <ListItem button onClick={() => goTo('fuente', { id: fuente.fuenteId })}>
                        <ListItemText
                            classes={{ primary: 'location-list-name' }}
                            primary={fuente.fuenteId}
                            secondaryTypographyProps={{ component: 'div' }}
                            secondary={
                                <div className="fuente-sec-item">
                                    <div className="sec-item">
                                        <FuenteStateChip fuente={fuente} />
                                    </div>
                                    <div className="sec-item">
                                        {fuente.locationName || 'sin location'}
                                    </div>
                                    <div className="sec-item">{fuente.version}</div>
                                </div>
                            }
                        />
                        <ListItemSecondaryAction>
                            <ConnectionState
                                state={fuente.online}
                                since={fuente.onlineModified}
                                showLabel={false}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </Fragment>
            );
        })}
    </List>
);

export default observer(MobileList);
