import React from 'react';
import { observer, inject } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { ROLE } from 'klara-common';

import CheckboxField from '@/components/CheckboxField';
import AutoChipField from '../../components/AutoChipField';
import SimpleSelect from '../../components/SimpleSelect';
import { viewPropsMapper } from '@/views/utils';

const EditUserDialog = ({ viewStore: store, fullScreen }) => {
    const roleOptions = [
        { label: 'Usuario', value: ROLE.USER },
        { label: 'Host', value: ROLE.HOST },
        { label: 'Igüista', value: ROLE.IGUISTA },
        { label: 'Administrador', value: ROLE.ADMIN },
    ];
    const currentRole = (store.editUserData && store.editUserData.role) || ROLE.HOST;
    return (
        <Dialog
            fullScreen={fullScreen}
            open={!!store.editUserId}
            onClose={store.closeEditUserDialog}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <span>Editar Usuario</span>
                <IconButton
                    aria-label="Close"
                    className="dialog-corner-close-btn"
                    onClick={store.closeEditUserDialog}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className="klara-dialog-form">
                <div className="form-field">
                    <TextField
                        error={!!store.userFormError.name}
                        helperText={store.userFormError.name}
                        label="Nombre"
                        fullWidth={true}
                        value={store.editUserData.name || ''}
                        onChange={ev => store.setEditUserField('name', ev.target.value)}
                    />
                </div>
                <div className="form-field">
                    <SimpleSelect
                        options={roleOptions}
                        value={currentRole}
                        onChange={val => store.setEditUserField('role', val)}
                    />
                </div>
                {![ROLE.ADMIN, ROLE.USER].includes(currentRole) && (
                    <div className="form-field">
                        <AutoChipField
                            error={!!store.userFormError.fuentes}
                            helperText={store.userFormError.fuentes}
                            label="Locaciones asignadas"
                            options={store.locationOptions || []}
                            value={store.editUserData.locations || []}
                            onChange={val => store.setEditUserField('locations', val)}
                        />
                    </div>
                )}
                {currentRole === ROLE.ADMIN && (
                    <div className="form-field">
                        <CheckboxField
                            value={store.editUserData.readOnly || false}
                            label="Read only admin"
                            onChange={val => store.setEditUserField('readOnly', val)}
                        />
                    </div>
                )}
                <div className="form-field">
                    <TextField
                        label="Comentario"
                        fullWidth={true}
                        value={store.editUserData.comment || ''}
                        onChange={ev => store.setEditUserField('comment', ev.target.value)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={store.closeEditUserDialog} color="primary">
                    Cancelar
                </Button>
                <Button onClick={store.saveUserProfile} color="primary" autoFocus>
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default inject(viewPropsMapper)(withMobileDialog()(observer(EditUserDialog)));
