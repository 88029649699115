import './simple-menu.scss';
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

/**
A simple "More" icon button, that opens up a menu. Based on MUI.
*/
export default class SimpleMenu extends Component {

  static propTypes = {
    /** The menu items. A label, an onClick func and ready. Icon optional */
    entries : PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick : PropTypes.func.isRequired,
      icon: PropTypes.node
    })),
    /** If the menu button is disabled. */
    disabled : PropTypes.bool,
    /** Alternative button icon, for use with MUI IconButton. */
    buttonIcon: PropTypes.node,
    /** Replace the whole MUI IconButton with a custom Button component. onClick prop required. */
    buttonComponent : PropTypes.node,
    /** aditional menu props for MUI Menu component */
    MenuProps: PropTypes.object
  };

  static defaultProps = {
    disabled : false
  };

  state = {
    anchorEl : null
  };

  onOpen = ev => this.setState({anchorEl: ev.currentTarget});
  onClose = () => this.setState({anchorEl: null});

  onItemClick = func => {
    !!func && func();
    this.clickTimeout = setTimeout(this.onClose, 250);
  }

  componentWillUnmount() {
    !!this.clickTimeout && clearTimeout(this.clickTimeout);
  }

  render() {
    const {buttonComponent, buttonIcon, entries, disabled, MenuProps} = this.props;
    const {anchorEl} = this.state;

    const Btn = !!buttonComponent
                ? React.cloneElement(buttonComponent, {
                    onClick : this.onOpen,
                    disabled : disabled,
                    className: cn('ch-simple-menu-btn', buttonComponent.props.className)
                  })
                : <IconButton
                    onClick={this.onOpen}
                    className="ch-simple-menu-btn"
                    disabled={disabled}
                    disableRipple>
                    {!!buttonIcon
                      ? buttonIcon
                      : <MoreVertIcon />
                    }
                  </IconButton>;
    return (
      <Fragment>
        {Btn}
        <Menu className="ch-simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.onClose}
          {...MenuProps}
          >
          {entries.map( (e, i) =>
            <MenuItem className="ch-simple-menu-item" key={`itm-${i}`} onClick={() => this.onItemClick(e.onClick)}>
              {!!e.icon
                ? <Fragment>
                    <ListItemIcon className="ch-simple-menu-item-icon">{e.icon}</ListItemIcon>
                    <ListItemText className="ch-simple-menu-item-text" primary={e.label} />
                  </Fragment>
                : e.label
              }
            </MenuItem>
          )}
        </Menu>
      </Fragment>
    );
  }
}
