import React, {useState} from 'react';
import cn from 'classnames';
import Dropzone from 'react-dropzone';
import ImageIcon from '@material-ui/icons/AddAPhoto';

import './dzone.scss';

const Dzone = ({onNewFiles}) => {
    const [active, setActive] = useState(false);
    return (
    <Dropzone
      onDragEnter={() => setActive(true)}
      onDragLeave={() => setActive(false)}
      onDrop={() => setActive(false)}
      accept="image/png,image/jpeg"
      onDropAccepted={onNewFiles}>
      {({ getRootProps, getInputProps }) =>
        <div {...getRootProps()} className={cn('drop-zone', active && 'active')}>
          <input {...getInputProps()} />
          <div className="drop-zone-content">
            <ImageIcon className="drop-zone-icon" />
            <div className="drop-zone-label">Arrastra imágenes aquí o cliquea para seleccionar</div>
          </div>
        </div>
      }
    </Dropzone>
  )
}

export default Dzone;
