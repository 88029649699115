import React, { Fragment, useCallback, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import TimerIcon from '@material-ui/icons/Timer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {
    ALLOWED_FUENTE_STATUSES,
    FUENTE_STATUSES_FREE_ONLY_HIDE,
    STATUS_UI,
    PLAN,
    http,
} from 'klara-common';

const propsMapper = (allStores, props, context) => {
    const { userStore } = allStores.rootStore;
    return {
        role: userStore.role,
        readOnly: userStore.readOnly,
    };
};

const FuenteStateChip = ({ fuente, role, readOnly, size = 'small' }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [tempStatus, setTempStatus] = React.useState(null);
    const status = fuente.status || 'unknown';
    const setStatus = useCallback(
        s => {
            setTempStatus({ status: s, verified: false });
            http.post('/api/setFuenteStatus', { fuenteId: fuente.fuenteId, status: s });
            setAnchorEl(null);
        },
        [fuente.fuenteId]
    );
    useEffect(() => {
        tempStatus && setTempStatus(null);
    }, [status, tempStatus]);
    const showStatus = tempStatus ? tempStatus.status : status;
    const verified = tempStatus ? tempStatus.verified : !!fuente.verified && fuente.verified.status;

    return (
        <Fragment>
            <Chip
                label={STATUS_UI[showStatus].label}
                size={size}
                style={{
                    color: 'white',
                    backgroundColor: STATUS_UI[showStatus].color,
                }}
                onClick={e => !readOnly && setAnchorEl(e.currentTarget)}
                avatar={
                    verified ? (
                        <DoneIcon style={{ fill: 'white' }} />
                    ) : (
                        <TimerIcon style={{ fill: 'white' }} />
                    )
                }
            />
            {!!ALLOWED_FUENTE_STATUSES[role] && (
                <Menu
                    onClose={() => setAnchorEl(null)}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                >
                    {ALLOWED_FUENTE_STATUSES[role]
                        .filter(
                            statVal =>
                                !!STATUS_UI[statVal] &&
                                (fuente.plan !== PLAN.LIBRE || !FUENTE_STATUSES_FREE_ONLY_HIDE.includes(statVal))
                        )
                        .map((statVal, i) => (
                            <MenuItem
                                key={i}
                                onClick={() => setStatus(statVal)}
                                value={statVal}
                                selected={fuente.status === statVal}
                            >
                                {STATUS_UI[statVal].label}
                            </MenuItem>
                        ))}
                </Menu>
            )}
        </Fragment>
    );
};

export default inject(propsMapper)(observer(FuenteStateChip));
