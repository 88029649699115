import React from 'react';
import Button from '@material-ui/core/Button';
import {observer, inject} from 'mobx-react';

import {viewPropsMapper} from '@/views/utils';
import './error.scss';

const Error = ({viewStore: store}) => (
  <div className="success">
    <h1>¡UY! Sucedió un error.</h1>
    <div className="msg-wrap">
      <p className="emoji">😬😯</p>
      <p>Por favor inténtado de nuevo:</p>
      <Button size="large" variant="contained" color="primary" onClick={store.retrySend}>Intentar de nuevo!</Button>
      <p>Si el error persiste, ponte en contacto con nosotros</p>
    </div>
  </div>
);

export default inject(viewPropsMapper)(observer(Error));
