import React from 'react';
import { inject, observer } from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import SimpleMenu from '../../components/SimpleMenu';
import SimpleDialog from '../../components/SimpleDialog';
import { viewPropsMapper } from '@/views/utils';

const UserTab = ({ viewStore: store }) => {
    let userRows = store.profilesSorted.map(item => {
        const [id, data] = item;
        const menuEntries = [
            { label: 'Editar', onClick: () => store.editUserProfile(id), icon: <EditIcon /> },
            { label: 'Borrar', onClick: () => store.deleteUser(id), icon: <DeleteIcon /> },
        ];
        return (
            <TableRow key={id}>
                <TableCell>
                    <div className="u-display-name">{data.name}</div>
                    <div className="u-email">{data.email}</div>
                </TableCell>
                <TableCell>{data.role}</TableCell>
                <TableCell>{data.comment}</TableCell>
                <TableCell width={48} padding="checkbox">
                    <SimpleMenu entries={menuEntries} />
                </TableCell>
            </TableRow>
        );
    });

    return (
        <Grid container className="user-view-container" spacing={10} justify="center">
            <Grid item xs={12}>
                <SimpleDialog
                    open={store.showInviteLinkDialog}
                    actions={[
                        {
                            label: 'OK',
                            raised: true,
                            onClick: store.closeInviteLinkDialog,
                        },
                    ]}
                >
                    {store.inviteLink}
                </SimpleDialog>
                {!!userRows.length && (
                    <Table className="user-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Rol</TableCell>
                                <TableCell>Comentario</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{userRows}</TableBody>
                    </Table>
                )}
            </Grid>
        </Grid>
    );
};

export default inject(viewPropsMapper)(observer(UserTab));
