const NotificationTestFormConfig = {
    sections: [
        {
            title: 'Notificación Test',
            fields: [
                {
                    key: 'msgTitle',
                    label: 'Title',
                    type: 'textfield',
                    required: true,
                    default: 'Notificación Test',
                },
                {
                    key: 'msgBody',
                    label: 'Body',
                    type: 'textfield',
                    required: true,
                    default: 'Este es un test',
                },
                {
                    key: 'userId',
                    label: 'User Id',
                    type: 'textfield',
                },
                {
                    key: 'locationId',
                    label: 'Location Id (subscribed to location)',
                    type: 'textfield',
                },
                {
                    key: 'isHostApp',
                    label: 'send to host app',
                    type: 'checkbox',
                },
                {
                    key: 'delay',
                    label: 'delay ms',
                    type: 'textfield',
                    validateType: 'int',
                },
            ],
        },
    ],
};

export default NotificationTestFormConfig;
