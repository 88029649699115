import React, { Component, Fragment } from 'react';
import { inject } from 'mobx-react';
import { RouterView } from 'mobx-state-router';
import AppBar from '@/components/AppBar';
import Loading from '@/components/loading';

@inject('rootStore')
class Shell extends Component {
  render() {
    const { rootStore } = this.props;
    const { routerStore } = rootStore;
    return (
      <Fragment>
        <AppBar />
        <Loading />
        <div className="klara-main-container">
          <RouterView routerStore={routerStore} viewMap={rootStore.views} />
        </div>
      </Fragment>
    )
  }
}

export default Shell;
