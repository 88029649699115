import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import MenuIcon from '@material-ui/icons/Menu';
import UpdateIcon from '@material-ui/icons/Update';
import LocationIcon from '@material-ui/icons/Room';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NotificationIcon from '@material-ui/icons/Notifications';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import { mdiLogout as logoutIcon } from '@mdi/js';
import Icon from '@mdi/react';
import QrCodeIcon from '@/icons/QrCode';

import { ROLE } from 'klara-common';

import './app-bar.scss';

const styles = {
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
};

@inject('rootStore')
@withStyles(styles)
@observer
class KlaraAdminAppBar extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    state = {
        drawerIsOpen: false,
    };

    goTo = view => {
        this.props.rootStore.routerStore.goTo(view);
        this.setState({ drawerIsOpen: false });
    };

    logout = () => {
        this.setState({ drawerIsOpen: false });
        this.props.rootStore.userStore.logout();
    };

    render() {
        const {
            classes,
            rootStore: { userStore, backTo },
        } = this.props;

        const { readOnly } = userStore;

        return (
            <div className={`${classes.root} main-app-bar`}>
                <AppBar position="static">
                    <Toolbar>
                        {userStore.isSignedIn && (
                            <IconButton
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="Menu"
                                onClick={() => this.setState({ drawerIsOpen: true })}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        {!!backTo && (
                            <IconButton
                                className={classes.menuButton}
                                color="inherit"
                                onClick={() => this.goTo(backTo)}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                        )}
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            Klara Backend
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    open={this.state.drawerIsOpen}
                    onClose={() => this.setState({ drawerIsOpen: false })}
                >
                    <Typography variant="h6" color="inherit" className="drawer-title">
                        Menu
                    </Typography>
                    <Divider />
                    <List className="drawer-list">
                        <ListItem button onClick={() => this.goTo('dashboard')}>
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                        <ListItem button onClick={() => this.goTo('locations')}>
                            <ListItemIcon>
                                <LocationIcon />
                            </ListItemIcon>
                            <ListItemText primary="Locaciones" />
                        </ListItem>
                        {userStore.role === ROLE.ADMIN && (
                            <Fragment>
                                <ListItem button onClick={() => this.goTo('fuentes')}>
                                    <ListItemIcon>
                                        <LocalDrinkIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Fuentes" />
                                </ListItem>
                                {!readOnly && (
                                    <ListItem button onClick={() => this.goTo('userAdmin')}>
                                        <ListItemIcon>
                                            <PeopleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Usuarios" />
                                    </ListItem>
                                )}
                                {/*}
                                  <ListItem button onClick={() => this.goTo('hostRecruteResults')}>
                                    <ListItemIcon><PeopleIcon /></ListItemIcon>
                                    <ListItemText primary="Reclutamiento Hosts" />
                                  </ListItem>
                                */}
                                {!readOnly && (
                                    <ListItem button onClick={() => this.goTo('fuenteUpdates')}>
                                        <ListItemIcon>
                                            <UpdateIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Fuente Updates" />
                                    </ListItem>
                                )}
                                {!readOnly && (
                                    <ListItem button onClick={() => this.goTo('generateQRs')}>
                                        <ListItemIcon>
                                            <QrCodeIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Generar QRs" />
                                    </ListItem>
                                )}
                                {!readOnly && (
                                    <ListItem button onClick={() => this.goTo('notificationTest')}>
                                        <ListItemIcon>
                                            <NotificationIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Test de notificaciones" />
                                    </ListItem>
                                )}
                            </Fragment>
                        )}
                        <ListItem button onClick={this.logout}>
                            <ListItemIcon>
                                <Icon
                                    path={logoutIcon}
                                    color="rgba(0, 0, 0, 0.54)"
                                    size={1}
                                    style={{ position: 'relative', left: '2px' }}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    </List>
                </Drawer>
            </div>
        );
    }
}

export default KlaraAdminAppBar;
