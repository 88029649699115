import React from 'react';
import View from './View';
import store from './store';
import {
  runAllHooks,
  checkUserIsSignedIn,
  checkUserIsAdmin,
  onRouterViewEnter,
  onRouterViewExit
} from '@/state/routerUtils';

const userAdminViewConf = {
  name : 'userAdmin',
  pattern : '/user-admin',
  onEnter : runAllHooks(
              checkUserIsSignedIn,
              checkUserIsAdmin,
              onRouterViewEnter
            ),
  onExit : onRouterViewExit,
  component: <View />,
  store
};

export default userAdminViewConf;
