import React from 'react';
import {observer, inject} from 'mobx-react';
import Portal from '@material-ui/core/Portal';
import CircularProgress from '@material-ui/core/CircularProgress';

import './loading.scss';

const Loading = ({rootStore}) => rootStore.loading
    ? <Portal>
        <div className="main-loading-container">
          <div className="progress-container">
            <CircularProgress />
          </div>
        </div>
      </Portal>
    : null;

export default inject('rootStore')(observer(Loading));
