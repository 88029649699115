import { observable, action, toJS, runInAction, computed, when } from 'mobx';
import { isEqual } from 'lodash';

import { http, FirestoreFieldValue, ENV } from 'klara-common';

export default class ComandosTabStore {
    @observable port = 10000;
    @observable sshLoading = false;
    @observable error = '';
    @observable alert = null;
    @observable loading = false;
    @observable openConfirm = false;
    @observable confirmMsg = null;

    @computed get tunnel() {
        return (!!this.main.fuente && this.main.fuente.tunnel) || null;
    }

    constructor(main) {
        this.main = main;
        let portnum = 10000 + parseInt(this.main.fuenteId.replace(/(I-|IGUA_)/, ''));
        if (isNaN(portnum)) {
            portnum = 10000 + new Date().getUTCMilliseconds();
        }
        this.port = portnum;
    }

    @action openFuenteTunnel = () => {
        if (this.sshLoading) return;
        this.disposeAsync();
        this.sshLoading = true;
        this.alert = null;
        http.post('/api/fuenteSSHTunnel', {
            command: 'open',
            fuenteId: this.main.fuenteId,
            port: toJS(this.port),
        })
            .then(() => {
                const checkAgainst =
                    (this.main.fuente.tunnel && toJS(this.main.fuente.tunnel)) || {};
                this.disposeWhen = when(
                    () =>
                        !!this.main.fuente.tunnel &&
                        !isEqual(toJS(this.main.fuente.tunnel || {}, checkAgainst)) &&
                        this.main.fuente.tunnel.active,
                    res => {
                        this.sshLoading = false;
                        this.timeout && clearTimeout(this.timeout);
                        this.timeout = null;
                    }
                );
                this.timeout = setTimeout(() => {
                    this.disposeWhen && this.disposeWhen();
                    this.sshLoading = false;
                    this.alert = { msg: 'No hubo respuesta en 2 minutos', type: 'warning' };
                }, 60 * 1000 * 2);
            })
            .catch(e =>
                runInAction(() => {
                    this.sshLoading = false;
                    this.error =
                        (!!e.error &&
                            !!e.error.response &&
                            !!e.error.response.body &&
                            e.error.response.body.error) ||
                        'Hubo un problema. Intenta de nuevo.';
                    console.log('catch', e);
                })
            );
    };

    @action closeFuenteTunnel = () => {
        if (this.sshLoading) return;
        this.disposeAsync();
        this.sshLoading = true;
        this.error = '';
        http.post('/api/fuenteSSHTunnel', {
            command: 'close',
            fuenteId: this.main.fuenteId,
        })
            .then(() => {
                const checkAgainst =
                    (this.main.fuente.tunnel && toJS(this.main.fuente.tunnel)) || {};
                this.disposeWhen = when(
                    () =>
                        !!this.main.fuente.tunnel &&
                        !isEqual(toJS(this.main.fuente.tunnel || {}, checkAgainst)) &&
                        !this.main.fuente.tunnel.active,
                    () => {
                        this.sshLoading = false;
                        this.timeout && clearTimeout(this.timeout);
                        this.timeout = null;
                    }
                );
                this.timeout = setTimeout(() => {
                    this.disposeWhen && this.disposeWhen();
                    this.sshLoading = false;
                    this.error = 'No hubo respuesta en 2 minutos';
                }, 60 * 1000 * 2);
            })
            .catch(e =>
                runInAction(() => {
                    this.sshLoading = false;
                    this.error =
                        (!!e.error &&
                            !!e.error.response &&
                            !!e.error.response.body &&
                            e.error.response.body.error) ||
                        'Hubo un problema. Intenta de nuevo.';
                    console.log('catch', e.error);
                })
            );
    };

    @action resetearTunnel = () => {
        this.sshLoading = false;
        this.main.fuenteRef.update({ tunnel: FirestoreFieldValue.delete() });
        this.closeFuenteTunnel();
    };

    /***************** REBOOT ***********************/

    @action reboot = async () => {
        if (this.loading) return;
        this.disposeAsync();
        this.loading = true;
        this.alert = null;
        this.openConfirm = false;
        try {
            await http.post('/api/rebootFuente', { fuenteId: this.main.fuenteId });
            this.alert = { severity: 'info', msg: `Reboot enviado a fuente.` };
            const checkAgainst = toJS(this.main.fuente.reboot) || null;
            this.disposeWhen = when(
                () => !!this.main.fuente.reboot && this.main.fuente.reboot !== checkAgainst,
                () => {
                    this.loading = false;
                    this.alert = { msg: 'Reboot recibido por fuente!', severity: 'success' };
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = null;
                }
            );
            this.timeout = setTimeout(() => {
                this.disposeWhen && this.disposeWhen();
                this.loading = false;
                this.alert = { msg: 'No hubo respuesta en 2 minutos.', severity: 'warning' };
            }, 60 * 1000 * 2);
        } catch (e) {
            this.loading = false;
            const msg =
                (!!e.error &&
                    !!e.error.response &&
                    !!e.error.response.body &&
                    e.error.response.body.error) ||
                'Hubo un problema. Intenta de nuevo.';
            this.alert = { msg, severity: 'error' };
            console.log('catch', e);
        }
    };

    @action confirmReboot = () => {
        this.onConfirm = this.reboot;
        this.confirmMsg = 'Seguro?? Ojo, alguien se puede estar sirviendo.';
        this.openConfirm = true;
    };

    /****************** SWITCH ENV *******************/

    @action switchEnv = async () => {
        const env = ENV === 'PROD' ? 'STAGE' : 'PROD';
        this.openConfirm = false;
        this.main.rootStore.setLoading(true);
        try {
            await http.post('/api/switchFuenteEnv', { fuenteId: this.main.fuenteId, env });
            this.alert = { severity: 'info', msg: `Cambio de entorno enviado a fuente.` };
            const checkAgainst = toJS(this.main.fuente.reboot) || null;
            this.disposeWhen = when(
                () => !!this.main.fuente.reboot && this.main.fuente.reboot !== checkAgainst,
                () => {
                    this.alert = {
                        msg: `Cambio de entorno a a ${env} recibido por fuente! Reiniciando...`,
                        severity: 'success',
                    };
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = null;
                }
            );
            this.timeout = setTimeout(() => {
                this.disposeWhen && this.disposeWhen();
                this.loading = false;
                this.alert = { msg: 'No hubo respuesta en 2 minutos.', severity: 'warning' };
            }, 60 * 1000 * 2);
        } catch (e) {
            console.error(e);
            this.alert = { severity: 'error', msg: 'algo salió mal :(' };
        }
        this.main.rootStore.setLoading(false);
    };

    @action confirmSwitchEnv = () => {
        this.onConfirm = this.switchEnv;
        this.confirmMsg = 'Seguro??? Tendrás que cambiar al otro entorno para manejar la fuente.';
        this.openConfirm = true;
    };

    /********* COMMON ***************/

    @action onConfirmCancel = () => {
        this.openConfirm = false;
    };

    disposeAsync = () => {
        this.disposeWhen && this.disposeWhen();
        this.disposeWhen = null;
        this.timout && clearTimeout(this.timeout);
        this.timeout = null;
    };

    onExit = () => {
        this.disposeAsync();
    };
}
