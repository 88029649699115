import {median, percentile} from 'stats-lite';
import {min, max} from 'lodash';
import {Accumulator} from '@/utils';

const getBoxPlotData = (data) => {
  const quartile2 = median(data)

  const quartile1 = percentile(data, 0.25)
  const quartile3 = percentile(data, 0.75)
  const interQuartileRange = quartile3 - quartile1

  const lowerOutlierCutoff = quartile1 - 1.5 * interQuartileRange
  const upperOutlierCutoff = quartile3 + 1.5 * interQuartileRange

  const outliers = []
  const nonOutliers = []
  data.forEach(datum => {
    if (datum < lowerOutlierCutoff || datum > upperOutlierCutoff) {
      outliers.push(datum)
    } else {
      nonOutliers.push(datum)
    }
  })

  const whiskerLow = min(nonOutliers)
  const whiskerHigh = max(nonOutliers)

  const outlierAc = new Accumulator();
  outliers.forEach(o => {
    outlierAc.add(o, 1);
  })

  return {
    whiskerLow : whiskerLow,
    quartile1 : quartile1,
    quartile2 : quartile2,
    quartile3 : quartile3,
    whiskerHigh : whiskerHigh,
    outliers : outlierAc.toArray('number')
  }
}

export {getBoxPlotData};
