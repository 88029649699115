import Login from '@/views/Login';
import Dashboard from '@/views/Dashboard';
import Fuentes from '@/views/Fuentes';
import FuenteDetail from '@/views/FuenteDetail';
import UserAdmin from '@/views/UserAdmin';
import Invite from '@/views/Invite';
import HostRecruteForm from '@/views/HostRecruteForm';
import NotFound from '@/views/NotFound';
import FuenteUpdates from '@/views/FuenteUpdates';
import Locations from '@/views/Locations';
import LocationDetail from '@/views/LocationDetail';
import NotificationsTest from '@/views/NotificationsTest';
import GenerateQrs from '@/views/GenerateQRs';
import { createRedirect } from './routerUtils';

const routes = [
    Login,
    Dashboard,
    Invite,
    Fuentes,
    FuenteDetail,
    UserAdmin,
    HostRecruteForm,
    FuenteUpdates,
    Locations,
    LocationDetail,
    NotificationsTest,
    GenerateQrs,
    createRedirect('/host-form', 'hostRecruteForm', { path: 'inicio' }, { noLogin: true }),
    NotFound,
];

export default routes;
