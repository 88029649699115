import React from 'react';
import { observer, inject } from 'mobx-react';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { viewPropsMapper } from '@/views/utils';

import './main-tabs.scss';

const MainTabs = ({ viewStore, userStore }) => {
    const showRecargaTab =
        !userStore.readOnly && (!viewStore.location.plan || viewStore.location.plan === 'prepago');
    return (
        <div className="main-tabs-container">
            <AppBar position="static" color="default" className="main-tabs">
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={viewStore.tab}
                    onChange={(e, tab) => viewStore.goToTab(tab)}
                >
                    <Tab label="Info" value="main" />
                    {/*<Tab label="Analysis" value="analysis" />*/}
                    <Tab label="Servidas" value="servidas" />
                    {!userStore.readOnly && <Tab label="Monedas" value="cashups" />}
                    {showRecargaTab && <Tab label="Recarga" value="recargas" />}
                    <Tab label="About" value="about" />
                </Tabs>
            </AppBar>
        </div>
    );
};

export default inject(viewPropsMapper)(observer(MainTabs));
