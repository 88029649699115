import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const defaultGetOptionSelected = (opt, val) => {
    if (val.value) {
        return opt.value === val.value;
    } else {
        return opt.value === val;
    }
};

const AutoChipField = ({
    label,
    value,
    options,
    getOptionLabel,
    getOptionSelected,
    placeholder,
    onChange,
    helperText,
    error,
    className,
}) => (
    <Autocomplete
        multiple
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected || defaultGetOptionSelected}
        value={value}
        onChange={(ev, val) => onChange(val)}
        renderInput={params => (
            <TextField
                {...params}
                {...{ label, placeholder, error, helperText, className }}
                fullWidth
            />
        )}
    />
);

AutoChipField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.array,
    options: PropTypes.array,
    getOptionLabel: PropTypes.func,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    helperText: PropTypes.node,
    error: PropTypes.bool,
    className: PropTypes.string,
};

AutoChipField.defaultProps = {
    getOptionLabel: o => o.label,
};

export default AutoChipField;
