import { find, get } from 'lodash';
import { isEmail, isInt } from 'validator';
import { SimpleOpeningHours } from 'simple-opening-hours';

const validateMsg = {
    required: 'Este campo es obligatorio.',
    email: 'Por favor ingresa un email válido.',
    phone: 'Por favor ingresa un teléfono válido.',
    openingHours: 'Formato de horarios inválido.',
    int : 'Por favor ingresa un número entero.',
    number : 'Por favor ingresa un número.'
};

function getFieldPoints(form, field) {
    let points = 0;
    const fval = get(form, field.key);
    //console.log(JSON.stringify(field));
    if (fval || field.type === 'subgroup') {
        switch (field.type) {
            case 'checkbox':
                points += field.points || 0;
                break;
            case 'select':
                points += find(field.options, o => o.value === fval).points || 0;
                break;
            case 'textfield':
                points += field.points || 0;
                break;
            case 'multiselect':
                get(form, field.key).forEach(opt => {
                    points += opt.points;
                });
                break;
            default:
                break;
        }

        if (field.children && (field.type !== 'checkbox' || (field.type === 'checkbox' && fval))) {
            let childPoints = 0;
            field.children.forEach(f => {
                childPoints += getFieldPoints(form, f);
            });
            if (field.maxPoints && childPoints > field.maxPoints) {
                childPoints = field.maxPoints;
            }
            points += childPoints;
        }
    }
    return points;
}

function getFormPoints(form, config) {
    let points = 0;
    config.autotest.pointQuestions.fields.forEach(f => {
        points += getFieldPoints(form, f);
    });
    return points;
}

export function validateAndGauge(form, config) {
    // obligatorios
    let errors = {};
    config.autotest.basic.fields.forEach(field => {
        const fieldErrors = validateField(form, field);
        errors = { ...errors, ...fieldErrors };
    });
    const points = getFormPoints(form, config);
    return { errors, points };
}

export function validateDataForm(form, config) {
    let errors = {};
    config.sections.forEach(sec => {
        sec.fields.forEach(field => {
            const fieldErrors = validateField(form, field);
            errors = { ...errors, ...fieldErrors };
        });
    });
    return errors;
}

export function validateGenericForm(form, config) {
    let errors = {};
    config.sections.forEach(sec => {
        if (!sec.showOnCondition || sec.showOnCondition(form)) {
            sec.fields.forEach(field => {
                if (!field.showOnCondition || field.showOnCondition(form)) {
                    const fieldErrors = validateField(form, field);
                    errors = { ...errors, ...fieldErrors };
                }
            });
        }
    });
    return errors;
}

function validateField(form, field) {
    const fval = get(form, field.key);
    let errors = {};
    if (field.required && !fval) {
        errors[field.key] = validateMsg.required;
    } else if (field.validateType && (field.required || fval)) {
        switch (field.validateType) {
            case 'email':
                if (!isEmail(fval)) {
                    errors[field.key] = validateMsg.email;
                }
                break;
            case 'phone':
                if (fval.length < 9 && !isInt(fval.replace(/ /g, '').replace('+', ''))) {
                    errors[field.key] = validateMsg.phone;
                }
                break;
            case 'int':
                if (!Number.isInteger(parseInt(fval)) || String(parseInt(fval)) !== fval) {
                    errors[field.key] = validateMsg.int
                }
                break;
            case 'number':
                if (!Number.isFinite(parseFloat(fval))) {
                    errors[field.key] = validateMsg.number
                }
                break;
            case 'openingHours':
                const soh = new SimpleOpeningHours(fval);
                if (Object.values(soh.getTable()).reduce((acc, v) => acc + v.length, 0) === 0) {
                    errors[field.key] = validateMsg.openingHours;
                }
                break;
            default:
                break;
        }
    }
    if (
        field.children &&
        (field.type === 'checkbox' ||
            (!field.showChildrenCondition || field.showChildrenCondition === fval))
    ) {
        field.children.forEach(f => {
            if (field.type !== 'checkbox' || (field.type === 'checkbox' && fval)) {
                const childErrors = validateField(form, f);
                errors = { ...errors, ...childErrors };
            }
        });
    }
    return errors;
}
