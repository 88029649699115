import React, { memo } from 'react';
import cn from 'classnames';
import {
    BarChart,
    Bar,
    Cell,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';

import { primary } from '@/theme';
import './histogram.scss';

const Histogram = ({ data, xKey, bar, bars, className }) => {
    const barDefs = bar ? [bar] : bars || [];
    return (
        <div className={cn('histogram', className)}>
            <ResponsiveContainer height="100%" width="100%">
                <BarChart data={data} margin={{ top: 5, right: -32, bottom: 5, left: -24 }} barGap={10}>
                    <XAxis dataKey={xKey} />
                    <YAxis yAxisId="a" />
                    <YAxis yAxisId="b" orientation="right" />
                    <Tooltip wrapperStyle={{zIndex: 5000}} />
                    <CartesianGrid vertical={false} />
                    {barDefs.map(bar => (
                        <Bar yAxisId="a" dataKey={bar.key} name={bar.name} key={bar.key} stackId="x">
                            {/*<LabelList fill="#000" angle={-45} position="bottom" />*/}
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={bar.color || primary['400']} />
                            ))}
                        </Bar>
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default memo(Histogram);
