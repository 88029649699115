import './simple-select.scss';
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';

export default class SimpleSelect extends PureComponent  {

  static propTypes = {
    options : PropTypes.array,
    value : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    outlined : PropTypes.bool,
    onChange : PropTypes.func.isRequired,
    placeholder : PropTypes.string
  }

  static defaultProps = {
    options: [],
    outlined: false,
    placeholder : 'elige'
  }

  negateClick = e => {
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    const {options, value, outlined, onChange, ...selectProps} = this.props;

    // change some mui stuff
    selectProps.MenuProps = {
      classes: {
        paper: cn('ch-simple-select-list', !!_.find(options, o => !!o.subheader) && 'with-subheaders')
      }
    };
    selectProps.inputProps = {
      className : 'ch-simple-select-input'
    }
    if (outlined) {
      selectProps.input = <OutlinedInput labelWidth={0} />;
      selectProps.classes = Object.assign({}, selectProps.classes || {}, {icon: 'ch-simple-select-dd-icon'})
    }

    // build menu items
    let menuItems = [];
    let injectSubheader = false;
    options.forEach( (o, i) => {
      if (!!o.subheader) {
        injectSubheader = o;
      } else if (injectSubheader) {
        menuItems.push(
          <MenuItem
            key={`o-${i}`}
            className={cn('ch-simple-select-menu-item with-subheader', i === 1 && 'is-first')}
            value={o.value}>
            <ListSubheader component="div" className="ch-simple-select-subheader">{injectSubheader.label}</ListSubheader>
            {o.label}
          </MenuItem>
        );
        injectSubheader = false;
      } else {
        menuItems.push(
          <MenuItem
            key={`o-${i}`}
            className="ch-simple-select-menu-item"
            value={o.value}>
            {o.label}
          </MenuItem>
        );
      }
    });

    return (
      <Select
        value={value || ""}
        onChange={ev => onChange(ev.target.value)}
        {...selectProps}>
        {menuItems}
      </Select>
    );
  }
}
