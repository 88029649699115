import React, { useState, Fragment } from 'react';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CircularProgress from '@material-ui/core/CircularProgress';

import Form from './Form';

const FormDialog = ({
    title,
    open,
    initState,
    config,
    asyncOptions,
    loading,
    fullScreen,
    onSubmit,
    onDelete,
    showDeleteConfirm,
    onDeleteConfirm,
    onDeleteCancel,
    onOpen,
    onChange,
    onClose,
    header,
}) => {
    const [dialogUploading, setDialogUploading] = useState(false);
    let formStore;
    const registerFormStore = f => {
        formStore = f;
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onEnter={onOpen}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
            classes={{ container: 'klara-dialog-container' }}
        >
            {(loading || dialogUploading) && (
                <div className="dialog-loading-container">
                    <div className="loading-elem">
                        <CircularProgress color="primary" />
                    </div>
                </div>
            )}

            <DialogTitle id="responsive-dialog-title">
                <span>{!showDeleteConfirm ? title : 'Borrar'}</span>
                <IconButton
                    aria-label="Close"
                    className="dialog-corner-close-btn"
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent className="klara-dialog-form">
                {open && (
                    <Fragment>
                        {!!header && header}
                        {!showDeleteConfirm && (
                            <Form
                                {...{
                                    config,
                                    initState,
                                    asyncOptions,
                                    onChange,
                                    registerFormStore,
                                    setDialogUploading,
                                    showSubmit: false,
                                }}
                            />
                        )}
                        {showDeleteConfirm&& <p>Seguro que quieres borrar?? Porfa, confirma tu acción:</p>}
                    </Fragment>
                )}
            </DialogContent>

            {!showDeleteConfirm && (
                <DialogActions>
                    {!!onDelete && (
                        <Fragment>
                            <Button onClick={onDelete} color="secondary">
                                Borrar
                            </Button>
                            <div style={{ flex: '1 0 0' }} />
                        </Fragment>
                    )}
                    <Button onClick={onClose} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => formStore.onSubmit(onSubmit)}
                        color="primary"
                        variant="contained"
                        autoFocus
                    >
                        Guardar
                    </Button>
                </DialogActions>
            )}
            {showDeleteConfirm && (
                <DialogActions>
                    <Button onClick={onDeleteCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={onDeleteConfirm}
                        color="primary"
                        variant="contained"
                        autoFocus
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default withMobileDialog()(observer(FormDialog));
