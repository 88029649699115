const DatosContacto = {
    submitLabel: 'Seguir',
    showSubmitOnCondition: form => !!(form.tipoInstitucion || form.tipoDeLocal),
    sections: [
        {
            title: 'Datos del local',
            fields: [
                {
                    key: 'tipoDeHost',
                    label: 'Tipo de empresa o institución',
                    type: 'radio',
                    required: true,
                    labelPlacement: 'end',
                    options: [
                        { value: 'pyme', label: 'Pyme' },
                        { value: 'empresa-institucion', label: 'Institución o Empresa' },
                    ],
                },
                {
                    key: 'tipoInstitucion',
                    label: 'Especifica:',
                    type: 'select',
                    required: true,
                    showOnCondition: form => form.tipoDeHost === 'empresa-institucion',
                    options: [
                        { value: 'inst-educativa', label: 'Institución educativa' },
                        { value: 'centro-comercial', label: 'Centro comercial' },
                        { value: 'local-comercial', label: 'Local comercial' },
                        { value: 'Empresa', label: 'Empresa' },
                        { value: 'otros', label: 'Otros' },
                    ],
                },
                {
                    key: 'tipoDeLocal',
                    label: 'Tipo de establecimiento',
                    type: 'select',
                    required: true,
                    showOnCondition: form => form.tipoDeHost === 'pyme',
                    options: [
                        { value: 'bodega', label: 'Bodega' },
                        { value: 'restaurante', label: 'Restaurante' },
                        { value: 'jugueria', label: 'Juguería' },
                        { value: 'cafe', label: 'Café' },
                        { value: 'puestoMercado', label: 'Puesto de mercado o galería comercial' },
                        { value: 'otros', label: 'Otros' },
                    ],
                    showChildrenCondition: 'otros',
                    children: [
                        {
                            key: 'tipoDeLocalOtros',
                            type: 'textfield',
                            label: 'Especificar',
                            required: true,
                        },
                    ],
                },
                {
                    key: 'razonSocial',
                    label: 'Razón social (nombre del local)',
                    type: 'textfield',
                    required: true,
                    showOnCondition: form => !!form.tipoDeHost,
                },
                {
                    key: 'address',
                    label: 'Dirección del local (incluir número!)',
                    type: 'addressfield',
                    required: true,
                    showOnCondition: form => !!form.tipoDeHost,
                },
            ],
        },
        {
            title: 'Datos de contacto',
            showOnCondition: form => !!(form.tipoInstitucion || form.tipoDeLocal) && !!form.address,
            fields: [
                {
                    key: 'nombre',
                    label: 'Nombre completo',
                    type: 'textfield',
                    required: true,
                },
                {
                    key: 'mail',
                    label: 'Dirección de email',
                    type: 'textfield',
                    required: true,
                    validateType: 'email',
                },
                {
                    key: 'phone',
                    label: 'Número de celular',
                    type: 'textfield',
                    required: true,
                    validateType: 'phone',
                },
                {
                    key: 'position',
                    label: 'Posición en institución / empresa (si aplica)',
                    type: 'textfield',
                },
                /*
         {
           key: 'comentario',
           label: 'Un comentario: cuentanos de tu caso (opcional)',
           type: 'textfield',
           props: {
             multiline: true
           }
         }*/
            ],
        },
    ],
};

export default DatosContacto;
