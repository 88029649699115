import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';

import './value-row.scss';

const ShowValues = ({values, align}) => {
    return (
        <div className={cn('stat-row', `stat-row-${values.length}`, align)}>
          {values.map( (v, i) =>
            <div className="stat-item" key={i}>
              <div className="stat-label">{v.label}</div>
              <div className="stat-value">{v.value}</div>
            </div>
          )}
        </div>
    )};

export default observer(ShowValues);
