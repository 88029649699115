import React from 'react';
import { observer } from 'mobx-react';
import Alert from '@material-ui/lab/Alert';

import SimpleDialog from '@/components/SimpleDialog';
import SSHTunnel from './SshTunnel';
import Reboot from './Reboot';
import SwitchEnv from './SwitchEnv';
import './comandos-tab.scss';


const ComandosTab = ({ tabStore }) => {
    return (
        <div className="comandos-tab" style={{ paddingTop: 20 }}>
            <h2>Comandos</h2>
            {!!tabStore.alert && (
                <Alert severity={tabStore.alert.severity} style={{ marginBottom: 40 }}>
                    {tabStore.alert.msg}
                </Alert>
            )}

            <div className="comandos">
                <SSHTunnel tabStore={tabStore} />
                <Reboot tabStore={tabStore} />
                <SwitchEnv tabStore={tabStore} />
            </div>

            <SimpleDialog
                open={tabStore.openConfirm}
                actions={[
                    { label: 'Cancelar', onClick: tabStore.onConfirmCancel, secondary: true },
                    { label: 'Seguir', onClick: tabStore.onConfirm },
                ]}
            >
                {tabStore.confirmMsg}
            </SimpleDialog>
        </div>
    );
};

export default observer(ComandosTab);
