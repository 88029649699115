import React, { Fragment, useEffect } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { omit, get } from 'lodash';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';

import CheckboxField from '@/components/CheckboxField';
import AddressField from '@/components/AddressField';
import ImageField from '@/components/ImageField';
import AutoChipField from '@/components/AutoChipField';
import RadioField from '@/components/RadioField';
import MultiCheckboxField from '@/components/MultiCheckboxField';
import SliderField from '@/components/SliderField';
import PeriodPicker from '@/components/PeriodPicker';

const FormField = ({ store, field, asyncOptions }) => {
    const { form, setField, errors } = store;

    useEffect(() => {
        if (typeof form[field.key] === 'undefined' && field.default) {
            setField(field.key, field.default);
        }
    }, [field, form, setField]);

    if (field.showOnCondition) {
        if (!field.showOnCondition(form)) return null;
    }

    let extraProps = {};
    if (field.props) {
        extraProps = field.props;
    } else if (field.getProps) {
        extraProps = field.getProps(field, form, store);
    }

    if (errors[field.key] && extraProps && extraProps.helperText) {
        extraProps = omit(extraProps, ['helperText']);
    }

    switch (field.type) {
        case 'subgroup':
            return (
                <div className="form-subgroup" {...extraProps}>
                    <div className="form-subgroup-title">{field.title}</div>
                    <div className="form-subgroup-content">
                        {field.children.map((f, i) => (
                            <FormField key={i} {...{ store, asyncOptions, field: f }} />
                        ))}
                    </div>
                </div>
            );

        case 'checkbox':
            return (
                <Fragment>
                    <CheckboxField
                        className={cn('form-item checkbox-field', field.className)}
                        value={get(form, field.key, false)}
                        label={field.label}
                        error={errors[field.key]}
                        description={field.description}
                        onChange={val => setField(field.key, val)}
                        {...extraProps}
                    />
                    {!!field.children && form[field.key] && (
                        <div className="form-item-expand-content" key={field.key + '-expand'}>
                            {field.children.map((f, i) => (
                                <FormField key={i} {...{ store, asyncOptions, field: f }} />
                            ))}
                        </div>
                    )}
                </Fragment>
            );

        case 'textfield':
            return (
                <TextField
                    value={get(form, field.key, '')}
                    label={field.label}
                    className={cn('form-item', field.className)}
                    error={Boolean(errors[field.key])}
                    helperText={errors[field.key] || field.description}
                    fullWidth
                    onChange={ev => setField(field.key, ev.target.value)}
                    {...extraProps}
                />
            );

        case 'select':
            const val = (asyncOptions || field.options || [])
                .map(i => i.value || i)
                .includes(get(form, field.key))
                ? get(form, field.key)
                : '';
            return (
                <Fragment>
                    <TextField
                        className={cn('form-item', field.className)}
                        value={val}
                        label={field.label}
                        onChange={ev => setField(field.key, ev.target.value)}
                        select
                        error={Boolean(errors[field.key])}
                        helperText={errors[field.key] || field.description}
                        {...extraProps}
                    >
                        {(asyncOptions || field.options).map((opt, i) => (
                            <MenuItem value={opt.value || opt} key={`opt-${i}`}>
                                {opt.label || opt}
                            </MenuItem>
                        ))}
                    </TextField>
                    {!!field.children &&
                        (!field.showChildrenCondition ||
                            form[field.key] === field.showChildrenCondition) && (
                            <div className="form-item-expand-content" key={field.key + '-expand'}>
                                {field.children.map((f, i) => (
                                    <FormField key={i} {...{ store, asyncOptions, field: f }} />
                                ))}
                            </div>
                        )}
                </Fragment>
            );

        case 'addressfield':
            return (
                <AddressField
                    value={get(form, field.key)}
                    className={cn('form-item address-field', field.className)}
                    label={field.label}
                    onChange={val => setField(field.key, val)}
                    error={Boolean(errors[field.key])}
                    helperText={errors[field.key] || field.description}
                    {...extraProps}
                />
            );

        case 'imagefield':
            return (
                <ImageField
                    className={field.className}
                    label={field.label}
                    description={field.description}
                    onChange={val => setField(field.key, val)}
                    error={Boolean(errors[field.key])}
                    helperText={errors[field.key]}
                    images={get(form, field.key)}
                    onUploading={val => store.setFilesUploading(field.key, val)}
                    {...extraProps}
                />
            );

        case 'multiselect':
            return (
                <Fragment>
                    <AutoChipField
                        className={cn('form-item', field.className)}
                        placeholder={field.label}
                        value={get(form, field.key, [])}
                        onChange={val => setField(field.key, val)}
                        options={asyncOptions || field.options}
                        error={Boolean(errors[field.key])}
                        helperText={errors[field.key] || field.description}
                        {...extraProps}
                    />
                    {!!field.children &&
                        (!field.showChildrenCondition ||
                            [...(form[field.key] || [])]
                                .map(o => o.value)
                                .includes(field.showChildrenCondition.value)) && (
                            <div
                                className="form-item-expand-content multiselect-expand"
                                key={field.key + '-expand'}
                            >
                                {field.children.map((f, i) => (
                                    <FormField key={i} {...{ store, asyncOptions, field: f }} />
                                ))}
                            </div>
                        )}
                </Fragment>
            );
        case 'radio':
            return (
                <RadioField
                    value={get(form, field.key, '')}
                    className={field.className}
                    label={field.label}
                    options={asyncOptions || field.options}
                    description={field.description}
                    onChange={val => setField(field.key, val)}
                    error={Boolean(errors[field.key])}
                    helperText={errors[field.key]}
                    labelPlacement={field.labelPlacement}
                    {...extraProps}
                />
            );
        case 'multicheckbox':
            return (
                <Fragment>
                    <MultiCheckboxField
                        value={get(form, field.key, [])}
                        className={field.className}
                        label={field.label}
                        options={asyncOptions || field.options}
                        description={field.description}
                        onChange={val => setField(field.key, val)}
                        error={Boolean(errors[field.key])}
                        helperText={errors[field.key]}
                        labelPlacement={field.labelPlacement}
                        {...extraProps}
                    />
                    {!!field.children &&
                        (!field.showChildrenCondition ||
                            [...(form[field.key] || [])].includes(
                                field.showChildrenCondition.value
                            )) && (
                            <div
                                className="form-item-expand-content multicheckbox-expand"
                                key={field.key + '-expand'}
                            >
                                {field.children.map((f, i) => (
                                    <FormField key={i} {...{ store, asyncOptions, field: f }} />
                                ))}
                            </div>
                        )}
                </Fragment>
            );

        case 'date':
            return (
                <DatePicker
                    label={field.label}
                    className={cn('form-item', field.className)}
                    value={get(form, field.key) ? moment(get(form, field.key)) : null}
                    clearable
                    onChange={val => setField(field.key, val.toDate())}
                    error={Boolean(errors[field.key])}
                    helperText={errors[field.key] || field.description}
                    {...extraProps}
                />
            );

        case 'datetime':
            return (
                <DateTimePicker
                    label={field.label}
                    className={cn('form-item', field.className)}
                    value={get(form, field.key) ? moment(get(form, field.key)) : null}
                    clearable
                    onChange={val => setField(field.key, val.toDate())}
                    error={Boolean(errors[field.key])}
                    helperText={errors[field.key] || field.description}
                    {...extraProps}
                />
            );
        case 'period':
            return (
                <PeriodPicker
                    label={field.label}
                    value={get(form, field.key)}
                    onChange={val => setField(field.key, val)}
                    className={cn('form-item', field.className)}
                    error={Boolean(errors[field.key])}
                    helperText={errors[field.key] || field.description}
                    {...extraProps}
                />
            );
        case 'slider':
            return (
                <SliderField
                    value={get(form, field.key, 0)}
                    className={field.className}
                    label={field.label}
                    valueLabelDisplay="auto"
                    onChange={val => setField(field.key, val)}
                    error={Boolean(errors[field.key])}
                    helperText={errors[field.key] || field.description}
                    {...extraProps}
                />
            );
        default:
            return null;
    }
};

export default observer(FormField);
