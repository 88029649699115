/*
const TRANSACCION = {
    id: ID,
    from: ID,
    fromType: ['usuario', 'rfid', 'fuente', 'klara-backend', 'host'],
    to: ID,
    toType: ['user', 'rfid', 'host', 'location'],
    via: ['qr', 'nfc', 'online', 'cash'],
    type: ['transferencia', 'recarga', 'hostCompra', 'hostVenta'],
    status: ['pendiente', 'esperandoDestino', 'cancelada', 'completa'],
    conversion: Number, // coeficiente cents -> ml
    cents: Number,
    mlProm: Number,
    ml: Number,
    timestamp: Date,
};
*/

const FormConfig = {
    sections: [
        {
            fields: [
                {
                    key: 'isProm',
                    label: 'es promoción?',
                    type: 'checkbox',
                },
                {
                    key: 'litrosProm',
                    label: 'Litros Promoción',
                    type: 'textfield',
                    validateType : 'number',
                    required: true,
                    showOnCondition: form => form.isProm,
                },
                {
                    key: 'soles',
                    label: 'Soles',
                    type: 'textfield',
                    validateType : 'number',
                    required: true,
                    showOnCondition: form => !form.isProm,
                },
                {
                    key: 'formaDePago',
                    label: 'Metodo de pago',
                    type: 'select',
                    options: [
                          { value: 'cash', label: 'Cash'},
                          { value: 'yape', label: 'Yape'},
                          { value: 'tranferencia', label: 'Transferencia'},
                          { value: 'online', label: 'Online (apps, web)'}
                    ],
                    required: true,
                    showOnCondition: form => !form.isProm,
                },
                {
                    key: 'percentForHost',
                    label: 'Porcentaje de ganancia del host (%)',
                    type: 'textfield',
                    validateType: 'number',
                    required: true,
                    default: 30,
                    showOnCondition: form => !form.isProm,
                },
            ],
        },
    ],
};

export default FormConfig;
