import React from 'react';
import cn from 'classnames';
import Slider from '@material-ui/core/Slider';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const SliderField = ({ value, onChange, sliderProps, label, className, error, helperText }) => {
    return (
        <FormControl
            component="fieldset"
            error={!!error}
            className={cn('slider-field form-item', className)}
        >
            <FormLabel component="legend" className="slider-field-label">
                {label}
            </FormLabel>
            <Slider
                value={value}
                valueLabelDisplay="auto"
                onChange={(e, val) => onChange(val)}
                {...sliderProps}
            />
            {!!error && <FormHelperText className="slider-error-msg">{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default SliderField;
