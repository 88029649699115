import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { toJS } from 'mobx';
import { DatePicker } from '@material-ui/pickers';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { isEqual } from 'lodash';

import './period-picker.scss';

const parseFbDates = value => {
    if (!value) return [{}];
    value.forEach((item, i) => {
        if (item.start && item.start.toDate) {
            item.start = item.start.toDate();
        }
        if (item.end && item.end.toDate) {
            item.end = item.end.toDate();
        }
    });
    return value;
};

const PeriodPicker = ({ value, onChange, className, label, error, helperText }) => {
    const [periods, setPeriods] = useState([{}]);

    const setPeriodItem = (field, idx, date) => {
        periods[idx][field] = date;
        setPeriods(periods);
        const data = periods.filter(p => !!p.start);
        onChange && onChange(data.length ? data : undefined);
    };

    const addPeriod = () => {
        setPeriods([...periods, {}]);
    };

    useEffect(() => {
        let endItems = [];
        let inputItems = parseFbDates(value || []);
        if (periods.length - inputItems.length > 0) {
            endItems = periods.slice(periods.length - inputItems.length);
        }
        const newPeriods = [...inputItems, ...endItems];
        if (!isEqual(toJS(newPeriods), toJS(periods))) {
            setPeriods(newPeriods);
        }
    }, [value, periods]);

    return (
        <div className={cn('periodpicker', className)}>
            <div className="periodpicker-label field-label">{label}</div>
            <div className="periodpicker-items">
                {periods.map((period, i) => (
                    <div key={i} className="periodpicker-pair">
                        <DatePicker
                            className="periodpicker-date-picker period-pair-start"
                            value={period.start || null}
                            onChange={date => setPeriodItem('start', i, date)}
                            label="Desde"
                            clearable={true}
                            disableFuture={true}
                            format="DD/MM/yyyy"
                        />
                        <DatePicker
                            className="periodpicker-date-picker period-pair-end"
                            value={period.end || null}
                            onChange={date => setPeriodItem('end', i, date)}
                            label="Hasta (opcional)"
                            clearable={true}
                            disableFuture={true}
                            format="DD/MM/yyyy"
                        />
                    </div>
                ))}
            </div>
            <div className="periodpicker-add-pair">
                <IconButton onClick={addPeriod} size="small">
                    <AddIcon />
                </IconButton>
            </div>
            {!!helperText && <FormHelperText error={!!error}>{helperText}</FormHelperText>}
        </div>
    );
};

export default PeriodPicker;
