import React from 'react';
import { observer } from 'mobx-react';
import Alert from '@material-ui/lab/Alert';

import './home-tab.scss';

const FuenteHomeTab = ({ tabStore, goTo }) => {
    const { fuente } = tabStore.main;
    return (
        <div className="fuente-home-tab">
            {!!tabStore.alert && (
                <Alert severity={tabStore.alert.severity} style={{ marginBottom: 40 }}>
                    {tabStore.alert.msg}
                </Alert>
            )}
            <div className="info-item inline">
                <div className="info-label">Tarjeta:</div>
                <div className="info-value">{fuente.board}</div>
            </div>
            <div className="info-item inline">
                <div className="info-label">Modelo:</div>
                <div className="info-value">{fuente.modelo || 'cajaAzul'}</div>
            </div>
            <div className="info-item sim inline">
                <div className="info-label">SIM (iccid):</div>
                <div className="info-value">{fuente.simId}</div>
            </div>
            {!!fuente.statusMsg && (
                <div className="info-item status-msg inline">
                    <div className="info-label">Status message:</div>
                    <div className="info-value">{fuente.statusMsg}</div>
                </div>
            )}
        </div>
    );
};

export default observer(FuenteHomeTab);
