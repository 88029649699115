import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import TimerIcon from '@material-ui/icons/Timer';
import Paper from '@material-ui/core/Paper';

import CopyField from '@/components/CopyField';
import './ssh-tunnel.scss';

const SSHTunnel = ({ tabStore }) => {
    return (
        <Paper className="ssh-tunnel comando">
            <h3>SSH Tunnel</h3>
            <div className="comando-content">
            {(!tabStore.tunnel || !tabStore.tunnel.active) && (
                <Fragment>
                    <div className="conectar" style={{ margin: '20px 0' }}>
                        <Button
                            endIcon={tabStore.sshLoading ? <TimerIcon /> : null}
                            onClick={tabStore.openFuenteTunnel}
                            variant="contained"
                            color="primary"
                            disabled={tabStore.sshLoading}
                        >
                            Abrir tunnel SSH
                        </Button>
                    </div>
                    <div className="resetear-tunnel-container">
                            <Button
                                size="small"
                                onClick={tabStore.resetearTunnel}
                                disabled={tabStore.sshLoading}
                            >
                                Resetear
                            </Button>
                        </div>
                    </Fragment>
                )}
                {!!tabStore.tunnel && tabStore.tunnel.active && (
                    <div className="conectar">
                        <p>Desde el servidor de klara (klara@server.klara.pe):</p>
                        <div className="copy-field-wrap">
                            <CopyField content={`ssh -p ${tabStore.tunnel.port} pi@localhost`} />
                        </div>
                        <p>Ojo: a veces hay que esperar un poquito a que se establezca la conección</p>
                        <div style={{ margin: '20px 0' }}>
                            <Button
                                endIcon={tabStore.sshLoading ? <TimerIcon /> : null}
                                onClick={tabStore.closeFuenteTunnel}
                                variant="contained"
                                color="primary"
                                disabled={tabStore.sshLoading}
                            >
                                Cerrar tunnel SSH
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Paper>
    );
};

export default observer(SSHTunnel);
