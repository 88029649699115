import { observable, action, toJS, when } from 'mobx';
import moment from 'moment';
import { Parser as Json2csvParser } from 'json2csv';
import { saveAs } from 'file-saver';

import AnalysisStore from './AnalysisTab/store';
import HomeStore from './HomeTab/store';
import CashupsStore from './CashupsTab/store';
import RecargasStore from './RecargasTab/store';
import ServidasStore from './ServidasTab/store';
import AboutStore from './AboutTab/store';
import { LocationFormDialogStore } from '@/components/LocationFormDialog';
import { db, COLS } from 'klara-common';

export default class FuenteView {
    @observable location = {};
    @observable fuente = {};
    @observable servsXDia = [];
    @observable servsXMes = [];
    @observable onlineStates = [];
    @observable startDate = moment().startOf('month');
    @observable endDate = null;
    @observable advancedPickers = false;
    @observable tab = 'main';
    @observable.ref tabStore = null;
    lastFuenteId = null;

    constructor(parent, fuenteId) {
        this.rootStore = parent;
        //this.locationId = parent.routerStore
        this.locationFormDialogStore = new LocationFormDialogStore();
    }

    initDBListeners() {
        this.startLocationListener();
        this.startServXDiaListener();
        this.startServXMesListener();
        when(
            () => !!this.location && !!this.location.fuenteId,
            () => {
                this.startOnlineStatusListener();
            }
        );
    }

    stopMainListeners() {
        this.locationListenerUnsub && this.locationListenerUnsub();
        this.fuenteListenerUnsub && this.fuenteListenerUnsub();
    }

    restartDataListeners() {
        this.stopDataListeners();
        this.startOnlineStatusListener();
        this.startServXDiaListener();
        this.startServXMesListener();
        this.tabStore && this.tabStore.restartListeners && this.tabStore.restartListeners();
    }

    stopDataListeners() {
        this.onlineStatusUnsub && this.onlineStatusUnsub();
        this.servsXDiaUnsub && this.servsXDiaUnsub();
        this.servsXMesUnsub && this.servsXMesUnsub();
    }

    stopAllDBListeners() {
        this.stopMainListeners();
        this.stopDataListeners();
    }

    /**
     * LOCATION
     */
    startLocationListener() {
        this.rootStore.setLoading(true);
        this.locationListenerUnsub = db
            .collection(COLS.LOCATION)
            .doc(this.locationId)
            .onSnapshot(this.updateLocationSnapshot, this.onSnapshotError);
    }

    @action updateLocationSnapshot = snapshot => {
        this.rootStore.setLoading(false);
        if (!snapshot.exists) {
            this.rootStore.routerStore.goTo('notFound');
            return;
        }
        this.location = snapshot.data();
        if (this.location.fuenteId !== this.lastFuenteId) {
            this.restartFuenteListener();
            this.lastFuenteId = this.location.fuenteId;
        }
    };

    /**
     * FUENTE
     */
    startFuenteListener() {
        this.fuenteListenerUnsub = db
            .collection(COLS.FUENTE)
            .doc(this.location.fuenteId)
            .onSnapshot(this.updateFuenteSnapshot, this.onSnapshotError);
    }

    @action updateFuenteSnapshot = snapshot => {
        this.fuente = snapshot.exists ? snapshot.data() : {};
    };

    restartFuenteListener() {
        this.fuenteListenerUnsub && this.fuenteListenerUnsub();
        this.startFuenteListener();
    }

    /**
     * AGREGADOS
     */
    startServXDiaListener() {
        let xDiaRef = db
            .collection(COLS.LOCATION)
            .doc(this.locationId)
            .collection('servidasXDia');
        if (this.startDate !== null) {
            xDiaRef = xDiaRef.where('timestamp', '>=', this.startDate.toDate());
        }
        if (this.endDate !== null) {
            xDiaRef = xDiaRef.where('timestamp', '<=', this.endDate.endOf('day').toDate());
        }
        xDiaRef = xDiaRef.orderBy('timestamp', 'asc');
        if (!this.endDate || this.endDate.isAfter()) {
            this.servsXDiaUnsub = xDiaRef.onSnapshot(
                this.updateServsXDiaSnapshot,
                this.onSnapshotError
            );
        } else {
            xDiaRef.get().then(this.updateServsXDiaSnapshot);
        }
    }

    @action updateServsXDiaSnapshot = changes => {
        this.servsXDia = changes.docs.map(d => d.data());
    };

    startServXMesListener() {
        let xMesRef = db
            .collection(COLS.LOCATION)
            .doc(this.locationId)
            .collection('servidasXMes');
        if (this.startDate !== null) {
            xMesRef = xMesRef.where('timestamp', '>=', moment(this.startDate).startOf('month').toDate());
        }
        if (this.endDate !== null) {
            xMesRef = xMesRef.where('timestamp', '<=', moment(this.endDate).endOf('month').toDate());
        }
        xMesRef = xMesRef.orderBy('timestamp', 'asc');
        if (!this.endDate || this.endDate.isAfter()) {
            this.servsXMesUnsub = xMesRef.onSnapshot(
                this.updateServsXMesSnapshot,
                this.onSnapshotError
            );
        } else {
            xMesRef.get().then(this.updateServsXMesSnapshot);
        }
    }

    @action updateServsXMesSnapshot = changes => {
        this.servsXMes = changes.docs.map(m => m.data());
    };

    onSnapshotError = e => console.log(e);

    /**
     * ONLINE STATUS
     */
    startOnlineStatusListener() {
        if (!this.location || !this.location.fuenteId) return;
        let onlineStatusRef = db
            .collection(COLS.ONLINE_STATE)
            .where('fuenteId', '==', this.location.fuenteId);
        if (this.startDate !== null) {
            onlineStatusRef = onlineStatusRef.where('timestamp', '>=', this.startDate.toDate());
        }
        if (this.endDate !== null) {
            onlineStatusRef = onlineStatusRef.where('timestamp', '<=', this.endDate.toDate());
        }
        onlineStatusRef = onlineStatusRef.orderBy('timestamp', 'asc');
        if (!this.endDate || this.endDate.isAfter()) {
            this.onlineStatusUnsub = onlineStatusRef.onSnapshot(this.updateOnlineStateSnapshot);
        } else {
            onlineStatusRef.get().then(this.updateOnlineStateSnapshot);
        }
    }

    @action updateOnlineStateSnapshot = snapshot => {
        this.onlineStates = snapshot.docs.map(d => d.data());
    };

    /**
     * UI ACTIONS
     */
    @action setStartDate = date => {
        this.startDate = moment(date).startOf('day');
        this.restartDataListeners();
    };

    @action setEndDate = date => {
        this.endDate = date ? moment(date).endOf('day') : null;
        this.restartDataListeners();
    };

    @action setSimpleDate = date => {
        this.startDate = date;
        this.endDate = moment(date).endOf('month');
        this.restartDataListeners();
    };

    @action toggleAdvancedPickers = ev => {
        this.advancedPickers = !this.advancedPickers;
    };

    @action goToTab = tab => {
        this.rootStore.routerStore.goTo('location', { id: this.locationId, tab });
    };

    @action openEditForm = () => {
        this.locationFormDialogStore.openEditForm(this.locationId, toJS(this.location));
    };

    downloadCsv = () => {
        let query = db
            .collection(COLS.SERVIDA)
            .where('locationId', '==', this.locationId)
            .where('timestamp', '>=', this.startDate.toDate());
        if (this.endDate) {
            query = query.where('timestamp', '<=', this.endDate.toDate());
        }
        query
            .get()
            .then(snap => {
                const data = snap.docs
                    .map(s => s.data())
                    .map(s => ({
                        centavos: s.cents,
                        mililitros: s.ml,
                        mlPaid: s.mlPaid || '',
                        mlProm: s.mlProm || '',
                        metodo: s.metodo,
                        rfid: s.rfid || '',
                        userId: s.userId || '',
                        fuenteId: s.fuenteId,
                        locationId: s.locationId,
                        timestamp: moment(s.timestamp.toDate()).toISOString(),
                    }));
                const parser = new Json2csvParser();
                const csv = parser.parse(data);
                var blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
                saveAs(blob, 'servidas.csv');
            })
            .catch(err => console.log('error', err));
    };

    /**
     * ROUTER ACTIONS
     */
    @action onSubpathEnter = routerState => {
        this.tab = routerState.params.tab || 'main';
        switch (this.tab) {
            case 'analysis':
                this.tabStore = new AnalysisStore(this);
                break;
            case 'cashups':
                this.tabStore = new CashupsStore(this);
                break;
            case 'recargas':
                this.tabStore = new RecargasStore(this);
                break;
            case 'servidas':
                this.tabStore = new ServidasStore(this);
                break;
            case 'about':
                this.tabStore = new AboutStore(this);
                break;
            default:
                this.tabStore = new HomeStore(this);
        }
    };

    @action onSubpathExit = () => {
        this.tabStore && this.tabStore.onExit && this.tabStore.onExit();
        this.tabStore = null;
    };

    @action onEnter = routerState => {
        this.locationId = routerState.params.id;
        this.onSubpathEnter(routerState);
        this.initDBListeners();
    };

    @action onExit = () => {
        this.stopAllDBListeners();
    };
}
