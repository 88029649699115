import React from 'react';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { ENV } from 'klara-common';

const toEnv = ENV === 'PROD' ? 'STAGE' : 'PROD';

const SwitchEnv = ({ tabStore, goTo }) => (
    <Paper className="comando switch-env">
        <h3>Cambiar de entorno</h3>
        <div className="comando-content">
            <Button variant="contained" color="primary" onClick={tabStore.confirmSwitchEnv}>
                Cambiar a entorno {toEnv}
            </Button>
        </div>
    </Paper>
);

export default observer(SwitchEnv);
