import React from 'react';
import { observer } from 'mobx-react';

import { FormDialog } from '@/components/Form';
import formConf from './formConfig';

const FuenteFormDialog = ({ store }) =>
    store ? (
        <FormDialog
            title={!store.editId ? 'Nueva Fuente' : 'Editar Fuente'}
            open={store.open}
            loading={store.loading}
            onClose={() => store.setDialogOpen(false)}
            showDeleteConfirm={store.showDeleteConfirm}
            onDelete={store.editId ? store.onDelete : undefined}
            onDeleteConfirm={store.onDeleteConfirm}
            onDeleteCancel={store.onDeleteCancel}
            onSubmit={store.onSubmit}
            config={formConf}
            initState={store.editInitState}
        />
    ) : null;

export default observer(FuenteFormDialog);
