import React, { Fragment, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import { DateRange, DefinedRange } from 'react-date-range';
import { es } from 'react-date-range/dist/locale';
import Button from '@material-ui/core/Button';
import {
    endOfToday,
    startOfDay,
    formatDistanceStrict,
    format,
    isFuture,
} from 'date-fns';
import esDateFns from 'date-fns/locale/es';

import { defaultStaticRanges, defaultInputRanges } from './config';
import './date-range-field.scss';

const formatRangeText = range => {
    if (!range.endDate || isFuture(range.endDate)) {
        const result = formatDistanceStrict(startOfDay(range.startDate), endOfToday(), {
            unit: 'day',
            roundMethod: 'ceil',
            locale: esDateFns
        });
        if (result === '1 day') {
            return 'Hoy';
        } else {
            return 'Últimos ' + result;
        }
    } else {
        return format(range.startDate, 'd MMM yyyy', { locale: esDateFns }) + ' - ' + format(range.endDate, 'd MMM yyyy', { locale: esDateFns });
    }
};

const DateRangeField = ({ startDate, endDate, onChange, ...props }) => {
    const [state, setState] = useState({ startDate, endDate, key: 'range' });
    const [valueTxt, setValueTxt] = useState(formatRangeText({ startDate, endDate }));
    const [anchorEl, setAnchorEl] = useState(null);

    const _onChange = ({ range }) => {
        setState(range);
        setValueTxt(formatRangeText(range));
        const { key, ...payload } = range;
        onChange && onChange(payload);
        setAnchorEl(null);
    };

    const _onTmpChange = ({ range }) => {
        setState(range);
    };

    const apply = () => {
        setValueTxt(formatRangeText(state));
        const { key, ...payload } = state;
        onChange && onChange(payload);
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <TextField classes={{ root: 'date-range-field'}} value={valueTxt} {...props} onClick={e => setAnchorEl(e.currentTarget)} />
            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                anchorEl={anchorEl}
            >
                <div className="date-field-cal-popup-content">
                    <DefinedRange
                        onChange={_onChange}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        ranges={[state]}
                    />
                    <div className="date-range-cal-container">
                        <DateRange
                            showSelectionPreview={false}
                            onChange={_onTmpChange}
                            months={1}
                            maxDate={endOfToday()}
                            direction="vertical"
                            scroll={{ enabled: true }}
                            ranges={[state]}
                            locale={es}
                        />
                        <Button
                            style={{ paddingTop: 10 }}
                            onClick={apply}
                            color="primary"
                            fullWidth
                            variant="contained"
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </Popover>
        </Fragment>
    );
};

export default DateRangeField;
