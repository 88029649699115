import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { observable, toJS } from 'mobx';
import generateRandomID from 'uuid/v4';
import { observer } from 'mobx-react';
import FormHelperText from '@material-ui/core/FormHelperText';
import { pick } from 'lodash';

import { firebaseStorage } from 'klara-common';
import resizeImage from './resizeImage';
import DZone from './dzone';
import Thumb from './thumb';

import './image-field.scss';

const randomizeFilename = name => {
    const random = generateRandomID();
    return random + '-' + name.replace(/ /g, '_');
};

@observer
class ImageField extends Component {
    static propTypes = {
        maxWidth: PropTypes.number,
        maxHeight: PropTypes.number,
        images: PropTypes.array,
        storageBasePath: PropTypes.string,
    };

    static defaultProps = {
        maxWidth: 2048,
        maxHeight: 2048,
        storageBasePath: 'images',
    };

    @observable images = [];

    constructor(props, context) {
        super(props, context);
        this.storageRef = firebaseStorage.ref(this.props.storageBasePath);
        this.images.replace(props.images || []);
    }

    findUploadTask = task => {
        for (let i = 0; i < this.images.length; i++) {
            if (this.images[i].task === task) {
                return { index: i, ...this.images[i] };
            }
        }
        return null;
    };

    findImageByUniqName = uniqName => {
        for (let img of this.images) {
            if (img.uniqName === uniqName) {
                return img;
            }
        }
        return null;
    };

    removeUploadTask = task => {
        const { index } = this.findUploadTask(task);
        if (index) {
            const copy = this.images.slice();
            copy.splice(index, 1);
            this.images = copy;
        }
    };

    deleteImage = index => {
        if (this.images[index].resourcePath) {
            this.storageRef
                .child(encodeURIComponent(this.images[index].name))
                .delete()
                .then(() => {
                    const copy = this.images.slice();
                    copy.splice(index, 1);
                    this.images.replace(copy);
                    this.doOnChange();
                });
        }
    };

    onUploading = (val) => {
        this.props.onUploading && this.props.onUploading(val);
    }

    doOnChange = () => {
        this.props.onChange &&
            this.props.onChange(
                toJS(this.images)
                    .filter(img => !!img.resourcePath)
                    .map(img => pick(img, ['resourcePath', 'url', 'name']))
            );
    };

    handleNewFiles = files => {
        const { maxWidth, maxHeight } = this.props;
        this.error = false;
        this.onUploading(true);
        const numFiles = files.length;
        let uploaded = 0;
        const startUpload = files.map(f => ({
            bytesTransferred: 0,
            totalBytes: 0,
            task: null,
            uploading: true,
            file: f,
            progress: 0,
            uniqName: randomizeFilename(f.name),
        }));
        this.images.replace(this.images.slice().concat(startUpload));

        (startUpload || []).forEach((item, i) => {
            resizeImage(item.file, maxWidth, maxHeight).then(file => {
                const img = this.findImageByUniqName(item.uniqName);
                img.tmpUrl = URL.createObjectURL(file);
                img.task = this.storageRef.child(item.uniqName).put(file);
                img.task.on(
                    'state_changed',
                    snapshot => {
                        const img = this.findImageByUniqName(item.uniqName);
                        img.progress =
                            (snapshot.bytesTransferred * 100) / (snapshot.totalBytes || 1);
                    },
                    error => {
                        const img = this.findImageByUniqName(item.uniqName);
                        img.error = true;
                        uploaded++;
                        if (uploaded === numFiles) {
                            this.onUploading(false);
                        }
                    },
                    () => {
                        img.task.snapshot.ref.getDownloadURL().then(url => {
                            const item = this.findUploadTask(img.task);
                            const { ref } = item.task.snapshot;
                            this.images[item.index] = {
                                url,
                                resourcePath: ref.fullPath,
                                name: ref.name,
                                uploaded: true,
                            };
                            this.doOnChange();
                            uploaded++;
                            if (uploaded === numFiles) {
                                this.onUploading(false);
                            }
                        });
                    }
                );
            });
        });
    };

    render() {
        const {
            className,
            placeholder,
            label,
            description,
            error,
            helperText,
        } = this.props;
        return (
            <div className={cn('image-field', className, error && 'image-field-error')}>
                <div className="field-label">{label}</div>
                {!!description && <div className="description">{description}</div>}
                <DZone placeholder={placeholder} onNewFiles={this.handleNewFiles} />
                <div className="img-preview-holder">
                    {this.images.map((img, i) => (
                        <Thumb key={`th-${i}`} img={img} onDelete={() => this.deleteImage(i)} />
                    ))}
                </div>
                {!!helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
            </div>
        );
    }
}

export default ImageField;
