import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import Hidden from '@material-ui/core/Hidden';

import DateRangeField from '@/components/DateRangeField';
import MobileList from './MobileList';
import DesktopList from './DesktopList';

import './servidas-tab.scss';

const ServidasTab = ({ tabStore }) => {
    if (!tabStore) return null;
    return (
        <div className="servidas-tab" style={{ paddingTop: 20 }}>
            <div className="servida-tab-controls">
                <DateRangeField
                    label="Intervalo fechas"
                    {...tabStore.range}
                    onChange={tabStore.setRange}
                />
            </div>
            <div className="servidas-list">
                <Fragment>
                    <Hidden mdUp>
                        <MobileList servidas={tabStore.servidas} />
                    </Hidden>
                    <Hidden smDown>
                        <DesktopList servidas={tabStore.servidas} />
                    </Hidden>
                </Fragment>
            </div>
        </div>
    );
};

export default observer(ServidasTab);
