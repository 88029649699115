import React from 'react';
import {observer} from 'mobx-react';
import GaugeChart from 'react-gauge-chart';

const TestResults = ({store}) => {
  if (!store.showResult) return null;
  return (
    <div className="test-results">
      {store.hasError &&
        <div className="failed-required-fields">
          <div className="emoji">😟</div>
          <div className="messages">
            <p>¡Uy! Te faltaron algunos requerimientos básicos para poder hospedar una fuente Klara.</p>
            <p>Fijate bien en los campos marcados con rojo arriba. Quizás te olvidaste de alguno.</p>
          </div>
        </div>
      }
      {!store.hasError &&
        <div className="evaluation-results">
          <div className="gauge-container">
            <GaugeChart
              id="gauge"
              className="gauge-chart"
              percent={store.resultPercent}
              width="300"
              height="300"
              colors={['rgb(255, 81, 0)','rgb(255, 255, 0)', 'rgb(0, 255, 0)']}
              hideText={true}
              />
            <div className="gauge-emoji">
              {store.resultLevel.emoji}
            </div>
          </div>
          <div className="evaluation-msg">
            {store.resultLevel.msg}
          </div>
        </div>
      }
    </div>
  );
}

export default observer(TestResults);
