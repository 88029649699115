import React, { useState, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Dialog from '@material-ui/core/Dialog';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { viewPropsMapper } from '@/views/utils';

import './location-watch-list.scss';

const LocList = inject(viewPropsMapper)(
    observer(({ locs, getValue, getSecondaryText, goTo }) =>
        locs
            ? locs.map((loc, i) => (
                  <ListItem key={i} button onClick={() => goTo('location', { id: loc.id })}>
                      <ListItemText
                          primary={loc.label}
                          secondary={getSecondaryText ? getSecondaryText(loc) : undefined}
                      />
                      <ListItemSecondaryAction>{getValue(loc)}</ListItemSecondaryAction>
                  </ListItem>
              ))
            : null
    )
);

const LocationWatchList = ({ title, locs, showMax = 6, getValue, getSecondaryText }) => {
    const [modalOpen, setModalOpen] = useState(false);
    return (
        <Fragment>
            <List>
                <ListSubheader>{title}</ListSubheader>
                <LocList locs={locs.slice(0, showMax)} {...{ getValue, getSecondaryText }} />
                {locs && locs.length > showMax && (
                    <ListItem button onClick={() => setModalOpen(true)}>
                        <ListItemText primary="Ver todas" />
                        <ListItemSecondaryAction>
                            <ChevronRightIcon />
                        </ListItemSecondaryAction>
                    </ListItem>
                )}
            </List>
            <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
                <div className="loc-watch-dialog-content">
                    <List>
                        <ListSubheader className="loc-watch-dialog-title">{title}</ListSubheader>
                        <LocList locs={locs} {...{ getValue, getSecondaryText }} />
                    </List>
                </div>
            </Dialog>
        </Fragment>
    );
};

export default observer(LocationWatchList);
