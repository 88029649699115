import { limaMoment } from 'klara-common';
import { set, get, orderBy } from 'lodash';

export const getDayIdsFromToday = numDays => {
    const start = limaMoment();
    const result = [];
    for (let i = 0; i < numDays; i++) {
        result.push(start.format('YYYY-MM-DD'));
        start.subtract(1, 'day');
    }
    return result;
};

export const getMonthIdsFromToday = numMonths => {
    const start = limaMoment();
    const result = [];
    for (let i = 0; i < numMonths; i++) {
        result.push(start.format('YYYY-MM'));
        start.subtract(1, 'month');
    }
    return result;
};

export const getLocLitrosInLast3Days = (locations, servsXDay) => {
    const dayIds = getDayIdsFromToday(3);
    const daysPerLoc = servsXDay.reduce(
        (acc, curr) => set(acc, `${curr.locationId}.${curr.id}`, curr),
        {}
    );
    const locsWithMlWarning = locations
        .filter(loc => loc.active)
        .map(loc => {
            const mlLast3days = dayIds.reduce((acc, did) => {
                const dayData = get(daysPerLoc, `${loc.id}.${did}`, null);
                return acc + (dayData ? dayData.ml + dayData.mlProm : 0);
            }, 0);
            return { ...loc, mlLast3days };
        })
        .filter(item => item.mlLast3days <= 10000);
    return orderBy(locsWithMlWarning, ['mlLast3days', 'label'], ['ASC', 'ASC']);
};

export const aggregateObjects = (acc, item, fields) => {
    fields.forEach(field => {
        set(acc, field, get(acc, field, 0) + (item[field] || 0));
    });
    return acc;
};
